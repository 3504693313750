import * as React from 'react';
import * as propz from 'propz';
import { compose, withAppUser, withServices } from '../../../../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import { InviteGuest, InviteGuestAnswers } from '../../../../../models/invite-guest';
import { Event } from '../../../../../models/event';
import { INVITE_STATUS } from '../../../../../consts/invite';

interface Props {
  eventId: string;
  event: Event;
}

interface State {
  inviteGuests: InviteGuest[];
  isLoading: boolean;
  isError: boolean;
}

class EventGuests extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      inviteGuests: [],
      isLoading: true,
      isError: false,
    };
  }

  componentDidMount() {
    const { getInviteGuests, eventId } = this.props;

    this.setState({
      isLoading: true,
    });

    getInviteGuests(eventId, { where: { status: INVITE_STATUS.ACCEPT } })
      .then((inviteGuests) => {
        this.setState({
          isLoading: false,
          inviteGuests,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  getTotal(answers: InviteGuestAnswers): number {
    if (typeof answers === 'undefined') {
      return 1;
    }

    const isPlusOneGuest = propz.get(answers, ['isPlusOneGuest']);
    const guests = propz.get(answers, ['guests'], 0);
    const isPlusOneChild = propz.get(answers, ['isPlusOneChild']);
    const children = propz.get(answers, ['children'], 0);

    switch (true) {
      case isPlusOneGuest && isPlusOneChild:
        return 1 + guests + children;
      case isPlusOneGuest:
        return 1 + guests;
      case isPlusOneChild:
        return 1 + children;
      default:
        return 1;
    }
  }

  getAllTotal() {
    const { inviteGuests } = this.state;

    return inviteGuests.reduce((sum, current) => {
      const total = this.getTotal(current.answers);
      sum += total;
      return sum;
    }, 0);
  }

  render() {
    const { inviteGuests } = this.state;

    const isPlusOneGuestExist = inviteGuests.some((invite) => invite.answers.isPlusOneGuest);
    const isPlusOneChildExist = inviteGuests.some((invite) => invite.answers.isPlusOneChild);

    const emptyRow = (
      <tr key={'event_guests_empty_row'}>
        <td colSpan={3}>No invitations</td>
      </tr>
    );

    const inviteGuestsRows = inviteGuests.map((inviteGuest, index) => {
      const { id, email, status, answers } = inviteGuest;

      const isPlusOneGuest = propz.get(answers, ['isPlusOneGuest']);
      const guests = propz.get(answers, ['guests']);
      const isPlusOneChild = propz.get(answers, ['isPlusOneChild']);
      const children = propz.get(answers, ['children']);

      return (
        <tr key={id}>
          <td>{index + 1}</td>
          <td>{email}</td>
          <td>{status}</td>
          {isPlusOneGuestExist && <td>{isPlusOneGuest ? 'Yes' : 'No'}</td>}
          {isPlusOneGuestExist && <td>{guests}</td>}
          {isPlusOneChildExist && <td>{isPlusOneChild ? 'Yes' : 'No'}</td>}
          {isPlusOneChildExist && <td>{children}</td>}
          {(isPlusOneGuestExist || isPlusOneChildExist) && <td>{this.getTotal(answers)}</td>}
        </tr>
      );
    });

    return (
      <div className={'mt-3 mb-3'}>
        <h1>{`Guests (Total: ${this.getAllTotal()})`}</h1>
        <table className={'table table-sm mPre'}>
          <thead>
            <tr>
              <th />
              <th>Email</th>
              <th>Status</th>
              {isPlusOneGuestExist && <td>+ 1</td>}
              {isPlusOneGuestExist && <td>Guests</td>}
              {isPlusOneChildExist && <td>+1 Child</td>}
              {isPlusOneChildExist && <td>Children</td>}
              {(isPlusOneGuestExist || isPlusOneChildExist) && <td>Total</td>}
            </tr>
          </thead>
          <tbody>{inviteGuestsRows.length > 0 ? inviteGuestsRows : emptyRow}</tbody>
        </table>
      </div>
    );
  }
}

const EventGuestsPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(EventGuests);

export default EventGuestsPage;
