import * as React from 'react';
import SocialButton from '../social-button';
import { SocialButtonProps } from '../social-button/social-button';

interface Props {
  socialButtons: SocialButtonProps[];
}

export default function SocialButtonList(props: Props) {
  const { socialButtons } = props;
  return (
    <div className={'mt-3 mb-3'}>
      {socialButtons.map(({ iconName, iconPrefix, title, href }, index) => {
        return (
          <SocialButton
            key={`social_button_${index}`}
            href={href}
            iconName={iconName}
            iconPrefix={iconPrefix}
            title={title}
          />
        );
      })}
    </div>
  );
}
