import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from '../../../../../components/button/button';

interface Props {
  onSubmit: (data: InviteCoHostFormValues) => void;
  onCancel: () => void;
}

export interface InviteCoHostFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').email('Must be valid email'),
});

export function InviteCoHostForm(props: Props) {
  const { onSubmit, onCancel } = props;

  const initialValues: InviteCoHostFormValues = {
    email: '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h3 className={'mb-3 text-center mt-3'}>Invite Co-Host</h3>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <Form>
                <div className="form-group">
                  <Field placeholder={'Enter email here'} name="email" className="form-control mb-3" />
                  <ErrorMessage component="div" className="alert alert-danger" name="email" />
                </div>
                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3 btn-secondary'} />
                <Button onClick={handleSubmit} text={'Invite Co-Host'} customClass={'mt-3 mb-3'} type={'submit'} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
