import * as React from 'react';
import { Component } from 'react';
import Button from '../../../../components/button';
import SimpleModal from '../../../../components/simple-modal';
import Loader from '../../../../components/loader';
import ErrorIndicator from '../../../../components/error-indicator';
import { compose, withAppUser, withServices } from '../../../../components/hoc-helpers';
import { RouteComponentProps, withRouter } from 'react-router';
import { Event } from '../../../../models/event';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from '../../../App/App';
import { History } from 'history';
import { mapMethodsToProps, MapMethodsToProps } from '../../../../helpers/component';
import EventItem from '../../../../components/events/EventItem';
import NoEventsModal from './../Events/NoEventsModal';
import './../UserView.scss';

interface Props {
   user: AppUser;
   history: History;
}

type EventsFilterByPerson = 'ALL' | 'HOST' | 'CO-HOST' | 'GUEST';

interface State {
   isLoading: boolean;
   isShowFilters: boolean;
   isNoEventsModalOpen: boolean;
   isError: boolean;
   events: Event[];
   eventsFilterByPerson: EventsFilterByPerson;
}

class PastEvents extends Component<Props & MapMethodsToProps & RouteComponentProps, State> {
   constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
      super(props);

      this.state = {
         isLoading: false,
         isShowFilters: false,
         isNoEventsModalOpen: false,
         events: [],
         isError: false,
         eventsFilterByPerson: 'ALL',
      };
   }

   componentDidMount() {
      const { getPastEvents } = this.props;
      this.setState({
         isLoading: true,
      });

      BPromise.all([getPastEvents()])
         .then(([pastEvents]) => {
            const isNoEventsModalOpen = pastEvents.length === 0;
            this.setState({
               isLoading: false,
               events: pastEvents,
               isNoEventsModalOpen
            });
         })
         .catch(() => {
            this.setState({
               isError: true,
               isLoading: false,
            });
         });
   }

   onManageClick = (eventId: string) => {
      const { history } = this.props;
      history.push(`/events/${eventId}`);
   };

   getEventsPromise(filterByPerson?: any): Promise<Event[]> {
      const { getPastEvents } = this.props;
      let eventsPromise: Promise<Event[]>;

      eventsPromise = getPastEvents(filterByPerson);
      return eventsPromise;
   }

   getEventsFilter(filter: EventsFilterByPerson): any {
      const { user } = this.props;
      const { userId } = user;
      switch (filter) {
         case 'ALL':
            return undefined;
         case 'HOST':
            return { where: { 'host.userId': userId } };
         case 'CO-HOST':
            return { where: { 'coHosts.userId': userId } };
         case 'GUEST':
            return { where: { 'guests.userId': userId } };
      }
   }

   onEventsFilterByPersonButtonClick = (filter: EventsFilterByPerson) => {
      const { eventsFilterByPerson } = this.state;
      if (eventsFilterByPerson !== filter) {
         this.setState({
            isLoading: true,
         });

         const filterByPerson = this.getEventsFilter(filter);
         const eventsPromise = this.getEventsPromise(filterByPerson);

         eventsPromise
            .then((events) => {
               this.setState({
                  isLoading: false,
                  eventsFilterByPerson: filter,
                  events: events
               });
            })
            .catch(() => {
               this.setState({
                  isError: true,
                  isLoading: false,
               });
            });
      }
   };

   renderNoEventsModal(): React.ReactNode {
      const { isNoEventsModalOpen } = this.state;
      return (
         <SimpleModal isOpen={isNoEventsModalOpen}>
            <NoEventsModal onCloseClick={this.onCloseNoEventsModalClick} isPast />
         </SimpleModal>
      );
   }

   onCloseNoEventsModalClick = () => {
      this.setState({
         isNoEventsModalOpen: false
      })
   }

   onShowOrHideFiltersClick = () => {
      this.setState(prevState => {
         return {
            isShowFilters: !prevState.isShowFilters
         }
      })
   }

   render() {
      const {
         isLoading,
         isError,
         events,
         eventsFilterByPerson,
         isShowFilters
      } = this.state;

      if (isLoading) {
         return <Loader />;
      }

      if (isError) {
         return <ErrorIndicator />;
      }

      const userEmail = propz.get(this.props.user, ['profile', 'email'], '');

      const eventItems = events.map((event) => {
         return (
            <EventItem event={event} onManageClick={this.onManageClick} userEmail={userEmail} key={event.id} />
         );
      });

      const isEventsEmpty = events.length === 0;

      const nonActiveFilterButtonClasses = 'btn-outline-secondary me-3 filter-btn';
      const activeFilterButtonClasses = 'me-3 filter-btn filter-btn-outline';

      return (
         <div>
            {this.renderNoEventsModal()}
            <div className="events">
               <div className="title-wrapper mt-0">
                  <h1 className='page-title'>Past events</h1>
                  <button className="show-filters" onClick={this.onShowOrHideFiltersClick}>{isShowFilters ? 'Hide-filters' : 'Show-filters'}</button>
               </div>
               {
                  isShowFilters && <div className='filters'>
                     <Button
                        customClass={eventsFilterByPerson === 'ALL' ? activeFilterButtonClasses : nonActiveFilterButtonClasses}
                        onClick={() => this.onEventsFilterByPersonButtonClick('ALL')}
                        text={'All events'}
                     />
                     <Button
                        customClass={eventsFilterByPerson === 'HOST' ? activeFilterButtonClasses : nonActiveFilterButtonClasses}
                        onClick={() => this.onEventsFilterByPersonButtonClick('HOST')}
                        text={'I am a Host'}
                     />
                     <Button
                        customClass={eventsFilterByPerson === 'CO-HOST' ? activeFilterButtonClasses : nonActiveFilterButtonClasses}
                        onClick={() => this.onEventsFilterByPersonButtonClick('CO-HOST')}
                        text={'I am a Co-Host'}
                     />
                  </div>
               }
               {eventItems}
               {
                  isEventsEmpty && <div className="no-events d-flex justify-content-center align-items-center">
                     <span>No events found</span>
                  </div>
               }
            </div>
         </div>
      )
   };
}

const UserPastEventsPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(PastEvents);

export default UserPastEventsPage;
