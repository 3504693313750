import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../../../../components/button';
import { Tag } from '../../../../../models/tag';
import { Contact } from '../../../../../models/contact';
import * as propz from 'propz';
import { compose, withAppUser, withServices } from '../../../../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppUser } from '../../../../App/App';

interface Props {
  onSubmitAndClose: (data: ContactFormValues) => void;
  onSubmitAndContinue: (data: ContactFormValues) => void;
  onCancel: () => void;
  tags: Tag[];
  contact?: Contact;
  user: AppUser;
}

export interface ContactFormValues {
  firstName: string;
  lastName: string;
  email: string;
  tags?: {
    tagName: string;
    tagId: string;
    tagColor: string;
  }[];
}

interface ContactFormValuesExtended extends ContactFormValues {
  action: 'SAVE_AND_CLOSE' | 'SAVE_AND_CONTINUE';
}

function ContactForm(props: Props & MapMethodsToProps & RouteComponentProps) {
  const { onCancel, onSubmitAndClose, onSubmitAndContinue, tags, contact, checkContactEmail, user } = props;

  const userEmail = propz.get(user, ['profile', 'email'], '');

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('Required')
      .email('Must be valid email')
      .test('email', 'You cannot send an invitation to yourself', (value: string) => {
        return userEmail !== value;
      })
      .test('email', 'Duplicate contact', (value: string) => {
        const currentEmail = propz.get(contact, ['email'], '');
        if (currentEmail !== value) {
          return checkContactEmail({ email: value }).then((res) => {
            return res.isAvailable;
          });
        } else {
          return true;
        }
      }),
  });

  const isContactExist = typeof contact !== 'undefined';

  const initialValues: ContactFormValuesExtended = {
    firstName: propz.get(contact, ['firstName'], ''),
    lastName: propz.get(contact, ['lastName'], ''),
    email: propz.get(contact, ['email'], ''),
    tags: propz.get(contact, ['tags'], []),
    action: 'SAVE_AND_CONTINUE',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h3 className={'mb-3 text-center mt-3'}>Contact</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => {
              const { action, ...rest } = values;

              if (action === 'SAVE_AND_CLOSE') {
                onSubmitAndClose(rest);
              } else {
                onSubmitAndContinue(rest);
              }
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <Form>
                <div className="form-group">
                  <label>Name</label>
                  <Field name="firstName" className="form-control mb-3" />
                </div>
                <div className="form-group">
                  <label>Surname</label>
                  <Field name="lastName" className="form-control mb-3" />
                </div>

                <div className="form-group">
                  <label>Email</label>
                  <Field name="email" className="form-control mb-3" />
                  <ErrorMessage component="div" className="alert alert-danger" name="email" />
                </div>

                {values.tags.length > 0 && (
                  <div>
                    <h3>Tags</h3>
                    {values.tags.map((tag) => {
                      return (
                        <span
                          onClick={() => {
                            const { tags } = values;
                            const tagsUpdated = tags.filter((valuesTag) => valuesTag.tagId !== tag.tagId);

                            setFieldValue('tags', tagsUpdated);
                          }}
                          className="badge me-1"
                          style={{ backgroundColor: `${tag.tagColor}`, cursor: 'pointer' }}
                          key={tag.tagId}
                        >
                          {tag.tagName}
                        </span>
                      );
                    })}
                  </div>
                )}

                {tags.length > 0 && (
                  <div>
                    <h3>Add tags</h3>
                    {tags
                      .filter((tag) => values.tags.every((valuesTag) => valuesTag.tagId !== tag.id))
                      .map((tag) => {
                        return (
                          <span
                            onClick={() => {
                              const { tags } = values;
                              const tagsUpdated = [
                                ...tags,
                                {
                                  tagId: tag.id,
                                  tagColor: tag.color,
                                  tagName: tag.name,
                                },
                              ];

                              setFieldValue('tags', tagsUpdated);
                            }}
                            className="badge me-1"
                            style={{ backgroundColor: `${tag.color}`, cursor: 'pointer' }}
                            key={tag.id}
                          >
                            {tag.name}
                          </span>
                        );
                      })}
                  </div>
                )}

                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3'} />

                <Button
                  onClick={() => {
                    setFieldValue('action', 'SAVE_AND_CLOSE');
                  }}
                  text={'Save and Close'}
                  customClass={'mt-3 mb-3 me-3'}
                  type={'submit'}
                />
                {!isContactExist && (
                  <Button
                    onClick={() => {
                      setFieldValue('action', 'SAVE_AND_CONTINUE');
                    }}
                    text={'Save and Continue'}
                    customClass={'mt-3 mb-3'}
                    type={'submit'}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

const ContactFormPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(ContactForm);

export default ContactFormPage;
