import * as React from 'react';
import './user-avatar.scss';

interface Props {
   userAvatar: string;
   fullName: string;
}

const UserAvatar = (props: Props) => {
   const { userAvatar, fullName } = props;
   return (
      <div className='user-avatar-wrapper'>
         <div className="user-avatar" style={{ backgroundImage: `url("${userAvatar}")` }}>
            {!userAvatar && <span>{fullName}</span>}
         </div>
         <div className="user-avatar__menu">
            <ul>
               <li>Profile</li>
               <li>Sign out</li>
            </ul>
         </div>
      </div>
   )
}

export default UserAvatar;
