import * as React from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import { Component } from 'react';
import { match, RouteComponentProps, withRouter } from 'react-router';
import { compose, withAppUser, withServices } from '../../components/hoc-helpers';
import { History } from 'history';
import Loader from '../../components/loader';
import ErrorIndicator from '../../components/error-indicator';
import { AppUser } from '../App/App';
import { MapMethodsToProps, mapMethodsToProps } from '../../helpers/component';
import { Link } from 'react-router-dom';
import { InviteGuest } from '../../models/invite-guest';
import InviteGuestAcceptForm from './InviteGuestAcceptForm';
import { InviteGuestAcceptFormValues } from './InviteGuestAcceptForm/InviteGuestAcceptForm';

interface Props {
  match: match;
  history: History;
  user: AppUser;
}
interface State {
  isLoading: boolean;
  isError: boolean;
  errorText: string;
  isSubmitted: boolean;
  invite: InviteGuest;
}

class InviteGuestAccept extends Component<Props & RouteComponentProps & MapMethodsToProps, State> {
  constructor(props: Props & RouteComponentProps & MapMethodsToProps) {
    super(props);

    this.state = {
      isLoading: true,
      isError: false,
      isSubmitted: false,
      errorText: '',
      invite: undefined,
    };
  }

  componentDidMount() {
    const { acceptInviteGuest, getInviteGuest, match } = this.props;

    const { params } = match as any;
    const { id } = params;

    let _invite: InviteGuest;

    this.setState({
      isLoading: true,
    });

    getInviteGuest(id)
      .then((invite) => {
        _invite = invite;
        const { isPlusOneGuest, isPlusOneChild } = invite;
        const isAdditionalForm = isPlusOneGuest || isPlusOneChild;

        if (isAdditionalForm) {
          return BPromise.resolve(false);
        } else {
          return acceptInviteGuest(id);
        }
      })
      .then((res) => {
        if (!res) {
          this.setState({
            invite: _invite,
            isLoading: false,
          });
        } else {
          this.setState({
            isSubmitted: true,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
          errorText: propz.get(err, ['response', 'data', 'details', 'text']),
        });
      });
  }

  onSubmitInviteGuestAcceptForm = (data: InviteGuestAcceptFormValues) => {
    const { acceptInviteGuest, match } = this.props;

    const { params } = match as any;
    const { id } = params;

    this.setState({
      isLoading: true,
    });

    acceptInviteGuest(id, data)
      .then((res) => {
        this.setState({
          isSubmitted: true,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
          errorText: propz.get(err, ['response', 'data', 'details', 'text']),
        });
      });
  };

  render() {
    const { isLoading, isError, errorText, isSubmitted, invite } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator errorText={errorText} />;
    }

    if (isSubmitted) {
      return (
        <div className={'container'}>
          <div className="row">
            <div className="col-md-12">
              <div className="mt-3 text-center">
                <p>Thank you for your response, it will be sent to the event host.</p>
                <p>You can change your response by selecting the relevant link in the invitation email.</p>
                <p>
                  To be able to manage your own events <Link to={{ pathname: '/registration' }}>sign up</Link> for your
                  EventBerries account.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <InviteGuestAcceptForm invite={invite} onSubmit={this.onSubmitInviteGuestAcceptForm} />;
    }
  }
}

const InviteGuestAcceptPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(InviteGuestAccept);

export default InviteGuestAcceptPage;
