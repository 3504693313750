import { SocialButtonProps } from '../components/social-button/social-button';
import { DEFAULT_ICON_PREFIX } from './common';

export const SOCIAL_BUTTONS: SocialButtonProps[] = [
  {
    iconName: 'facebook',
    iconPrefix: DEFAULT_ICON_PREFIX,
    title: 'Facebook',
    href: 'http://localhost:3000/public/facebook',
  },
  {
    iconName: 'google',
    iconPrefix: DEFAULT_ICON_PREFIX,
    title: 'Google',
    href: 'http://localhost:3000/public/google',
  },
  // {
  //   iconName: 'twitter',
  //   iconPrefix: DEFAULT_ICON_PREFIX,
  //   title: 'Twitter',
  //   href: 'http://localhost:3000/twitter',
  // },
];
