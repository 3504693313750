import { isAdmin, isUser } from './user';
import { AppUser } from '../views/App/App';
import Content from '../views/GenericView/Content';
import UsersPage from '../views/GenericView/AdminView/Users';
import SignOut from '../views/GenericView/SignOut';
import Events from '../views/GenericView/UserView/Events';
import PastEvents from '../views/GenericView/UserView/PastEvents';
import UpcomingEvents from '../views/GenericView/UserView/UpcomingEvents';
import CreateEvent from '../views/GenericView/UserView/CreateEvent';
import UserEventPage from '../views/GenericView/UserView/Event';
import UserTagsPage from '../views/GenericView/UserView/Tags';
import UserContactsPage from '../views/GenericView/UserView/Contacts';
import * as propz from 'propz';
import ProfileWarning from '../views/GenericView/NotVerifiedUserView/ProfileWarning';
import ProfilePage from '../views/GenericView/UserView/Profile';

export interface RouteItem {
  path: string;
  component: any;
  exact: boolean;
}

export const USER_ROUTES: RouteItem[] = [
  {
    path: '/tags',
    component: UserTagsPage,
    exact: true,
  },
  {
    path: '/contacts',
    component: UserContactsPage,
    exact: true,
  },
  {
    path: '/events/my',
    component: Events,
    exact: true,
  },
  {
    path: '/events/past',
    component: PastEvents,
    exact: true,
  },
  {
    path: '/events/upcoming',
    component: UpcomingEvents,
    exact: true,
  },
  {
    path: '/events/create',
    component: CreateEvent,
    exact: true,
  },
  {
    path: '/events/:eventId',
    component: UserEventPage,
    exact: true,
  },
  {
    path: '/signOut',
    component: SignOut,
    exact: true,
  },
  {
    path: '/profile',
    component: ProfilePage,
    exact: true,
  },
  {
    path: '/',
    component: Content,
    exact: false,
  },
];

export const ADMIN_ROUTES: RouteItem[] = [
  {
    path: '/signOut',
    component: SignOut,
    exact: true,
  },
  {
    path: '/users',
    component: UsersPage,
    exact: true,
  },
  {
    path: '/',
    component: Content,
    exact: false,
  },
];

export const NOT_VERIFIED_USER_ROUTES: RouteItem[] = [
  {
    path: '/signOut',
    component: SignOut,
    exact: true,
  },
  {
    path: '/',
    component: ProfileWarning,
    exact: false,
  },
];

export function getRoutes(user: AppUser): RouteItem[] {
  const isEmailVerified = propz.get<boolean>(user, ['profile', 'verification', 'status', 'email'], false);

  switch (true) {
    case isUser(user) && !isEmailVerified:
      return NOT_VERIFIED_USER_ROUTES;
    case isUser(user) && isEmailVerified:
      return USER_ROUTES;
    case isAdmin(user):
      return ADMIN_ROUTES;
  }
}
