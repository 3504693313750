import * as React from 'react';
import { InviteGuest } from '../../../models/invite-guest';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../../components/button';
import * as Yup from 'yup';
import { FieldProps } from 'formik/dist/Field';
import SwitchField from '../../../components/switch-field';

interface Props {
  invite: InviteGuest;
  onSubmit: (data: InviteGuestAcceptFormValues) => void;
}

export interface InviteGuestAcceptFormValues {
  isPlusOneGuest: boolean;
  guests: number;
  isPlusOneChild: boolean;
  children: number;
}

const validationSchema = Yup.object().shape({
  isPlusOneGuest: Yup.boolean(),
  guests: Yup.number().typeError('Must be a number'),
  isPlusOneChild: Yup.boolean(),
  children: Yup.number().typeError('Must be a number'),
});

export function InviteGuestAcceptForm(props: Props) {
  const { invite, onSubmit } = props;

  const { isPlusOneGuest, isPlusOneChild, isMultipleGuests, isMultipleChildren } = invite;

  const initialValues: InviteGuestAcceptFormValues = {
    isPlusOneGuest: false,
    guests: 0,
    isPlusOneChild: false,
    children: 0,
  };

  return (
    <div className="container">
      <div className="row">
        <div className="offset-md-3 col-md-6">
          <h1 className={'text-center mt-3 mb-3'}>Additional questions</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data) => {
              const dataConverted = {
                ...data,
                guests: Number(data.guests),
                children: Number(data.children),
              };
              onSubmit(dataConverted);
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <Form>
                {isPlusOneGuest && (
                  <>
                    <div className="form-group">
                      <Field name="isPlusOneGuest" className={'mb-3'}>
                        {({ field }: FieldProps) => (
                          <SwitchField
                            {...field}
                            onChange={() => {
                              if (!values.isPlusOneGuest) {
                                setFieldValue('isPlusOneGuest', true);
                              } else {
                                setFieldValue('isPlusOneGuest', false);
                                setFieldValue('guests', 0);
                              }
                            }}
                            text="+1"
                            customClass="mb-3"
                          />
                        )}
                      </Field>
                    </div>

                    {isMultipleGuests && (
                      <div className="form-group">
                        <Field
                          disabled={!values.isPlusOneGuest}
                          placeholder={'Number guests'}
                          name="guests"
                          className="form-control mb-3"
                        />
                        <ErrorMessage component="div" className="alert alert-danger" name="guests" />
                      </div>
                    )}
                  </>
                )}

                {isPlusOneChild && (
                  <>
                    <div className="form-group">
                      <Field name="isPlusOneChild" className={'mb-3'}>
                        {({ field }: FieldProps) => (
                          <SwitchField
                            {...field}
                            onChange={() => {
                              if (!values.isPlusOneChild) {
                                setFieldValue('isPlusOneChild', true);
                              } else {
                                setFieldValue('isPlusOneChild', false);
                                setFieldValue('children', 0);
                              }
                            }}
                            text="+1 Child"
                            customClass="mb-3"
                          />
                        )}
                      </Field>
                    </div>

                    {isMultipleChildren && (
                      <div className="form-group">
                        <Field
                          disabled={!values.isPlusOneChild}
                          placeholder={'Number children'}
                          name="children"
                          className="form-control mb-3"
                        />
                        <ErrorMessage component="div" className="alert alert-danger" name="children" />
                      </div>
                    )}
                  </>
                )}

                <Button onClick={handleSubmit} text={'Send answer'} customClass={''} type={'submit'} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
