import * as React from 'react';
import * as propz from 'propz';
import { Event } from '../../../../../models/event';
import * as Moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '../../../../../consts/date';
import EventDetailsCoHostForm from './EventDetailsCoHostForm';
import { EventDetailsCoHostFormValues } from './EventDetailsCoHostForm/EventDetailsCoHostForm';
import { InviteCoHost } from '../../../../../models/invite-co-host';
import { mapMethodsToProps, MapMethodsToProps } from '../../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, withAppUser, withServices } from '../../../../../components/hoc-helpers';
import Loader from '../../../../../components/loader';
import ErrorIndicator from '../../../../../components/error-indicator';
import SimpleModal from '../../../../../components/simple-modal';
import Map from '../../../../../components/map';
import { INVITE_STATUS, INVITE_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../../../../consts/invite';
import { AppUser } from '../../../../App/App';

import './EventDetails.scss';

interface Props {
  event: Event;
  user: AppUser;
}

interface State {
  inviteCoHosts: InviteCoHost[];
  isLoading: boolean;
  isError: boolean;
  isShowMapModal: boolean;
}

const ROWS = [
  { title: 'When', field: 'when' },
  { title: 'Where', field: 'where' },
  { title: 'What', field: 'what' },
  { title: 'Host', field: 'host' },
  { title: 'Co-Hosts', field: 'co-hosts' },
];

class EventDetails extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      inviteCoHosts: [],
      isLoading: true,
      isError: false,
      isShowMapModal: false,
    };
  }

  componentDidMount() {
    const { getInviteCoHosts, event } = this.props;

    this.setState({
      isLoading: true,
    });

    getInviteCoHosts(event.id)
      .then((inviteCoHosts) => {
        this.setState({
          isLoading: false,
          inviteCoHosts,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  onCoHostFormSubmit = (data: EventDetailsCoHostFormValues): void => {
    const { inviteCoHost, getInviteCoHosts, event } = this.props;

    this.setState({
      isLoading: true,
    });

    inviteCoHost(event.id, data)
      .then((res) => getInviteCoHosts(event.id))
      .then((inviteCoHosts) => {
        this.setState({
          isLoading: false,
          inviteCoHosts,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  renderMapModal() {
    const { isShowMapModal } = this.state;
    const { event } = this.props;
    const { place } = event;

    if (typeof place !== 'undefined') {
      return (
        <SimpleModal
          isOpen={isShowMapModal}
          title={'Map'}
          negativeText={'Ok'}
          onNegativeButtonClick={this.closeMapModal}
        >
          <Map lat={place.lat} lng={place.lng} />
        </SimpleModal>
      );
    } else {
      return null;
    }
  }

  closeMapModal = () => {
    this.setState({
      isShowMapModal: false,
    });
  };

  onShowMapClick = () => {
    this.setState({
      isShowMapModal: true,
    });
  };

  render() {
    const { event, user } = this.props;
    const { isLoading, isError, inviteCoHosts, isShowMapModal } = this.state;
    const { start, host, end, place, venue, isOnlineEvent } = event;

    const userEmail = propz.get(user, ['profile', 'email'], '');

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const classes = isShowMapModal ? 'modal-open' : '';

    const rows = ROWS.map((row, index) => {
      let contentCell: any;
      const { field } = row;

      switch (field) {
        case 'when':
          if (typeof start !== 'undefined' && typeof end !== 'undefined') {
            const isSameDay = Moment(start).isSame(end, 'day');
            if (isSameDay) {
              const timeStart = Moment(start).format(TIME_FORMAT);
              const timeEnd = Moment(end).format(TIME_FORMAT);
              const dateStart = Moment(start).format(DATE_FORMAT);

              contentCell = `${dateStart} ${timeStart} - ${timeEnd}`;
            } else {
              const dateStart = Moment(start).format(DATE_TIME_FORMAT);
              const dateEnd = Moment(end).format(DATE_TIME_FORMAT);

              contentCell = `${dateStart} - ${dateEnd}`;
            }
          } else {
            contentCell = `Not defined`;
          }
          break;

        case 'where':
          if (isOnlineEvent) {
            contentCell = 'Online event';
          } else {
            if (typeof place !== 'undefined') {
              const { town, country, postcode, address } = place;
              contentCell = `${country} ${town} ${address} ${postcode} ${venue}`;
            } else {
              contentCell = `${venue || ''}`;
            }
          }
          break;

        case 'host':
          const firstName = propz.get(host, ['firstName'], '');
          const lastName = propz.get(host, ['lastName'], '');
          const email = propz.get(host, ['email'], '');
          const actualInformation = !firstName && !lastName ? email : `${firstName} ${lastName}`;
          contentCell = actualInformation;
          break;

        case 'co-hosts':
          if (inviteCoHosts.length === 0) {
            contentCell = 'No co-hosts';
          } else {
            const coHostInvitesString = [...inviteCoHosts]
              .sort((invite1, invite2) => {
                return invite1.status > invite2.status ? 1 : -1;
              })
              .map((invite, index) => {
                const firstName = propz.get(invite, ['user', 'firstName'], '');
                const lastName = propz.get(invite, ['user', 'lastName'], '');
                const status = propz.get(invite, ['status']);
                const email = propz.get(invite, ['email'], '');
                const actualInformation = !firstName && !lastName ? email : `${firstName} ${lastName}`;

                return (
                  <div key={invite.id}>
                    <span style={{ paddingRight: '10px' }}>{actualInformation}</span>
                    <span className={status === INVITE_STATUS.ACCEPT ? 'text-success' : 'text-danger'}>
                      {INVITE_STATUS_SERVER_TO_CLIENT_MAPPING[status]}
                    </span>
                  </div>
                );
              });

            contentCell = coHostInvitesString;
          }
          break;

        case 'what':
          contentCell = event.eventType.eventTypeName;
          break;

        default:
          contentCell = event[row.field as keyof Event];
      }

      return (
        <div className="row mb-3 event-details" key={`event_details_summary_${index}`}>
          <div className={'col-md-1 fw-bold'}>{row.title}</div>
          {field === 'where' ? (
            <div className={'col-md-11 mPre'}>
              {contentCell}
              {typeof place !== 'undefined' && (
                <a
                  className='show-map-link'
                  onClick={this.onShowMapClick}
                >
                  Show map
                </a>
              )}
            </div>
          ) : (
            <div className={'col-md-11 mPre'}>{contentCell}</div>
          )}
        </div>
      );
    });

    return (
      <div className={classes}>
        {this.renderMapModal()}
        <div className={'container-fluid mt-3 mb-3 g-0'}>
          {rows}
          <div className="row mb-3">
            <div className={'col-md-1'} />
            <div className={'col-md-11'}>
              <EventDetailsCoHostForm onSubmit={this.onCoHostFormSubmit} userEmail={userEmail} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const EventDetailsPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(EventDetails);

export default EventDetailsPage;
