import axios from 'axios';
import { Session } from '../../models/session';
import { RegistrationFormValues } from '../../views/Registration/Registration';
import { ResetPasswordFormValues } from '../../views/ResetPassword/ResetPasswordForm/ResetPasswordForm';
import { InviteGuest } from '../../models/invite-guest';
import { InviteGuestAcceptFormValues } from '../../views/InviteGuestAccept/InviteGuestAcceptForm/InviteGuestAcceptForm';

export default class PublicService {
  api: string;
  private readonly PREFIX = 'public';

  constructor(api: string) {
    this.api = `${api}/${this.PREFIX}`;
  }

  login = (email: string, password: string): Promise<Session> => {
    return axios.post<Session>(`${this.api}/login`, { email, password }).then((response) => response.data);
  };

  acceptInviteCoHost = (id: string): Promise<any> => {
    return axios.post<any>(`${this.api}/acceptInviteCoHost`, { id }).then((response) => response.data);
  };

  getInviteGuest = (id: string): Promise<InviteGuest> => {
    return axios.get<InviteGuest>(`${this.api}/inviteGuests/${id}`).then((response) => response.data);
  };

  acceptInviteGuest = (id: string, data?: InviteGuestAcceptFormValues): Promise<any> => {
    return axios
      .post<any>(`${this.api}/acceptInviteGuest`, { id, answers: { ...data } })
      .then((response) => response.data);
  };

  rejectInviteGuest = (id: string): Promise<any> => {
    return axios.post<any>(`${this.api}/rejectInviteGuest`, { id }).then((response) => response.data);
  };

  maybeInviteGuest = (id: string): Promise<any> => {
    return axios.post<any>(`${this.api}/maybeInviteGuest`, { id }).then((response) => response.data);
  };

  checkAvailability = (data: { email: string } | { phone: string }): Promise<{ isAvailable: boolean }> => {
    return axios.post<{ isAvailable: boolean }>(`${this.api}/register/check`, data).then((response) => response.data);
  };

  register = (data: RegistrationFormValues): Promise<any> => {
    return axios.post<any>(`${this.api}/register`, data).then((response) => response.data);
  };

  checkTokenEmail = (tokenEmail: string): Promise<any> => {
    return axios.post<any>(`${this.api}/tokens/email`, { tokenEmail }).then((response) => response.data);
  };

  resetRequestPassword = (data: ResetPasswordFormValues): Promise<any> => {
    return axios.post<any>(`${this.api}/passwords/resetRequest`, data).then((response) => response.data);
  };

  resetPassword = (password: string, secretKey: string): Promise<any> => {
    return axios.post<any>(`${this.api}/passwords/reset`, { password, secretKey }).then((response) => response.data);
  };
}
