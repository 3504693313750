import * as React from 'react';
import { Profile } from '../../../../../models/profile';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import Button from '../../../../../components/button';
import * as Yup from 'yup';
import { mapMethodsToProps, MapMethodsToProps } from '../../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, withAppUser, withServices } from '../../../../../components/hoc-helpers';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  profile: Profile;
}

interface State {}

export interface ProfileSummaryFormValues {
  avatar: string;
  firstName: string;
  lastName: string;
  email: string;
}

class ProfileSummaryForm extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  fileInputRef: any;

  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.fileInputRef = React.createRef();
  }

  onFileChange = (
    eventDescriptor: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const file = eventDescriptor.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const { uploadPublicFile } = this.props;

      uploadPublicFile(formData).then((res) => {
        const { url } = res;
        setFieldValue('avatar', url);
      });
    } else {
      setFieldValue('avatar', '');
    }
  };

  render() {
    const { onSubmit, onCancel, profile } = this.props;

    const { avatar = '', firstName = '', lastName = '', email = '' } = profile;

    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
    });

    const initialValues: ProfileSummaryFormValues = {
      avatar,
      firstName,
      lastName,
      email,
    };

    return (
      <div className={'mt-3'}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ handleSubmit, setFieldValue, values }) => (
            <Form>
              <div className={'d-flex justify-content-start'}>
                <div className={'me-5'}>
                  <h3>Your photo</h3>

                  {values.avatar ? (
                    <div className={'mb-3 eAvatarWrapper'}>
                      <img className={'img-thumbnail'} src={values.avatar} alt={'avatar'} />
                    </div>
                  ) : (
                    <div className={'mb-3 eAvatarWrapper'} />
                  )}

                  <input
                    className={'mb-3'}
                    type="file"
                    onChange={(event) => this.onFileChange(event, setFieldValue)}
                    ref={(input) => (this.fileInputRef = input)}
                  />
                </div>

                <div className={'eProfileInfo'}>
                  <h3>Summary</h3>
                  <div className="form-group">
                    <label>Name</label>
                    <Field name="firstName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="firstName" />
                  </div>

                  <div className="form-group">
                    <label>Surname</label>
                    <Field name="lastName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="lastName" />
                  </div>

                  <div className="form-group">
                    <label>Email</label>
                    <input disabled={true} className={'form-control mb-3'} value={email} />
                  </div>
                </div>
              </div>

              <div>
                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3 btn-secondary'} />
                <Button type={'submit'} text={'Save'} onClick={handleSubmit} customClass={'mt-3 mb-3'} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const ProfileSummaryFormPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(ProfileSummaryForm);

export default ProfileSummaryFormPage;
