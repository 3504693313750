import * as React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Button from '../../../../../../components/button';

const validationSchema = Yup.object().shape({
  text: Yup.string().required('Required'),
});

interface Props {
  onSubmit: (data: EventToDoListTaskFormValues) => void;
}

export interface EventToDoListTaskFormValues {
  text: string;
  deadline?: Date;
  responsibleUserId?: string;
  subtasks?: { text: string }[];
}

export function CreateEventToDoListTaskForm(props: Props) {
  const { onSubmit } = props;

  const initialValues: EventToDoListTaskFormValues = {
    text: '',
  };

  return (
    <div className={'mt-3 mb-3'}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, values }) => (
          <Form>
            <div className="row">
              <div className="col-auto">
                <Field placeholder={'Enter task text here'} name="text" className="form-control" />
              </div>
              <div className="col-auto">
                <Button
                  disabled={values.text.length === 0}
                  onClick={handleSubmit}
                  text={'Add task'}
                  customClass={''}
                  type={'submit'}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
