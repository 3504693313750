import * as React from 'react';
import FileService from '../../services/file-service';
import UserService from '../../services/user-service';
import AdminService from '../../services/admin-service';
import PublicService from '../../services/public-service';

export interface Services {
  file: FileService;
  admin: AdminService;
  user: UserService;
  public: PublicService;
}

const { Provider: ServicesProvider, Consumer: ServicesConsumer } = React.createContext<Services>(undefined);

export { ServicesProvider, ServicesConsumer };
