import * as React from 'react';
import './NoEventsModal.scss';

interface Props {
   onCloseClick: () => void,
   isPast?: boolean
}

const NoEventsModal: React.FC<Props> = ({ onCloseClick, isPast }) => {
   return (
      <div className='no-events-modal p-3'>
         <div className='text-center mb-5'>
            <p className='mb-0'>{`You don't have any ${isPast ? 'past' : 'upcoming'} events.`}</p>
            {!isPast && <p>Would you like to create a new event?</p>}

         </div>

         <div className='text-center'>
            {!isPast &&
               <button className="btn btn-outline-primary me-3 no-events-modal__btn no-events-modal__btn--outline">Create!
               </button>
            }
            <button className="btn btn-outline-secondary no-events-modal__btn" onClick={onCloseClick}>
               {isPast ? 'Cancel' : 'Not now'}
            </button>
         </div>
      </div>
   )
}

export default NoEventsModal;
