import * as React from 'react';
import { EventType } from '../../../../../models/event-type';
import './EventFormWithBot.scss';
import DialogMessage from '../../../../../components/dialog-message';
import Button from '../../../../../components/button';
import DatePicker from 'react-datepicker';

interface Props {
  onSubmit: (data: EventFormWithBotValues) => void;
  onCancel: () => void;
  eventTypes: EventType[];
}

export interface EventFormWithBotValues {
  name: string;
  eventTypeId: string;
  start?: Date;
  end?: Date;
  venue?: string;
  isNotSureDate: boolean;
  isNotSureVenue: boolean;
}

interface State {
  currentStep: number;
  currentText: string;
  eventName: string;
  eventStart: Date;
  eventEnd: Date;
  eventTypeId: string;
  eventTypeName: string;
  eventVenue: string;
  isDateDefined: boolean;
  isVenueDefined: boolean;
}

const EVENT_BERRY_AVATAR = '/dist/images/avatar2.jpg';
const USER_AVATAR = '/dist/images/avatar1.jpg';

export class EventFormWithBot extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const initialTime = new Date();
    initialTime.setHours(10, 0);

    this.state = {
      currentStep: 0,
      currentText: '',
      eventName: '',
      eventTypeId: '',
      eventTypeName: '',
      eventVenue: '',
      eventStart: initialTime,
      eventEnd: initialTime,
      isDateDefined: undefined,
      isVenueDefined: undefined,
    };
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentText: event.target.value,
    });
  };

  onChangeEventName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      eventName: event.target.value,
    });
  };

  onChangeEventStart = (start: Date | [Date, Date]) => {
    if (!Array.isArray(start)) {
      this.setState((prevState) => {
        return {
          eventStart: start,
          currentStep: prevState.currentStep === 3 ? prevState.currentStep + 1 : prevState.currentStep,
        };
      });
    }
  };

  onChangeEventEnd = (end: Date | [Date, Date]) => {
    if (!Array.isArray(end)) {
      this.setState((prevState) => {
        return {
          eventEnd: end,
          currentStep: prevState.currentStep === 4 ? prevState.currentStep + 1 : prevState.currentStep,
        };
      });
    }
  };

  onChangeEventVenue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      eventVenue: event.target.value,
    });
  };

  onClickEventType = (typeId: string, typeName: string) => {
    const { eventTypeId } = this.state;

    if (eventTypeId === '') {
      this.setState((prevState) => {
        return {
          eventTypeId: typeId,
          eventTypeName: typeName,
          currentStep: prevState.currentStep + 1,
        };
      });
    } else {
      this.setState({
        eventTypeId: typeId,
        eventTypeName: typeName,
      });
    }
  };

  onClickEventDate = (isDefined: boolean) => {
    if (!isDefined) {
      this.setState((prevState) => {
        return {
          currentStep: prevState.currentStep === 2 ? prevState.currentStep + 3 : prevState.currentStep,
          isDateDefined: false,
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          currentStep: prevState.currentStep === 2 ? prevState.currentStep + 1 : prevState.currentStep,
          isDateDefined: true,
        };
      });
    }
  };

  onClickEventVenue = (isDefined: boolean) => {
    if (!isDefined) {
      this.setState((prevState) => {
        return {
          currentStep: prevState.currentStep + 2,
          isVenueDefined: false,
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          currentStep: prevState.currentStep + 1,
          isVenueDefined: true,
        };
      });
    }
  };

  onSend = () => {
    const { currentStep } = this.state;

    switch (currentStep) {
      case 0:
        this.setState((prevState) => {
          return {
            eventName: prevState.currentText,
            currentText: '',
            currentStep: prevState.currentStep + 1,
          };
        });
        break;
      case 6:
        this.setState((prevState) => {
          return {
            eventVenue: prevState.currentText,
            currentText: '',
            currentStep: prevState.currentStep + 1,
          };
        });
    }
  };

  onGOClick = () => {
    const { eventStart, eventEnd, eventName, eventTypeId, eventVenue, isVenueDefined, isDateDefined } = this.state;
    const { onSubmit } = this.props;

    const dataToPost = {
      start: eventStart,
      end: eventEnd,
      venue: eventVenue,
      name: eventName,
      eventTypeId: eventTypeId,
      isNotSureDate: !isDateDefined,
      isNotSureVenue: !isVenueDefined,
    };

    onSubmit(dataToPost);
  };

  render() {
    const {
      currentText,
      eventTypeId,
      eventTypeName,
      isDateDefined,
      isVenueDefined,
      eventName,
      currentStep,
      eventVenue,
      eventEnd,
      eventStart,
    } = this.state;
    const { eventTypes } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="eDialog">
              <div className="eDialogContent">
                <div className="eDialogBody">
                  {currentStep >= 0 && (
                    <>
                      <DialogMessage
                        message={{
                          text: 'Your remarkable event starts here!',
                          avatar: EVENT_BERRY_AVATAR,
                        }}
                      />

                      <DialogMessage
                        message={{
                          text: "Hey! I am BerryBot, your EventBerries virtual assistant and I will get you through the process of creating your event. Let's start with your event name. Type in the name and click the Send button or simply press Enter on your keyboard.",
                          avatar: EVENT_BERRY_AVATAR,
                        }}
                      />
                    </>
                  )}

                  {currentStep >= 1 && (
                    <>
                      <div className={'d-flex align-items-center mt-3'}>
                        <div className={''}>
                          <img className={'eAvatar'} src={USER_AVATAR} alt={'avatar'} />
                        </div>
                        <div className={'ms-3'}>
                          <input value={eventName} onChange={this.onChangeEventName} />
                        </div>
                      </div>

                      <DialogMessage
                        message={{
                          text: 'Great, what is it going to be? Based on what you\'ve entered I would suggest it to be one of the types stated below. Select "Other" if you aren\'t sure yet, you will be able to change it later.',
                          avatar: EVENT_BERRY_AVATAR,
                        }}
                      />
                      <div className={'d-flex align-items-center mt-3'}>
                        {currentStep >= 2 && (
                          <div className={''}>
                            <img className={'eAvatar'} src={USER_AVATAR} alt={'avatar'} />
                          </div>
                        )}

                        <div>
                          {eventTypes.map((eventType) => {
                            return (
                              <Button
                                key={eventType.id}
                                onClick={() => this.onClickEventType(eventType.id, eventType.name)}
                                text={eventType.name}
                                customClass={eventTypeId !== eventType.id ? 'ms-3 btn-secondary' : 'ms-3 btn-primary'}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}

                  {currentStep >= 2 && (
                    <>
                      <DialogMessage
                        message={{
                          text: `Perfect, we have set it up as a ${eventTypeName}, although you can change it later if you need. Have you decided on the date(s)?`,
                          avatar: EVENT_BERRY_AVATAR,
                        }}
                      />

                      <div className={'d-flex align-items-center mt-3'}>
                        {currentStep >= 3 && (
                          <div className={''}>
                            <img className={'eAvatar'} src={USER_AVATAR} alt={'avatar'} />
                          </div>
                        )}
                        <div>
                          <Button
                            onClick={() => this.onClickEventDate(true)}
                            text={'Yes'}
                            customClass={isDateDefined === true ? 'ms-3 btn-primary' : 'ms-3 btn-secondary'}
                          />
                          <Button
                            onClick={() => this.onClickEventDate(false)}
                            text={'Not yet'}
                            customClass={isDateDefined === false ? 'ms-3 btn-primary' : 'ms-3 btn-secondary'}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {currentStep >= 3 && isDateDefined && (
                    <div className={'d-flex align-items-center mt-3'}>
                      {currentStep >= 4 && isDateDefined && (
                        <div className={''}>
                          <img className={'eAvatar'} src={USER_AVATAR} alt={'avatar'} />
                        </div>
                      )}
                      <div className={'ms-3'}>Start date</div>
                      <div>
                        <DatePicker
                          selected={eventStart}
                          className="form-control ms-3"
                          dateFormat={'dd-MM-yyyy HH:mm'}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="Time"
                          onChange={(start) => {
                            this.onChangeEventStart(start);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {currentStep >= 4 && isDateDefined && (
                    <div className={'d-flex align-items-center mt-3'}>
                      {currentStep >= 5 && isDateDefined && (
                        <div className={''}>
                          <img className={'eAvatar'} src={USER_AVATAR} alt={'avatar'} />
                        </div>
                      )}
                      <div className={'ms-3'}>End date</div>
                      <div>
                        <DatePicker
                          selected={eventEnd}
                          className="form-control ms-3"
                          dateFormat={'dd-MM-yyyy HH:mm'}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="Time"
                          onChange={(end) => {
                            this.onChangeEventEnd(end);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {currentStep >= 5 && isDateDefined === false && (
                    <DialogMessage
                      message={{
                        text: "No worries, you can add your event dates and time once you're ready. Do you have a nice venue in mind where you are going to host your event?",
                        avatar: EVENT_BERRY_AVATAR,
                      }}
                    />
                  )}

                  {currentStep >= 5 && isDateDefined === true && (
                    <DialogMessage
                      message={{
                        text: 'Do you have a nice venue in mind where you are going to host your event?',
                        avatar: EVENT_BERRY_AVATAR,
                      }}
                    />
                  )}

                  {currentStep >= 5 && (
                    <div className={'d-flex align-items-center mt-3'}>
                      {currentStep >= 6 && (
                        <div className={''}>
                          <img className={'eAvatar'} src={USER_AVATAR} alt={'avatar'} />
                        </div>
                      )}
                      <div>
                        <Button
                          onClick={() => this.onClickEventVenue(true)}
                          text={'Yes'}
                          customClass={isVenueDefined === true ? 'ms-3 btn-primary' : 'ms-3 btn-secondary'}
                        />
                        <Button
                          onClick={() => this.onClickEventVenue(false)}
                          text={'Not yet'}
                          customClass={isVenueDefined === false ? 'ms-3 btn-primary' : 'ms-3 btn-secondary'}
                        />
                      </div>
                    </div>
                  )}

                  {currentStep >= 7 && isVenueDefined && (
                    <div className={'d-flex align-items-center mt-3'}>
                      <div className={''}>
                        <img className={'eAvatar'} src={USER_AVATAR} alt={'avatar'} />
                      </div>
                      <div className={'ms-3'}>
                        <input value={eventVenue} onChange={this.onChangeEventVenue} />
                      </div>
                    </div>
                  )}

                  {currentStep >= 7 && isVenueDefined === false && (
                    <DialogMessage
                      message={{
                        text: "That's okay, you can specify your venue details once you know them. You're nearly there, click the Go! button to proceed to managing your invitations, guests, and lots more!",
                        avatar: EVENT_BERRY_AVATAR,
                      }}
                    />
                  )}

                  {currentStep >= 7 && isVenueDefined === true && (
                    <DialogMessage
                      message={{
                        text: "You're nearly there, click the Go! button to proceed to managing your invitations, guests, and lots more!",
                        avatar: EVENT_BERRY_AVATAR,
                      }}
                    />
                  )}

                  {currentStep >= 7 && (
                    <div className={'d-flex align-items-center mt-3'}>
                      <div className={''}>
                        <img className={'eAvatar'} src={USER_AVATAR} alt={'avatar'} />
                      </div>
                      <div>
                        <Button onClick={this.onGOClick} text={'GO!'} customClass={'ms-3'} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="eDialogFooter d-flex">
                <div className={'eDialogFooterAvatar'}>
                  <img className={'eAvatar'} src={USER_AVATAR} alt={'avatar'} />
                </div>
                <div className={'eDialogFooterInputWrapper'}>
                  <input
                    disabled={currentStep !== 0 && currentStep !== 6}
                    value={currentText}
                    onChange={this.onChange}
                    className={'eDialogFooterInput'}
                    type={'text'}
                  />
                </div>
                <div className={'eDialogFooterButton'}>
                  <button disabled={currentStep !== 0 && currentStep !== 6} onClick={this.onSend}>
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
