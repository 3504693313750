import * as React from 'react';
import { Component } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { EventType } from '../../../../models/event-type';
import { PlaceAutocompleteData } from '../../../../components/place-autocomplete/place-autocomplete';
import { DEFAULT_LAT, DEFAULT_LNG } from '../../../../consts/common';
import { FieldProps } from 'formik/dist/Field';
import { compose, withAppUser, withServices } from '../../../../components/hoc-helpers';
import { Event } from '../../../../models/event';
import { AppUser } from '../../../App/App';
import SimpleModal from '../../../../components/simple-modal';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../helpers/component';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router';
import SwitchField from '../../../../components/switch-field';
import PlaceAutocomplete from '../../../../components/place-autocomplete';
import Button from '../../../../components/button';
import Map from '../../../../components/map';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import SuccessModal from './SuccessModal';

interface Props {
   user: AppUser;
}

interface State {
   isLoading: boolean;
   isError: boolean;
   eventTypes: EventType[];
   events: Event[];
   isSuccessModalOpen: boolean;
   newEventId: string;
}

export interface EventFormValues {
   name: string;
   eventTypeId: string;
   start: Date;
   end: Date;
   isNotSureDate: boolean;
   isNotSureVenue: boolean;
   isOnlineEvent: boolean;
   venue: string;
   place?: PlaceAutocompleteData;
}

const initialTime = new Date();
initialTime.setHours(10, 0);

const initialValues: EventFormValues = {
   name: '',
   eventTypeId: '',
   start: initialTime,
   end: initialTime,
   isNotSureDate: false,
   isNotSureVenue: false,
   isOnlineEvent: false,
   venue: '',
   place: {
      address: '',
      postcode: '',
      country: '',
      town: '',
      lat: DEFAULT_LAT,
      lng: DEFAULT_LNG,
   },
};
const validationSchema = Yup.object().shape({
   name: Yup.string().required('Required'),
   eventTypeId: Yup.string().required('Required'),
   start: Yup.date().max(Yup.ref('end'), 'Must be less then end'),
   end: Yup.date().min(Yup.ref('start'), 'Must be more then start'),
});

export class CreateEvent extends Component<Props & MapMethodsToProps & RouteComponentProps, State> {
   constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
      super(props);

      this.state = {
         isLoading: false,
         eventTypes: [],
         events: [],
         isError: false,
         newEventId: '',
         isSuccessModalOpen: false
      };
   }

   componentDidMount() {
      const { getEventTypes } = this.props;
      this.setState({
         isLoading: true,
      });

      getEventTypes()
         .then((eventTypes) => {
            this.setState({
               isLoading: false,
               eventTypes
            });
         })
         .catch(() => {
            this.setState({
               isError: true,
               isLoading: false,
            });
         });
   }

   onSubmitCreateEventForm = (data: EventFormValues, { resetForm }: any) => {
      const { eventTypes } = this.state;
      const { createEvent } = this.props;
      const { name, eventTypeId, start, end, isNotSureDate, place, isNotSureVenue, isOnlineEvent, venue } = data;

      const eventTypeName = eventTypes.find((eventType) => eventType.id === eventTypeId).name;

      const dataToPost = {
         name,
         isOnlineEvent,
         eventType: {
            eventTypeId,
            eventTypeName,
         },
         start: start.toISOString(),
         end: end.toISOString(),
         place: isNotSureVenue ? undefined : place,
         venue: isNotSureVenue ? undefined : venue,
         isNotSureDate
      };

      this.setState({
         isLoading: true
      });

      createEvent(dataToPost)
         .then(res => {
            this.setState({
               newEventId: res.id
            });
         })
         .catch(() => {
            this.setState({
               isError: true,
               isLoading: false,
            });
         });

      this.setState({
         isLoading: false,
         isSuccessModalOpen: true
      });

      resetForm();
   };

   renderSuccessModal = () => {
      const { isSuccessModalOpen } = this.state;
      return (
         <SimpleModal isOpen={isSuccessModalOpen}>
            <SuccessModal onClose={this.onCloseSuccessModal} onManageEventClick={this.onManageEventClick} />
         </SimpleModal>
      );
   };

   onManageEventClick = () => {
      const { newEventId } = this.state;
      this.props.history.push(`/events/${newEventId}`);
      this.onCloseSuccessModal();
   };

   onCloseSuccessModal = () => {
      this.setState({
         isSuccessModalOpen: false
      });
   };

   render() {
      const { eventTypes } = this.state;
      return (
         <div className='create-event-page'>
            {this.renderSuccessModal()}
            <h1 className='mb-4'>My event</h1>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmitCreateEventForm}>
               {({ handleSubmit, values, setFieldValue, resetForm }) => (
                  <Form>
                     <div className="form-group">
                        <Field placeholder={'Let us start with your event name'} name="name" className="form-control mb-3" />
                        <ErrorMessage component="div" className="alert alert-danger" name="name" />
                     </div>

                     <div className="form-group">
                        <Field
                           name="eventTypeId"
                           component="select"
                           placeholder="Select your event type"
                           className="form-control mb-3"
                        >
                           <option value={''}>Select your event type</option>
                           {eventTypes.map((eventType: any) => (
                              <option value={eventType.id} key={eventType.id}>
                                 {eventType.name}
                              </option>
                           ))}
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name="eventTypeId" />
                     </div>

                     <div className="d-flex">
                        <div className="me-4">
                           <div>Start date</div>
                           <Field name="start">
                              {({ field }: FieldProps) => (
                                 <DatePicker
                                    selected={field.value}
                                    className="form-control mb-3"
                                    dateFormat={'dd-MM-yyyy HH:mm'}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    onChange={(start) => {
                                       setFieldValue('start', start);
                                    }}
                                 />
                              )}
                           </Field>
                           <ErrorMessage component="div" className="border-0 alert alert-danger" name="start" />
                        </div>

                        <div>
                           <div>End date</div>
                           <Field name="end">
                              {({ field }: FieldProps) => (
                                 <DatePicker
                                    selected={field.value}
                                    className="form-control mb-3"
                                    dateFormat={'dd-MM-yyyy HH:mm'}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    onChange={(end) => {
                                       setFieldValue('end', end);
                                    }}
                                 />
                              )}
                           </Field>
                           <ErrorMessage component="div" className="border-0 alert alert-danger" name="end" />
                        </div>
                     </div>

                     <div className="form-group">
                        <Field name="isNotSureDate">
                           {({ field }: FieldProps) => (
                              <SwitchField
                                 {...field}
                                 onChange={(event) => {
                                    if (!values.isNotSureDate) {
                                       setFieldValue('start', new Date());
                                       setFieldValue('end', new Date());
                                    }
                                    setFieldValue('isNotSureDate', !values.isNotSureDate);
                                 }}
                                 text="Not sure"
                                 customClass="mb-3"
                              />
                           )}
                        </Field>
                     </div>

                     <div className="form-group">
                        <Field name="place">
                           {({ field }: FieldProps) => (
                              <PlaceAutocomplete
                                 isDisabled={values.isNotSureVenue}
                                 onPlaceSelected={(data: PlaceAutocompleteData) => {
                                    setFieldValue('place', data);
                                 }}
                              />
                           )}
                        </Field>
                     </div>

                     <div className="form-group">
                        <Field
                           disabled={values.isNotSureVenue}
                           placeholder={'Place name'}
                           name="venue"
                           className="form-control mb-3"
                        />
                     </div>

                     <div className={'mt-3 mb-3'}>
                        <Map lat={values.place.lat} lng={values.place.lng} />
                     </div>

                     <div className="d-flex">
                        <div className="me-4">
                           <Field name="isNotSureVenue">
                              {({ field }: FieldProps) => (
                                 <SwitchField
                                    {...field}
                                    text="Not sure"
                                    customClass="mb-3"
                                    onChange={(event) => {
                                       setFieldValue('isNotSureVenue', !values.isNotSureVenue);
                                    }}
                                 />
                              )}
                           </Field>
                        </div>

                        <div>
                           <Field name="isOnlineEvent">
                              {({ field }: FieldProps) => <SwitchField {...field} text="Online event" customClass="mb-3" />}
                           </Field>
                        </div>
                     </div>

                     <Button onClick={handleSubmit} text={'Create my event!'} customClass={'mt-3 mb-3'} type={'submit'} />
                  </Form>
               )}
            </Formik>
         </div>
      )
   }
}

const CreateEventPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(CreateEvent);

export default CreateEventPage;