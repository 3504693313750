import * as React from 'react';
import { Profile } from '../../../../../models/profile';
import Button from '../../../../../components/button';

interface Props {
  onEditClick: () => void;
  profile: Profile;
}

export function ProfileSummary(props: Props) {
  const { profile, onEditClick } = props;

  const { avatar = '', firstName = '', lastName = '', email = '' } = profile;

  return (
    <div className={'mt-3'}>
      <div className={'d-flex justify-content-start'}>
        <div className={'me-5'}>
          <h3>Your photo</h3>
          {avatar ? (
            <div className={'mb-3 eAvatarWrapper'}>
              <img className={'img-thumbnail'} src={avatar} alt={'avatar'} />
            </div>
          ) : (
            <div className={'mb-3 eAvatarWrapper'}>
              <img className={'img-thumbnail'} src={'/dist/images/empty_pic.png'} alt={'avatar'} />
            </div>
          )}
        </div>
        <div className={'eProfileInfo'}>
          <h3>Summary</h3>

          <div className="form-group">
            <label>Name</label>
            <input disabled={true} className={'form-control mb-3'} value={firstName} />
          </div>

          <div className="form-group">
            <label>Surname</label>
            <input disabled={true} className={'form-control mb-3'} value={lastName} />
          </div>

          <div className="form-group">
            <label>Email</label>
            <input disabled={true} className={'form-control mb-3'} value={email} />
          </div>
        </div>
      </div>

      <div>
        <Button onClick={onEditClick} text={'Edit'} customClass={'mt-3 mb-3 me-3'} />
      </div>
    </div>
  );
}
