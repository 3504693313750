import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import './App.scss';
import { faFacebook, faTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Loader from '../../components/loader';
import { Redirect, Route, Switch } from 'react-router';
import { clearSession, getCookieAgreement, getSession, setCookieAgreement } from '../../helpers/cookies';
import Login from '../Login';
import SocialAuth from '../SocialAuth';
import { apiSelector } from '../../helpers/api';
import PublicService from '../../services/public-service';
import FileService from '../../services/file-service';
import UserService from '../../services/user-service';
import AdminService from '../../services/admin-service';
import { Services, ServicesProvider } from '../../components/services-context/services-context';
import { USER_TYPE } from '../../types/user';
import { AppUserProvider } from '../../components/app-user-context';
import InviteCoHostPage from '../InviteCoHost';
import InviteGuestPage from '../InviteGuest';
import Landing from '../Landing';
import Registration from '../Registration';
import ErrorIndicator from '../../components/error-indicator';
import TokensPage from '../Tokens';
import GenericViewPage from '../GenericView';
import { Profile } from '../../models/profile';
import ResetPasswordPage from '../ResetPassword';
import InviteGuestAcceptPage from '../InviteGuestAccept';

library.add(faFacebook, faTwitter, faGoogle, faTrash, faEdit);

interface State {
  user: AppUser;
  isLoading: boolean;
  isError: boolean;
  services: Services;
  isCookiesModalOpen: boolean;
}

export interface AppUser {
  authorized: boolean;
  sessionKey: string;
  type: USER_TYPE;
  userId: string;
  profile?: Profile;
}

export default class App extends Component<{}, State> {
  constructor(props: any) {
    super(props);

    const session = getSession();
    const isSession = typeof session !== 'undefined';

    const myDomain = document.location.hostname;
    const { api, fileApi } = apiSelector(myDomain);

    const user: AppUser = {
      sessionKey: isSession ? session.key : undefined,
      authorized: isSession,
      type: isSession ? session.type : undefined,
      userId: isSession ? session.userId : undefined,
    };
    const cookiesAgreement = getCookieAgreement();
    const isCookiesAgreement = typeof cookiesAgreement !== 'undefined';

    this.state = {
      user: user,
      isLoading: false,
      isError: false,
      services: {
        file: new FileService(user, fileApi),
        admin: new AdminService(user, api),
        user: new UserService(user, api),
        public: new PublicService(api),
      },
      isCookiesModalOpen: !isCookiesAgreement,
    };
  }

  onLogin = (userId: string, key: string, type: USER_TYPE): void => {
    const myDomain = document.location.hostname;
    const { api, fileApi } = apiSelector(myDomain);

    const user: AppUser = {
      authorized: true,
      userId: userId,
      sessionKey: key,
      type: type,
    };

    this.setState((prevState) => {
      return {
        user: user,
        services: {
          ...prevState.services,
          file: new FileService(user, fileApi),
          admin: new AdminService(user, api),
          user: new UserService(user, api),
        },
      };
    });
  };

  setProfile = (profile: Profile): void => {
    this.setState((prevState) => {
      return {
        user: {
          ...prevState.user,
          profile,
        },
      };
    });
  };

  setDefaultState = (): void => {
    clearSession();

    this.setState({
      user: {
        sessionKey: undefined,
        authorized: false,
        type: undefined,
        userId: undefined,
      },
    });
  };

  onLogoutClick = (): void => {
    const { services } = this.state;

    services.user.logout().then(
      () => {
        this.setDefaultState();
      },
      () => {
        this.setDefaultState();
      }
    );
  };

  onCookiesModalOkClick = () => {
    setCookieAgreement();
    this.setState({
      isCookiesModalOpen: false,
    });
  };

  render() {
    const { isLoading, user, services, isCookiesModalOpen, isError } = this.state;
    const { authorized } = user;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    return (
      <AppUserProvider value={user}>
        <ServicesProvider value={services}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Landing onOk={this.onCookiesModalOkClick} isCookiesModalOpen={isCookiesModalOpen} />}
            />
            <Route exact path="/login" component={() => <Login onLogin={this.onLogin} />} />
            <Route exact path="/registration" component={() => <Registration />} />
            <Route exact path="/resetPassword" component={() => <ResetPasswordPage />} />
            <Route exact path="/resetPassword/:secretKey" component={() => <ResetPasswordPage />} />
            <Route exact path="/tokenEmail/:tokenEmail" component={() => <TokensPage />} />
            <Route exact path="/inviteCoHost/:id" component={() => <InviteCoHostPage />} />
            <Route exact path="/inviteGuest/:id/accept" component={() => <InviteGuestAcceptPage />} />
            <Route exact path="/inviteGuest/:id/reject" component={() => <InviteGuestPage action={'REJECT'} />} />
            <Route exact path="/inviteGuest/:id/maybe" component={() => <InviteGuestPage action={'MAYBE'} />} />
            <Route
              exact
              path="/auth/user/:userId/session/:sessionId/type/:typeId"
              component={() => <SocialAuth onLogin={this.onLogin} />}
            />
            <Route
              path="/"
              render={() =>
                authorized ? (
                  <GenericViewPage user={user} onLogoutClick={this.onLogoutClick} setProfile={this.setProfile} />
                ) : (
                  <Redirect to={{ pathname: '/login' }} />
                )
              }
            />
          </Switch>
        </ServicesProvider>
      </AppUserProvider>
    );
  }
}
