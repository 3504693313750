import * as React from 'react';
import './loader.scss';

export default function Loader() {
  return (
    <div className="bLoading">
      <div className="spinner-border">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
