import * as React from 'react';

interface Props {
  onPlaceSelected: (data: PlaceAutocompleteData) => void;
  isDisabled?: boolean;
}

interface State {}

export interface PlaceAutocompleteData {
  address: string;
  postcode: string;
  country: string;
  town: string;
  lat: number;
  lng: number;
}

export class PlaceAutocomplete extends React.Component<Props, State> {
  autocompleteInput: any;
  autocomplete: any;
  listener: any;

  static defaultProps = { isDisabled: false };

  constructor(props: Props) {
    super(props);

    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
  }

  componentDidMount() {
    const options = {
      componentRestrictions: { country: 'uk' },
      fields: ['address_components', 'geometry', 'name'],
      strictBounds: false,
    };

    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current, options);

    this.listener = this.autocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();

      let address = '';
      let postcode = '';
      let country = '';
      let town = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address = `${component.long_name} ${address}`;
            break;
          }

          case 'route': {
            address += component.long_name;
            break;
          }

          case 'postal_town': {
            town = component.long_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case 'country':
            country = component.long_name;
            break;
        }
      }

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      this.props.onPlaceSelected({
        address,
        postcode,
        country,
        town,
        lat,
        lng,
      });
    });
  }

  componentWillUnmount() {
    (window as any).google.maps.event.removeListener(this.listener);
  }

  render() {
    const { isDisabled } = this.props;
    return (
      <input
        disabled={isDisabled}
        className="form-control mb-3"
        ref={this.autocompleteInput}
        id="autocomplete"
        placeholder="Enter your address"
        type="text"
      />
    );
  }
}
