import axios from 'axios';
import { AppUser } from '../../views/App/App';

export default class AdminService {
  api: string;
  user: AppUser;
  private readonly PREFIX = 'admin';

  constructor(user: AppUser, api: string) {
    this.api = `${api}/${this.PREFIX}`;
    this.user = user;
  }

  getUsers = (): Promise<any> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };

    return axios.get<any>(`${this.api}/users`, config).then((response) => response.data);
  };
}
