import { compose, withAppUser, withServices } from '../../../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import { Component } from 'react';
import * as React from 'react';
import Loader from '../../../../components/loader';
import ErrorIndicator from '../../../../components/error-indicator';
import { Contact } from '../../../../models/contact';
import Button from '../../../../components/button';
import SimpleModal from '../../../../components/simple-modal';
import ContactFormPage from './ContactForm';
import { ContactFormValues } from './ContactForm/ContactForm';
import { Tag } from '../../../../models/tag';
import * as BPromise from 'bluebird';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CSVImportFormPage from './CSVImportForm';
import { CsvImportFormValues } from './CSVImportForm/CSVImportForm';
import { AppUser } from '../../../App/App';
import {
  ContactsInviteGuestForm,
  ContactsInviteGuestFormValues,
} from './ContactsInviteGuestForm/ContactsInviteGuestForm';
import { Event } from '../../../../models/event';
import { Template } from '../../../../models/template';
import './Contacts.scss';

interface Props {
  user: AppUser;
}
interface State {
  isContactFormModalOpen: boolean;
  isCSVImportFormModalOpen: boolean;
  isLoading: boolean;
  isError: boolean;
  contacts: Contact[];
  activeContactId: string;
  tags: Tag[];
  emails: string[];
  isSelectAllChecked: boolean;
  isInviteGuestModalOpen: boolean;
  events: Event[];
  templates: Template[];
}

class UserContacts extends Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      isContactFormModalOpen: false,
      isCSVImportFormModalOpen: false,
      isLoading: false,
      isError: false,
      contacts: [],
      activeContactId: '',
      tags: [],
      emails: [],
      isSelectAllChecked: false,
      isInviteGuestModalOpen: false,
      events: [],
      templates: [],
    };
  }

  componentDidMount() {
    const { getContacts, getTags, getTemplates, getEvents } = this.props;
    this.setState({
      isLoading: true,
    });

    BPromise.all([getContacts(), getTags(), getTemplates(), getEvents()])
      .then(([contacts, tags, templates, events]) => {
        this.setState({
          isLoading: false,
          contacts,
          tags,
          templates,
          events,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  onCloseContactModalClick = () => {
    this.setState({
      isContactFormModalOpen: false,
    });
  };

  onSubmitAndCloseContactForm = (data: ContactFormValues) => {
    const { createContact, getContacts, updateContact } = this.props;
    const { activeContactId } = this.state;

    const promise = activeContactId ? updateContact(activeContactId, data) : createContact(data);

    this.setState({
      isLoading: true,
      isContactFormModalOpen: false,
    });

    promise
      .then((res) => getContacts())
      .then((contacts) => {
        this.setState({
          isLoading: false,
          contacts,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onSubmitAndContinueContactForm = (data: ContactFormValues) => {
    const { createContact, getContacts } = this.props;

    this.setState({
      isLoading: true,
      isContactFormModalOpen: false,
    });

    createContact(data)
      .then((res) => getContacts())
      .then((contacts) => {
        this.setState({
          isLoading: false,
          contacts,
          isContactFormModalOpen: true,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  renderContactFormModal(): React.ReactNode {
    const { isContactFormModalOpen, tags, contacts, activeContactId } = this.state;

    const contactFounded = contacts.find((contact) => contact.id === activeContactId);

    return (
      <SimpleModal isOpen={isContactFormModalOpen}>
        <ContactFormPage
          onSubmitAndClose={this.onSubmitAndCloseContactForm}
          onSubmitAndContinue={this.onSubmitAndContinueContactForm}
          onCancel={this.onCloseContactModalClick}
          tags={tags}
          contact={contactFounded}
          user={this.props.user}
        />
      </SimpleModal>
    );
  }

  renderCSVImportFormModal(): React.ReactNode {
    const { isCSVImportFormModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isCSVImportFormModalOpen}>
        <CSVImportFormPage onSubmit={this.onSubmitCSVImportForm} onCancel={this.onCloseCSVImportModalClick} />
      </SimpleModal>
    );
  }

  onSubmitCSVImportForm = (data: CsvImportFormValues) => {
    const { createContact, getContacts } = this.props;

    this.setState({
      isLoading: true,
      isCSVImportFormModalOpen: false,
    });

    const promises = data.contacts.map((contact) => createContact(contact));

    BPromise.all(promises)
      .then((res) => getContacts())
      .then((contacts) => {
        this.setState({
          isLoading: false,
          contacts,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onCloseCSVImportModalClick = () => {
    this.setState({
      isCSVImportFormModalOpen: false,
    });
  };

  onCreateContactClick = () => {
    this.setState({
      isContactFormModalOpen: true,
      activeContactId: '',
    });
  };

  onCSVImportClick = () => {
    this.setState({
      isCSVImportFormModalOpen: true,
    });
  };

  onEditContactButtonClick = (id: string) => {
    this.setState({
      isContactFormModalOpen: true,
      activeContactId: id,
    });
  };

  onRemoveContactButtonClick = (contactId: string) => {
    const { removeContact, getContacts } = this.props;

    this.setState({
      isLoading: true,
    });

    removeContact(contactId)
      .then((res) => getContacts())
      .then((contacts) => {
        this.setState({
          isLoading: false,
          contacts,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onSelectAllClick = () => {
    this.setState((prevState) => {
      return {
        isSelectAllChecked: !prevState.isSelectAllChecked,
        emails: prevState.isSelectAllChecked ? [] : prevState.contacts.map((contact) => contact.email),
      };
    });
  };

  onContactClick = (email: string) => {
    this.setState((prevState) => {
      const { emails } = prevState;
      const isEmail = emails.some((e) => e === email);

      return {
        emails: isEmail ? emails.filter((e) => e !== email) : [...prevState.emails, email],
      };
    });
  };

  onCloseInviteGuestModalClick = () => {
    this.setState({
      isInviteGuestModalOpen: false,
    });
  };

  onSubmitInviteGuestForm = (data: ContactsInviteGuestFormValues) => {
    const { inviteGuest } = this.props;
    const { eventId, ...rest } = data;

    this.setState({
      isLoading: true,
      isInviteGuestModalOpen: false,
      emails: [],
      isSelectAllChecked: false,
    });

    inviteGuest(eventId, rest)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  renderInviteGuestModal(): React.ReactNode {
    const { isInviteGuestModalOpen, templates, events, emails } = this.state;
    return (
      <SimpleModal isOpen={isInviteGuestModalOpen}>
        <ContactsInviteGuestForm
          templates={templates}
          events={events}
          emails={emails}
          onCancel={this.onCloseInviteGuestModalClick}
          onSubmit={this.onSubmitInviteGuestForm}
          user={this.props.user}
        />
      </SimpleModal>
    );
  }

  onInviteGuestClick = () => {
    this.setState({
      isInviteGuestModalOpen: true,
    });
  };

  render() {
    const {
      isContactFormModalOpen,
      isLoading,
      isError,
      contacts,
      isCSVImportFormModalOpen,
      emails,
      isSelectAllChecked,
      isInviteGuestModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const classes = isContactFormModalOpen || isCSVImportFormModalOpen || isInviteGuestModalOpen ? 'modal-open' : '';

    const rows = contacts.map((contact) => {
      const { firstName, lastName, email, id, tags } = contact;
      return (
        <tr className='contact contacts-content'>
          <th scope='row' className={'pe-0 py-3 contact-cell contact-cell-check'}>
            <div className="contacts-check">
              <input
                onChange={() => {
                  this.onContactClick(email);
                }}
                checked={emails.some((e) => e === email)}
                type="checkbox"
                className="form-check-input"
              />
            </div>
          </th>
          <td className={'pe-0 py-3 contact-cell'}>{firstName}</td>
          <td className={'pe-0 py-3 contact-cell'}>{lastName}</td>
          <td className={'pe-0 py-3 contact-cell'}>{email}</td>
          {tags.length > 0 ? (
            <td className={'pe-0 py-3 contact-cell'}>
              {tags.map((tag) => {
                return (
                  <span className="badge me-1" style={{ backgroundColor: `${tag.tagColor}` }} key={tag.id}>
                    {tag.tagName}
                  </span>
                );
              })}
            </td>
          ) : (
            <td className={'pe-0 py-3 contact-cell'} />
          )}

          <td className={'contact-cell pe-0 py-3 contact-btns'}>
            <Button
              onClick={() => this.onEditContactButtonClick(id)}
              customClass={'btn-default me-2 btn-sm'}
              text={[
                <FontAwesomeIcon key={`icon_button_edit_contact`} icon={['fas', 'edit']} />,
                <span className='d-sm-inline-block d-none' key={`span_button_edit_contact`}>
                  Edit
                </span>,
              ]}
            />
            <Button
              onClick={() => this.onRemoveContactButtonClick(id)}
              customClass={'btn-outline-danger btn-default btn-sm'}
              text={[
                <FontAwesomeIcon key={`icon_button_remove_contact`} icon={['fas', 'trash']} />,
                <span className='d-sm-inline-block d-none' key={`span_button_remove_contact`}>
                  Remove
                </span>,
              ]}
            />
          </td>
        </tr>
      );
    });

    return (
      <div className={classes}>
        {this.renderContactFormModal()}
        {this.renderCSVImportFormModal()}
        {this.renderInviteGuestModal()}
        <h1 className='page-title'>Contacts</h1>
        <div>
          <Button onClick={this.onCreateContactClick} text={'Add contact'} customClass={'me-3 btn-default'} />
          <Button onClick={this.onCSVImportClick} customClass={'me-3 btn-default'} text={'Import'} />
          <Button disabled={emails.length === 0} onClick={this.onInviteGuestClick} customClass={'btn-default'} text={'Invite guests'} />
        </div>
        <table className='table table-borderless contacts-table mt-5'>
          <thead>
            {contacts.length > 0 && (
              <tr className='table-primary contacts-table__header'>
                <th scope='col' className="contacts-check contact-cell-check">
                  <input
                    onChange={this.onSelectAllClick}
                    checked={isSelectAllChecked}
                    type="checkbox"
                    className="form-check-input"
                    id={'selectAll'}
                  />
                </th>
                <th scope='col' className="contact-cell">Name</th>
                <th scope='col' className="contact-cell">Surname</th>
                <th scope='col' className="contact-cell">Email</th>
                <th scope='col' className="contact-cell">Tags</th>
                <th scope='col' className="contact-cell"></th>
              </tr>
            )}
          </thead>
          <tbody>{contacts.length > 0 ? rows : 'No contacts'}</tbody>
        </table>

      </div>
    );
  }
}

const UserContactsPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(UserContacts);

export default UserContactsPage;
