import * as React from 'react';
import { AppUserConsumer } from '../app-user-context';

const withAppUser = (Wrapped: any) => {
  return (props: any) => {
    return <AppUserConsumer>{(user) => <Wrapped {...props} user={user} />}</AppUserConsumer>;
  };
};

export default withAppUser;
