import * as React from 'react';

interface Props {
  onLogoutClick: () => void;
}

export function SignOut(props: Props): null {
  props.onLogoutClick();
  return null;
}
