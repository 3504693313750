import axios from 'axios';
import { AppUser } from '../../views/App/App';

export default class FileService {
  fileApi: string;
  user: AppUser;

  constructor(user: AppUser, fileApi: string) {
    this.fileApi = fileApi;
    this.user = user;
  }

  uploadPublicFile = (formData: any): Promise<{ mimeType: string; url: string }> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };

    return axios
      .post<{ mimeType: string; url: string }>(`${this.fileApi}/storage/files/public/upload`, formData, config)
      .then((response) => response.data);
  };

  getFileApi = (): string => {
    return this.fileApi;
  };
}
