import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import Button from '../../../../../../components/button';
import { EventToDoListTaskFormValues } from './CreateEventToDoListTaskForm';
import { ToDoListTask } from '../../../../../../models/to-do-list';
import { FieldProps } from 'formik/dist/Field';
import DatePicker from 'react-datepicker';
import { Person } from '../../../../../../models/event';

const validationSchema = Yup.object().shape({
  text: Yup.string().required('Required'),
});

interface Props {
  onSubmit: (data: EventToDoListTaskFormValues) => void;
  task: ToDoListTask;
  coHosts: Person[];
  onCancel: () => void;
}

export function EditEventToDoListTaskForm(props: Props) {
  const { onSubmit, onCancel, task, coHosts } = props;
  const { text, deadline, subtasks, responsible } = task;

  const initialValues: EventToDoListTaskFormValues = {
    text: text,
    deadline: typeof deadline !== 'undefined' ? new Date(deadline) : undefined,
    responsibleUserId: typeof responsible !== 'undefined' ? responsible.userId : '',
    subtasks:
      subtasks.length > 0
        ? [...subtasks].map((subtask) => ({
            text: subtask.text,
          }))
        : [
            {
              text: '',
            },
          ],
  };

  return (
    <div className={'mt-3 mb-3'}>
      <h3 className={'mb-3 text-center mt-3'}>Edit task</h3>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, setFieldValue, values }) => (
          <Form>
            <div className="form-group">
              <label>Task text</label>
              <Field placeholder={'Task text'} name="text" className="form-control mb-3" />
              <ErrorMessage component="div" className="alert alert-danger" name="text" />
            </div>

            <div className="form-group">
              <label>Deadline</label>
              <Field name="deadline">
                {({ field }: FieldProps) => (
                  <div>
                    <DatePicker
                      selected={field.value}
                      className="form-control mb-3"
                      dateFormat={'dd-MM-yyyy HH:mm'}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="Time"
                      onChange={(deadline) => {
                        setFieldValue('deadline', deadline);
                      }}
                    />
                  </div>
                )}
              </Field>
            </div>

            <div className="form-group">
              <label>Responsible</label>
              <Field
                name="responsibleUserId"
                component="select"
                placeholder="Select responsible"
                className="form-control mb-3"
              >
                <option value={''}>Select responsible</option>
                {coHosts.map((coHost) => (
                  <option value={coHost.userId} key={coHost.userId}>
                    {!coHost.firstName && !coHost.lastName ? coHost.email : `${coHost.firstName} ${coHost.lastName}`}
                  </option>
                ))}
              </Field>
            </div>

            <div className="form-group">
              <label>Sub tasks</label>
              <FieldArray name="subtasks">
                {({ insert, remove, push }) => (
                  <div>
                    {values.subtasks.length > 0 &&
                      values.subtasks.map((subtask: { text: string }, index: number) => (
                        <div className="d-table-row" key={index}>
                          <div className="d-table-cell">
                            <div className={'mb-3 me-1'}>{index + 1}.</div>
                          </div>
                          <div className="d-table-cell">
                            <Field
                              name={`subtasks.${index}.text`}
                              className="form-control mb-3 "
                              placeholder="Enter text here"
                            />
                          </div>
                          <div className="d-table-cell">
                            <button type="button" className="mb-3  btn-close" onClick={() => remove(index)} />
                          </div>
                        </div>
                      ))}
                    <button type="button" className="btn btn-secondary" onClick={() => push({ text: '' })}>
                      Add Sub Task
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>

            <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3 btn-secondary'} />
            <Button onClick={handleSubmit} text={'Edit Task'} customClass={'mt-3 mb-3'} type={'submit'} />
          </Form>
        )}
      </Formik>
    </div>
  );
}
