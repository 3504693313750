import * as React from 'react';
import { Component } from 'react';
import { match, RouteComponentProps, withRouter } from 'react-router';
import { History } from 'history';
import StorageHelper from '../../helpers/storage';

interface Props {
  match: match;
  history: History;
  onLogin: (userId: string, key: string, type: string) => void;
}
interface State {}

class SocialAuth extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
  }

  componentDidMount() {
    const { match, onLogin, history } = this.props;
    const { params } = match as any;
    const { userId, sessionId, typeId } = params;

    const session = {
      userId,
      key: sessionId,
      type: typeId,
    };

    StorageHelper.Cookie.set('session', session);

    onLogin(userId, sessionId, typeId);
    history.push(typeId === 'SUPERADMIN' ? '/users' : '/events');
  }

  render(): null {
    return null;
  }
}

export default withRouter(SocialAuth);
