import * as React from 'react';
import { ServicesConsumer } from '../services-context';
import { Services } from '../services-context/services-context';

const withServices = (mapMethodsToProps: (services: Services) => any) => (Wrapped: any) => {
  return (props: any) => {
    return (
      <ServicesConsumer>
        {(services: Services) => {
          const servicesProps = mapMethodsToProps(services);

          return <Wrapped {...props} {...servicesProps} />;
        }}
      </ServicesConsumer>
    );
  };
};

export default withServices;
