import * as React from 'react';
import { ReactNode } from 'react';
import './tabs.scss';

interface Props {
  tabs: string[];
  activeTabIndex: number;
  onTabClick: (index: number) => void;
  children?: ReactNode;
}

export function Tabs(props: Props) {
  const { tabs, activeTabIndex, onTabClick, children } = props;
  const tabsHeader = tabs.map((tab, index) => {
    const tabClassName = activeTabIndex === index ? 'event-details-nav__link event-details-nav__link--active' : 'event-details-nav__link';

    const onClick = (event: React.MouseEvent<HTMLAnchorElement>, index: number) => {
      event.preventDefault();
      onTabClick(index);
    };

    return (
      <li key={`tab_${index}`} className="event-details-nav__item">
        <a
          className={tabClassName}
          href=""
          onClick={(event) => {
            onClick(event, index);
          }}
        >
          {tab}
        </a>
      </li>
    );
  });

  return (
    <div>
      <ul className="event-details-nav">{tabsHeader}</ul>
      {children && <div>{children}</div>}
    </div>
  );
}
