import * as React from 'react';
import LoginForm from '../../components/login-form';
import { withRouter } from 'react-router';
import { Component } from 'react';
import { History } from 'history';
import StorageHelper from '../../helpers/storage';
import { compose, withServices } from '../../components/hoc-helpers';
import { Session } from '../../models/session';
import { Services } from '../../components/services-context/services-context';
import { USER_TYPE } from '../../types/user';
import { MapMethodsToProps, mapMethodsToProps } from '../../helpers/component';

interface Props {
  onLogin: (userId: string, key: string, type: USER_TYPE) => void;
  history: History;
}

interface State {
  email: string;
  password: string;
  isRememberMe: boolean;
  isError: boolean;
}

class Login extends Component<Props & MapMethodsToProps, State> {
  constructor(props: Props & MapMethodsToProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isRememberMe: false,
      isError: false,
    };
  }

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ email: value });
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ password: value });
  };

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { email, password, isRememberMe } = this.state;
    const { history, onLogin, login } = this.props;

    login(email, password)
      .then((session: Session) => {
        const { key, userId, type } = session;

        if (isRememberMe) {
          // one year cookie. just count of days because js-cookie convert days to msec itself
          StorageHelper.Cookie.set('session', session, { expires: 365 });
        } else {
          StorageHelper.Cookie.set('session', session);
        }

        onLogin(userId, key, type);
        history.push(type === 'SUPERADMIN' ? '/users' : '/events/upcoming');
      })
      .catch((error: any) => {
        this.setState({ isError: true });
      });
  };

  onRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.checked;
    this.setState({ isRememberMe: value });
  };

  render() {
    const { email, password, isRememberMe, isError } = this.state;
    return (
      <LoginForm
        email={email}
        password={password}
        onEmailChange={this.onEmailChange}
        onPasswordChange={this.onPasswordChange}
        onFormSubmit={this.onFormSubmit}
        isRememberMe={isRememberMe}
        onRememberMeChange={this.onRememberMeChange}
        isError={isError}
        caption={'Welcome to EventBerry!'}
      />
    );
  }
}

const LoginPage = compose(withServices(mapMethodsToProps), withRouter)(Login);

export default LoginPage;
