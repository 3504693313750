import * as React from 'react';
import Tabs from '../../../../components/tabs';
import { EVENT_TABS, TABS } from '../../../../consts/tabs';
import Loader from '../../../../components/loader';
import ErrorIndicator from '../../../../components/error-indicator';
import { compose, withAppUser, withServices } from '../../../../components/hoc-helpers';
import { RouteComponentProps, withRouter } from 'react-router';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../helpers/component';
import { Event } from '../../../../models/event';
import EventToDoLists from './EventToDoLists/EventToDoLists';
import EventInvitationsPage from './EventInvitations/EventInvitations';
import EventGuestsPage from './EventGuests';
import EventDetailsPage from './EventDetails';

interface Props {}

interface State {
  activeTabIndex: number;
  isLoading: boolean;
  isError: boolean;
  event: Event;
}

class UserEvent extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      activeTabIndex: 0,
      isLoading: true,
      isError: false,
      event: undefined,
    };
  }

  componentDidMount() {
    const { getEvent, match } = this.props;
    const { params } = match as any;
    const { eventId } = params;

    this.setState({
      isLoading: true,
    });

    getEvent(eventId)
      .then((event) => {
        this.setState({
          isLoading: false,
          event: event,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  onTabClick = (index: number) => {
    this.setState({
      activeTabIndex: index,
    });
  };

  getContent(): React.ReactNode {
    const { activeTabIndex, event } = this.state;
    const activeTab = EVENT_TABS[activeTabIndex];

    switch (activeTab) {
      case TABS.DETAILS:
        return <EventDetailsPage event={event} />;
      case TABS.TO_DO_LISTS:
        return <EventToDoLists event={event} eventId={event.id} />;
      case TABS.INVITATIONS:
        return <EventInvitationsPage eventId={event.id} />;
      case TABS.GUESTS:
        return <EventGuestsPage event={event} eventId={event.id} />;
      default:
        return <h1>Coming soon</h1>;
    }
  }

  render() {
    const { activeTabIndex, isLoading, isError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const content = this.getContent();

    return (
      <Tabs tabs={EVENT_TABS} activeTabIndex={activeTabIndex} onTabClick={this.onTabClick}>
        {content}
      </Tabs>
    );
  }
}

const UserEventPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(UserEvent);

export default UserEventPage;
