import * as React from 'react';
import { Component } from 'react';
import Button from '../../../../components/button';
import SimpleModal from '../../../../components/simple-modal';
import { EventType } from '../../../../models/event-type';
import Loader from '../../../../components/loader';
import ErrorIndicator from '../../../../components/error-indicator';
import { compose, withAppUser, withServices } from '../../../../components/hoc-helpers';
import { RouteComponentProps, withRouter } from 'react-router';
import EventForm from './EventForm';
import EventFormWithBot from './EventFormWithBot';
import { EventFormValues } from './EventForm/EventForm';
import { Event } from '../../../../models/event';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from '../../../App/App';
import InviteCoHostForm from './InviteCoHostForm';
import { InviteCoHostFormValues } from './InviteCoHostForm/InviteCoHostForm';
import { History } from 'history';
import { mapMethodsToProps, MapMethodsToProps } from '../../../../helpers/component';
import InviteGuestFormPage from './InviteGuestForm';
import { InviteGuestFormValues } from './InviteGuestForm/InviteGuestForm';
import { Template } from '../../../../models/template';
import { EventFormWithBotValues } from './EventFormWithBot/EventFormWithBot';
import EventItem from '../../../../components/events/EventItem';
import NoEventsModal from './NoEventsModal';
import './Events.scss';
interface Props {
  user: AppUser;
  history: History;
}

type EventsFilterByPerson = 'ALL' | 'HOST' | 'CO-HOST' | 'GUEST';
type EventsFilterByDate = 'ALL' | 'UPCOMING' | 'PAST';

interface State {
  isLoading: boolean;
  isCreateEventModalOpen: boolean;
  isCreateEventWithBotModalOpen: boolean;
  isInviteCoHostModalOpen: boolean;
  isInviteGuestModalOpen: boolean;
  isShowFilters: boolean;
  isNoEventsModalOpen: boolean;
  isError: boolean;
  eventTypes: EventType[];
  events: Event[];
  templates: Template[];
  eventsFilterByDate: EventsFilterByDate;
  eventsFilterByPerson: EventsFilterByPerson;
  eventIdSelected: string;
}

class UserEvents extends Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      isLoading: false,
      isCreateEventModalOpen: false,
      isCreateEventWithBotModalOpen: false,
      isInviteCoHostModalOpen: false,
      isInviteGuestModalOpen: false,
      isShowFilters: false,
      isNoEventsModalOpen: false,
      eventTypes: [],
      events: [],
      templates: [],
      isError: false,
      eventsFilterByDate: 'ALL',
      eventsFilterByPerson: 'ALL',
      eventIdSelected: '',
    };
  }

  componentDidMount() {
    const { getEvents, getEventTypes, getTemplates } = this.props;
    this.setState({
      isLoading: true,
    });

    BPromise.all([getEvents(), getEventTypes(), getTemplates()])
      .then(([events, eventTypes, templates]) => {
        this.setState({
          isLoading: false,
          eventTypes,
          events,
          templates,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  onCreateEventClick = () => {
    this.setState({
      isCreateEventModalOpen: true,
    });
  };

  onCloseCreateEventModalClick = () => {
    this.setState({
      isCreateEventModalOpen: false,
    });
  };

  onSubmitCreateEventForm = (data: EventFormValues) => {
    const { eventTypes } = this.state;
    const { createEvent, getEvents } = this.props;
    const { name, eventTypeId, start, end, isNotSureDate, place, isNotSureVenue, isOnlineEvent, venue } = data;

    const eventTypeName = eventTypes.find((eventType) => eventType.id === eventTypeId).name;

    const dataToPost = {
      name,
      isOnlineEvent,
      eventType: {
        eventTypeId,
        eventTypeName,
      },
      start: isNotSureDate ? undefined : start.toISOString(),
      end: isNotSureDate ? undefined : end.toISOString(),
      place: isNotSureVenue ? undefined : place,
      venue: isNotSureVenue ? undefined : venue,
    };

    this.setState({
      isLoading: true,
      isCreateEventModalOpen: false,
    });

    createEvent(dataToPost)
      .then((res) => getEvents())
      .then((events) => {
        this.setState({
          isLoading: false,
          events,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onSubmitCreateEventFormWithBot = (data: EventFormWithBotValues) => {
    const { eventTypes } = this.state;
    const { createEvent, getEvents } = this.props;
    const { name, eventTypeId, start, end, venue, isNotSureDate, isNotSureVenue } = data;

    const eventTypeName = eventTypes.find((eventType) => eventType.id === eventTypeId).name;

    const dataToPost = {
      name,
      isOnlineEvent: false,
      eventType: {
        eventTypeId,
        eventTypeName,
      },
      start: isNotSureDate ? undefined : start.toISOString(),
      end: isNotSureDate ? undefined : end.toISOString(),
      venue: isNotSureVenue ? undefined : venue,
    };

    this.setState({
      isLoading: true,
      isCreateEventWithBotModalOpen: false,
    });

    createEvent(dataToPost)
      .then((res) => getEvents())
      .then((events) => {
        this.setState({
          isLoading: false,
          events,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  renderCreateEventModal(): React.ReactNode {
    const { isCreateEventModalOpen, eventTypes } = this.state;
    return (
      <SimpleModal isOpen={isCreateEventModalOpen}>
        <EventForm
          eventTypes={eventTypes}
          onCancel={this.onCloseCreateEventModalClick}
          onSubmit={this.onSubmitCreateEventForm}
        />
      </SimpleModal>
    );
  }

  onCloseInviteCoHostModalClick = () => {
    this.setState({
      isInviteCoHostModalOpen: false,
    });
  };

  onSubmitInviteCoHostForm = (data: InviteCoHostFormValues) => {
    const { inviteCoHost } = this.props;
    const { eventIdSelected } = this.state;

    this.setState({
      isLoading: true,
      isInviteCoHostModalOpen: false,
    });

    inviteCoHost(eventIdSelected, data)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  renderInviteCoHostModal(): React.ReactNode {
    const { isInviteCoHostModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isInviteCoHostModalOpen}>
        <InviteCoHostForm onCancel={this.onCloseInviteCoHostModalClick} onSubmit={this.onSubmitInviteCoHostForm} />
      </SimpleModal>
    );
  }

  onCloseInviteGuestModalClick = () => {
    this.setState({
      isInviteGuestModalOpen: false,
    });
  };

  onSubmitInviteGuestForm = (data: InviteGuestFormValues) => {
    const { inviteGuest } = this.props;
    const { eventIdSelected } = this.state;

    this.setState({
      isLoading: true,
      isInviteGuestModalOpen: false,
    });

    inviteGuest(eventIdSelected, data)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  renderInviteGuestModal(): React.ReactNode {
    const { isInviteGuestModalOpen, templates } = this.state;
    return (
      <SimpleModal isOpen={isInviteGuestModalOpen}>
        <InviteGuestFormPage
          templates={templates}
          onCancel={this.onCloseInviteGuestModalClick}
          onSubmit={this.onSubmitInviteGuestForm}
        />
      </SimpleModal>
    );
  }

  renderNoEventsModal(): React.ReactNode {
    const { isNoEventsModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isNoEventsModalOpen}>
        <NoEventsModal onCloseClick={this.onCloseNoEventsModalClick} />
      </SimpleModal>
    );
  }

  onCloseNoEventsModalClick = () => {
    this.setState({
      isNoEventsModalOpen: false
    })
  }

  onEventsFilterByDateButtonClick = (filter: EventsFilterByDate) => {
    const { eventsFilterByDate, eventsFilterByPerson } = this.state;
    if (eventsFilterByDate !== filter) {
      this.setState({
        isLoading: true,
      });

      const filterByPerson = this.getEventsFilter(eventsFilterByPerson);
      const eventsPromise = this.getEventsPromise(filter, filterByPerson);

      eventsPromise
        .then((events) => {
          const isNoEventsModalOpen = events.length === 0;
          this.setState({
            isLoading: false,
            eventsFilterByDate: filter,
            events: events,
            isNoEventsModalOpen: isNoEventsModalOpen
          });
        })
        .catch(() => {
          this.setState({
            isError: true,
            isLoading: false,
          });
        });
    }
  };

  getEventsPromise(filter: EventsFilterByDate, filterByPerson?: any): Promise<Event[]> {
    const { getEvents, getPastEvents, getUpcomingEvents } = this.props;
    let eventsPromise: Promise<Event[]>;

    switch (filter) {
      case 'ALL':
        eventsPromise = getEvents(filterByPerson);
        break;
      case 'PAST':
        eventsPromise = getPastEvents(filterByPerson);
        break;
      case 'UPCOMING':
        eventsPromise = getUpcomingEvents(filterByPerson);
        break;
    }
    return eventsPromise;
  }

  getEventsFilter(filter: EventsFilterByPerson): any {
    const { user } = this.props;
    const { userId } = user;
    switch (filter) {
      case 'ALL':
        return undefined;
      case 'HOST':
        return { where: { 'host.userId': userId } };
      case 'CO-HOST':
        return { where: { 'coHosts.userId': userId } };
      case 'GUEST':
        return { where: { 'guests.userId': userId } };
    }
  }

  onEventsFilterByPersonButtonClick = (filter: EventsFilterByPerson) => {
    const { eventsFilterByPerson, eventsFilterByDate } = this.state;
    if (eventsFilterByPerson !== filter) {
      this.setState({
        isLoading: true,
      });

      const filterByPerson = this.getEventsFilter(filter);
      const eventsPromise = this.getEventsPromise(eventsFilterByDate, filterByPerson);

      eventsPromise
        .then((events) => {
          const isNoEventsModalOpen = events.length === 0;
          this.setState({
            isLoading: false,
            eventsFilterByPerson: filter,
            events: events,
            isNoEventsModalOpen: isNoEventsModalOpen
          });
        })
        .catch(() => {
          this.setState({
            isError: true,
            isLoading: false,
          });
        });
    }
  };

  onInviteCoHostClick = (eventId: string) => {
    this.setState({
      isInviteCoHostModalOpen: true,
      eventIdSelected: eventId,
    });
  };

  onInviteGuestClick = (eventId: string) => {
    this.setState({
      isInviteGuestModalOpen: true,
      eventIdSelected: eventId,
    });
  };

  onManageClick = (eventId: string) => {
    const { history } = this.props;
    history.push(`/events/${eventId}`);
  };

  onCreateEventWithBotClick = () => {
    this.setState({
      isCreateEventWithBotModalOpen: true,
    });
  };

  onCloseCreateEventWithBotModalClick = () => {
    this.setState({
      isCreateEventWithBotModalOpen: false,
    });
  };

  renderCreateEventWithBotModal(): React.ReactNode {
    const { isCreateEventWithBotModalOpen, eventTypes } = this.state;
    return (
      <SimpleModal
        customClass={'modal-xl modal-dialog-scrollable'}
        isOpen={isCreateEventWithBotModalOpen}
        onCloseButtonClick={this.onCloseCreateEventWithBotModalClick}
      >
        <EventFormWithBot
          eventTypes={eventTypes}
          onCancel={this.onCloseCreateEventWithBotModalClick}
          onSubmit={this.onSubmitCreateEventFormWithBot}
        />
      </SimpleModal>
    );
  }

  onShowOrHideFiltersClick = () => {
    this.setState(prevState => {
      return {
        isShowFilters: !prevState.isShowFilters
      }
    })
  }

  render() {
    const {
      isCreateEventModalOpen,
      isLoading,
      isError,
      events,
      eventsFilterByDate,
      eventsFilterByPerson,
      isInviteCoHostModalOpen,
      isInviteGuestModalOpen,
      isCreateEventWithBotModalOpen,
      isShowFilters
    } = this.state;
    const classes =
      isCreateEventModalOpen || isInviteCoHostModalOpen || isInviteGuestModalOpen || isCreateEventWithBotModalOpen
        ? 'modal-open'
        : '';

    const userEmail = propz.get(this.props.user, ['profile', 'email'], '');

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const eventItems = events.map((event) => {
      return (
        <EventItem event={event} onManageClick={this.onManageClick} userEmail={userEmail} key={event.id} />
      );
    });

    const nonActiveFilterButtonClasses = 'btn-outline-secondary me-3 filter-btn';
    const activeFilterButtonClasses = 'me-3 filter-btn filter-btn-outline';

    return (
      <div className={classes}>
        {this.renderCreateEventModal()}
        {this.renderCreateEventWithBotModal()}
        {this.renderInviteCoHostModal()}
        {this.renderInviteGuestModal()}
        {this.renderNoEventsModal()}
        <Button customClass={'me-3'} onClick={this.onCreateEventClick} text={'Create event'} />
        <Button onClick={this.onCreateEventWithBotClick} text={'Create event with bot'} />

        <div className="mt-3 mb-3">
          <Button
            customClass={eventsFilterByDate === 'ALL' ? activeFilterButtonClasses : nonActiveFilterButtonClasses}
            onClick={() => this.onEventsFilterByDateButtonClick('ALL')}
            text={'All events'}
          />
          <Button
            customClass={eventsFilterByDate === 'UPCOMING' ? activeFilterButtonClasses : nonActiveFilterButtonClasses}
            onClick={() => this.onEventsFilterByDateButtonClick('UPCOMING')}
            text={'Upcoming events'}
          />
          <Button
            customClass={eventsFilterByDate === 'PAST' ? activeFilterButtonClasses : nonActiveFilterButtonClasses}
            onClick={() => this.onEventsFilterByDateButtonClick('PAST')}
            text={'Past events'}
          />
        </div>

        <div className="events">
          <div className="title-wrapper">
            <h1 className='page-title'>{eventsFilterByDate} Events</h1>
            <button className="show-filters" onClick={this.onShowOrHideFiltersClick}>{isShowFilters ? 'Hide-filters' : 'Show-filters'}</button>
          </div>
          {
            isShowFilters && <div className='filters'>
              <Button
                customClass={eventsFilterByPerson === 'ALL' ? activeFilterButtonClasses : nonActiveFilterButtonClasses}
                onClick={() => this.onEventsFilterByPersonButtonClick('ALL')}
                text={'All events'}
              />
              <Button
                customClass={eventsFilterByPerson === 'HOST' ? activeFilterButtonClasses : nonActiveFilterButtonClasses}
                onClick={() => this.onEventsFilterByPersonButtonClick('HOST')}
                text={'I am a Host'}
              />
              <Button
                customClass={eventsFilterByPerson === 'CO-HOST' ? activeFilterButtonClasses : nonActiveFilterButtonClasses}
                onClick={() => this.onEventsFilterByPersonButtonClick('CO-HOST')}
                text={'I am a Co-Host'}
              />
            </div>
          }

          {eventItems}
        </div>
      </div>
    );
  }
}

const UserEventsPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(UserEvents);

export default UserEventsPage;
