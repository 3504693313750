import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../../../../components/button';
import { EventType } from '../../../../../models/event-type';
import DatePicker from 'react-datepicker';
import { FieldProps } from 'formik/dist/Field';
import SwitchField from '../../../../../components/switch-field';
import PlaceAutocomplete from '../../../../../components/place-autocomplete';
import { PlaceAutocompleteData } from '../../../../../components/place-autocomplete/place-autocomplete';
import { DEFAULT_LAT, DEFAULT_LNG } from '../../../../../consts/common';
import Map from '../../../../../components/map';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  eventTypeId: Yup.string().required('Required'),
  start: Yup.date().max(Yup.ref('end'), 'Must be less then end'),
  end: Yup.date().min(Yup.ref('start'), 'Must be more then start'),
});

interface Props {
  onSubmit: (data: EventFormValues) => void;
  onCancel: () => void;
  eventTypes: EventType[];
}

export interface EventFormValues {
  name: string;
  eventTypeId: string;
  start: Date;
  end: Date;
  isNotSureDate: boolean;
  isNotSureVenue: boolean;
  isOnlineEvent: boolean;
  venue: string;
  place?: PlaceAutocompleteData;
}

export function EventForm(props: Props) {
  const { onCancel, onSubmit, eventTypes } = props;

  const initialTime = new Date();
  initialTime.setHours(10, 0);

  const initialValues: EventFormValues = {
    name: '',
    eventTypeId: '',
    start: initialTime,
    end: initialTime,
    isNotSureDate: false,
    isNotSureVenue: false,
    isOnlineEvent: false,
    venue: '',
    place: {
      address: '',
      postcode: '',
      country: '',
      town: '',
      lat: DEFAULT_LAT,
      lng: DEFAULT_LNG,
    },
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h3 className={'mb-3 text-center mt-3'}>My event</h3>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit, values, setFieldValue }) => (
              <Form>
                <div className="form-group">
                  <Field placeholder={'Let us start with your event name'} name="name" className="form-control mb-3" />
                  <ErrorMessage component="div" className="alert alert-danger" name="name" />
                </div>

                <div className="form-group">
                  <Field
                    name="eventTypeId"
                    component="select"
                    placeholder="Select your event type"
                    className="form-control mb-3"
                  >
                    <option value={''}>Select your event type</option>
                    {eventTypes.map((eventType) => (
                      <option value={eventType.id} key={eventType.id}>
                        {eventType.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component="div" className="alert alert-danger" name="eventTypeId" />
                </div>

                <div className="row">
                  <div className="col">
                    <div>Start date</div>
                    <Field name="start">
                      {({ field }: FieldProps) => (
                        <DatePicker
                          selected={field.value}
                          disabled={values.isNotSureDate}
                          className="form-control mb-3"
                          dateFormat={'dd-MM-yyyy HH:mm'}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="Time"
                          onChange={(start) => {
                            setFieldValue('start', start);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage component="div" className="border-0 alert alert-danger" name="start" />
                  </div>

                  <div className="col">
                    <div>End date</div>
                    <Field name="end">
                      {({ field }: FieldProps) => (
                        <DatePicker
                          selected={field.value}
                          disabled={values.isNotSureDate}
                          className="form-control mb-3"
                          dateFormat={'dd-MM-yyyy HH:mm'}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="Time"
                          onChange={(end) => {
                            setFieldValue('end', end);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage component="div" className="border-0 alert alert-danger" name="end" />
                  </div>
                </div>

                <div className="form-group">
                  <Field name="isNotSureDate">
                    {({ field }: FieldProps) => (
                      <SwitchField
                        {...field}
                        onChange={(event) => {
                          if (!values.isNotSureDate) {
                            setFieldValue('start', new Date());
                            setFieldValue('end', new Date());
                          }
                          setFieldValue('isNotSureDate', !values.isNotSureDate);
                        }}
                        text="Not sure"
                        customClass="mb-3"
                      />
                    )}
                  </Field>
                </div>

                <div className="form-group">
                  <Field name="place">
                    {({ field }: FieldProps) => (
                      <PlaceAutocomplete
                        isDisabled={values.isNotSureVenue}
                        onPlaceSelected={(data: PlaceAutocompleteData) => {
                          setFieldValue('place', data);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className="form-group">
                  <Field
                    disabled={values.isNotSureVenue}
                    placeholder={'Place name'}
                    name="venue"
                    className="form-control mb-3"
                  />
                </div>

                <div className={'mt-3 mb-3'}>
                  <Map lat={values.place.lat} lng={values.place.lng} />
                </div>

                <div className="row">
                  <div className="col">
                    <Field name="isNotSureVenue">
                      {({ field }: FieldProps) => (
                        <SwitchField
                          {...field}
                          text="Not sure"
                          customClass="mb-3"
                          onChange={(event) => {
                            setFieldValue('isNotSureVenue', !values.isNotSureVenue);
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col">
                    <Field name="isOnlineEvent">
                      {({ field }: FieldProps) => <SwitchField {...field} text="Online event" customClass="mb-3" />}
                    </Field>
                  </div>
                </div>

                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3 btn-secondary'} />
                <Button onClick={handleSubmit} text={'Create my event!'} customClass={'mt-3 mb-3'} type={'submit'} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
