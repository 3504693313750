import * as React from 'react';
import { Component } from 'react';
import { History } from 'history';
import { match, RouteComponentProps, withRouter } from 'react-router';
import { mapMethodsToProps, MapMethodsToProps } from '../../helpers/component';
import Loader from '../../components/loader';
import ErrorIndicator from '../../components/error-indicator';
import { compose, withAppUser, withServices } from '../../components/hoc-helpers';

interface Props {
  match: match;
  history: History;
}

interface State {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

class Tokens extends Component<Props & RouteComponentProps & MapMethodsToProps, State> {
  constructor(props: Props & RouteComponentProps & MapMethodsToProps) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
      isSuccess: false,
    };
  }

  componentDidMount() {
    const { match, checkTokenEmail } = this.props;

    const { params } = match as any;
    const { tokenEmail } = params;

    this.setState({
      isLoading: true,
    });

    checkTokenEmail(tokenEmail)
      .then((res) => {
        this.setState({
          isLoading: false,
          isSuccess: true,
        });
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  render() {
    const { isLoading, isError, isSuccess } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    if (isSuccess) {
      return <h1>Email check success</h1>;
    }

    return null;
  }
}

const TokensPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(Tokens);

export default TokensPage;
