import * as React from 'react';
import { Component } from 'react';
import Loader from '../../../../components/loader';
import ErrorIndicator from '../../../../components/error-indicator';
import { compose, withServices } from '../../../../components/hoc-helpers';
import { withRouter } from 'react-router';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../helpers/component';

interface Props {}
interface State {
  users: any;
  isLoading: boolean;
  isError: boolean;
}

class Users extends Component<Props & MapMethodsToProps, State> {
  constructor(props: Props & MapMethodsToProps) {
    super(props);

    this.state = {
      users: undefined,
      isLoading: true,
      isError: false,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
      isError: false,
    });

    this.props
      .getUsers()
      .then((users) => {
        this.setState({
          users,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  render() {
    const { users, isLoading, isError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const rows = users.map((user: any, index: number) => {
      return (
        <tr key={`users_summary_${index}`}>
          <td>{index + 1}</td>
          <td>{user.email}</td>
          <td>{user.createdAt}</td>
        </tr>
      );
    });

    const emptyRow = (
      <tr key={'users_empty_row'}>
        <td>No users</td>
      </tr>
    );

    return (
      <div>
        <h1>Users table</h1>
        <table className={'table table-sm mPre'}>
          <thead>
            <tr>
              <th />
              <th>Email</th>
              <th>Created at</th>
            </tr>
          </thead>
          <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
        </table>
      </div>
    );
  }
}

const UsersPage = compose(withServices(mapMethodsToProps), withRouter)(Users);

export default UsersPage;
