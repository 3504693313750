import * as React from 'react';
import { Component } from 'react';
import { compose, withAppUser, withServices } from '../../../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import Button from '../../../../components/button';
import Loader from '../../../../components/loader';
import ErrorIndicator from '../../../../components/error-indicator';
import SimpleModal from '../../../../components/simple-modal';
import { Tag } from '../../../../models/tag';
import TagForm from './TagForm';
import { TagFormValues } from './TagForm/TagForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Tags.scss';

interface Props { }
interface State {
  isTagFormModalOpen: boolean;
  isLoading: boolean;
  isError: boolean;
  tags: Tag[];
  activeTagId: string;
}

class UserTags extends Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      isTagFormModalOpen: false,
      isLoading: false,
      isError: false,
      tags: [],
      activeTagId: '',
    };
  }

  componentDidMount() {
    const { getTags } = this.props;
    this.setState({
      isLoading: true,
    });

    getTags()
      .then((tags) => {
        this.setState({
          isLoading: false,
          tags,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  onCreateTagClick = () => {
    this.setState({
      isTagFormModalOpen: true,
      activeTagId: '',
    });
  };

  onCloseCreateTagModalClick = () => {
    this.setState({
      isTagFormModalOpen: false,
      activeTagId: '',
    });
  };

  onSubmitTagForm = (data: TagFormValues) => {
    const { createTag, updateTag, getTags } = this.props;
    const { activeTagId } = this.state;

    this.setState({
      isLoading: true,
      isTagFormModalOpen: false,
    });

    const promise = activeTagId ? updateTag(activeTagId, data) : createTag(data);

    promise
      .then((res) => getTags())
      .then((tags) => {
        this.setState({
          isLoading: false,
          tags,
          activeTagId: '',
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  renderTagFormModal(): React.ReactNode {
    const { isTagFormModalOpen, activeTagId, tags } = this.state;

    const tagFounded = tags.find((tag) => tag.id === activeTagId);

    return (
      <SimpleModal isOpen={isTagFormModalOpen}>
        <TagForm tag={tagFounded} onCancel={this.onCloseCreateTagModalClick} onSubmit={this.onSubmitTagForm} />
      </SimpleModal>
    );
  }

  onEditTagButtonClick = (id: string) => {
    this.setState({
      isTagFormModalOpen: true,
      activeTagId: id,
    });
  };

  onRemoveTagButtonClick = (tagId: string) => {
    const { removeTag, getTags } = this.props;

    this.setState({
      isLoading: true,
    });

    removeTag(tagId)
      .then((res) => getTags())
      .then((tags) => {
        this.setState({
          isLoading: false,
          tags,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  render() {
    const { isTagFormModalOpen, isLoading, isError, tags } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const classes = isTagFormModalOpen ? 'modal-open' : '';

    const rows = tags.map((tag, index) => {
      const { id, name, color } = tag;
      return (
        <div key={id} className={'tags-table-content tags-table-row my-2'}>
          <div className="tags-table-row__item tags-check">
            <input
              type="checkbox"
              className="form-check-input"
            />
          </div>
          <div className={'tags-table-text tags-table-row__item'}>{name}</div>
          <div className={'tags-table-row__item'}>
            <div
              className={'tag-color-wrapper d-inline-block align-middle'}
            >
              <div
                className='tag-color'
                style={{
                  backgroundColor: `${color}`,
                }}
              />
            </div>
          </div>

          <div className='tag-btns tags-table-row__item d-flex justify-content-end'>
            <div>
              <Button
                onClick={() => this.onEditTagButtonClick(id)}
                customClass={'btn-default me-3'}
                text={[
                  <FontAwesomeIcon key={`icon_button_edit_tag`} icon={['fas', 'edit']} />,
                  <span key={`span_button_edit_tag`}>
                    Edit
                  </span>,
                ]}
              />
            </div>
            <div>
              <Button
                onClick={() => this.onRemoveTagButtonClick(id)}
                customClass={'btn-outline-danger btn-default'}
                text={[
                  <FontAwesomeIcon key={`icon_button_remove_tag`} icon={['fas', 'trash']} />,
                  <span key={`span_button_remove_tag`}>
                    Remove
                  </span>,
                ]}
              />
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className={classes}>
        {this.renderTagFormModal()}
        <h1 className='page-title'>Tags</h1>
        <Button onClick={this.onCreateTagClick} text={'Create tag'} customClass='btn-default mb-5' />
        <div className="tags-table">
          <div className="tags-table-header tags-table-row mb-3">
            <div className="tags-check">
              <input
                type="checkbox"
                className="form-check-input"
              />
            </div>
            <div className="tags-table-header__item tags-table-row__item">Name</div>
            <div className="tags-table-header__item tags-table-row__item">Colour</div>
            <div className="tags-table-header__item tags-table-row__item"></div>
          </div>
          <div className={'tags-table'}>{rows}</div>
        </div>
      </div>
    );
  }
}

const UserTagsPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(UserTags);

export default UserTagsPage;
