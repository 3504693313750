import * as React from 'react';
import './error-indicator.scss';

interface Props {
  errorText?: string;
}

const ErrorIndicator = ({ errorText }: Props) => {
  return (
    <div className="error-indicator">
      <img src="/dist/images/death-star.png" alt="error icon" />
      <span className="boom">BOOM!</span>
      {errorText ? (
        <>
          <span>something has gone terribly wrong</span>
          <span>(but we already sent droids to fix it)</span>
          <span>details: {errorText}</span>
        </>
      ) : (
        <>
          <span>something has gone terribly wrong</span>
          <span>(but we already sent droids to fix it)</span>
        </>
      )}
    </div>
  );
};

export default ErrorIndicator;
