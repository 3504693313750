import * as React from 'react';
import * as Yup from 'yup';
import * as propz from 'propz';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../../../../components/button';
import { Tag } from '../../../../../models/tag';
import { FieldProps } from 'formik/dist/Field';
import { CirclePicker } from 'react-color';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  color: Yup.string().required('Required'),
});

interface Props {
  onSubmit: (data: TagFormValues) => void;
  onCancel: () => void;
  tag?: Tag;
}

export interface TagFormValues {
  name: string;
  color: string;
}

export function TagForm(props: Props) {
  const { onCancel, onSubmit, tag } = props;

  const initialValues: TagFormValues = {
    name: propz.get(tag, ['name'], ''),
    color: propz.get(tag, ['color'], '#ff0000'),
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h3 className={'mb-3 text-center mt-3'}>Tag</h3>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit, values, setFieldValue }) => (
              <Form>
                <div className="form-group">
                  <label>Tag name</label>
                  <Field placeholder={'Tag name'} name="name" className="form-control mb-3" />
                  <ErrorMessage component="div" className="alert alert-danger" name="name" />
                </div>

                <div>
                  <div className={'d-inline-block me-3'}>Color</div>
                  <div className={'d-inline-block align-middle'}>
                    <div
                      className={'d-inline-block'}
                      style={{
                        width: '28px',
                        height: '28px',
                      }}
                    >
                      <div
                        style={{
                          border: '1px solid #000',
                          backgroundColor: `${values.color}`,
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                        }}
                      />
                    </div>
                  </div>
                  <Field name="color">
                    {({ field }: FieldProps) => (
                      <CirclePicker
                        className={'mt-3'}
                        width={'100%'}
                        onChangeComplete={(color) => {
                          setFieldValue('color', color.hex);
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage component="div" className="alert alert-danger" name="color" />
                </div>

                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3 btn-outline-primary'} />
                <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3'} type={'submit'} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
