import * as React from 'react';
import * as BPromise from 'bluebird';
import { compose, withAppUser, withServices } from '../../../../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import { InviteGuest } from '../../../../../models/invite-guest';
import { InviteCoHost } from '../../../../../models/invite-co-host';
import Loader from '../../../../../components/loader';
import ErrorIndicator from '../../../../../components/error-indicator';

interface Props {
  eventId: string;
}

interface State {
  inviteGuests: InviteGuest[];
  inviteCoHosts: InviteCoHost[];
  isLoading: boolean;
  isError: boolean;
}

class EventInvitations extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      inviteGuests: [],
      inviteCoHosts: [],
      isLoading: true,
      isError: false,
    };
  }

  componentDidMount() {
    const { getInviteGuests, getInviteCoHosts, eventId } = this.props;

    this.setState({
      isLoading: true,
    });

    BPromise.all([getInviteGuests(eventId), getInviteCoHosts(eventId)])
      .then(([inviteGuests, inviteCoHosts]) => {
        this.setState({
          isLoading: false,
          inviteGuests,
          inviteCoHosts,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  render() {
    const { inviteGuests, inviteCoHosts, isLoading, isError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const emptyRow = (
      <tr key={'event_invitations_and_guests_empty_row'}>
        <td colSpan={3}>No invitations</td>
      </tr>
    );

    const inviteGuestsRows = inviteGuests.map((inviteGuest, index) => {
      const { id, email, status } = inviteGuest;
      return (
        <tr key={id}>
          <td>{index + 1}</td>
          <td>{email}</td>
          <td>{status}</td>
        </tr>
      );
    });

    const inviteCoHostsRows = inviteCoHosts.map((inviteCoHost, index) => {
      const { id, email, status } = inviteCoHost;
      return (
        <tr key={id}>
          <td>{index + 1}</td>
          <td>{email}</td>
          <td>{status}</td>
        </tr>
      );
    });

    return (
      <div className={'mt-3 mb-3'}>
        <h1>Guests</h1>

        <table className={'table table-sm mPre'}>
          <thead>
            <tr>
              <th />
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{inviteGuestsRows.length > 0 ? inviteGuestsRows : emptyRow}</tbody>
        </table>

        <h1>Co Hosts</h1>
        <table className={'table table-sm mPre'}>
          <thead>
            <tr>
              <th />
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{inviteCoHostsRows.length > 0 ? inviteCoHostsRows : emptyRow}</tbody>
        </table>
      </div>
    );
  }
}

const EventInvitationsPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(EventInvitations);

export default EventInvitationsPage;
