import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { DEFAULT_ICON_PREFIX } from '../../consts/common';

export interface SocialButtonProps {
  iconName: IconName;
  iconPrefix: IconPrefix;
  title: string;
  href: string;
}

export default function SocialButton(props: SocialButtonProps) {
  const { iconName, iconPrefix = DEFAULT_ICON_PREFIX, title, href } = props;
  return (
    <a href={href} className={'btn btn-light me-3'}>
      <FontAwesomeIcon icon={[iconPrefix, iconName]} />
      <span style={{ marginLeft: '5px' }}>{title}</span>
    </a>
  );
}
