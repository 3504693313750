import * as React from 'react';

interface Props {
  message: any;
}

export function DialogMessage(props: Props) {
  const { message } = props;
  return (
    <div className={'eMessage mt-3'}>
      <div className={'d-inline-block'}>
        <img className={'eAvatar'} src={message.avatar} alt={'avatar'} />
      </div>
      <div className={'d-inline-block mPreWrap eMessageText'}>{message.text}</div>
    </div>
  );
}
