import axios from 'axios';
import { AppUser } from '../../views/App/App';
import { EventType } from '../../models/event-type';
import { Event } from '../../models/event';
import { ToDoList, ToDoListTask } from '../../models/to-do-list';
import { Template } from '../../models/template';
import { InviteGuest } from '../../models/invite-guest';
import { InviteCoHost } from '../../models/invite-co-host';
import { Tag } from '../../models/tag';
import { TagFormValues } from '../../views/GenericView/UserView/Tags/TagForm/TagForm';
import { Contact } from '../../models/contact';
import { ContactFormValues } from '../../views/GenericView/UserView/Contacts/ContactForm/ContactForm';
import { Profile } from '../../models/profile';
import { InviteGuestFormValues } from '../../views/GenericView/UserView/Events/InviteGuestForm/InviteGuestForm';
import { DEFAULT_LIMIT } from '../../consts/common';

export default class UserService {
  api: string;
  user: AppUser;
  private readonly PREFIX = 'user';

  constructor(user: AppUser, api: string) {
    this.api = `${api}/${this.PREFIX}`;
    this.user = user;
  }

  logout = (): Promise<any> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.delete<any>(`${this.api}/sessions/${sessionKey}`, config).then((response) => response.data);
  };

  getEventTypes = (): Promise<EventType[]> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.get<EventType[]>(`${this.api}/eventTypes`, config).then((response) => response.data);
  };

  getTemplates = (): Promise<Template[]> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.get<Template[]>(`${this.api}/templates`, config).then((response) => response.data);
  };

  getEvents = (filter?: any): Promise<Event[]> => {
    const { sessionKey } = this.user;
    const config =
      typeof filter !== 'undefined'
        ? {
          headers: {
            usid: sessionKey,
          },
          params: {
            filter: {
              ...filter,
              limit: filter.limit || DEFAULT_LIMIT,
            },
          },
        }
        : {
          headers: {
            usid: sessionKey,
          },
          params: {
            filter: {
              limit: DEFAULT_LIMIT,
            },
          },
        };

    return axios.get<Event[]>(`${this.api}/events`, config).then((response) => response.data);
  };

  getEvent = (eventId: string): Promise<Event> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.get<Event>(`${this.api}/events/${eventId}`, config).then((response) => response.data);
  };

  getUpcomingEvents = (filter?: any): Promise<Event[]> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
      params: {
        filter,
      },
    };
    return axios.get<Event[]>(`${this.api}/upcomingEvents`, config).then((response) => response.data);
  };

  getPastEvents = (filter?: any): Promise<Event[]> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
      params: {
        filter,
      },
    };
    return axios.get<Event[]>(`${this.api}/pastEvents`, config).then((response) => response.data);
  };

  createEvent = (data: any): Promise<Event> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.post<Event>(`${this.api}/events`, data, config).then((response) => response.data);
  };

  inviteCoHost = (eventId: string, data: { email: string }): Promise<any> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .post<any>(`${this.api}/events/${eventId}/inviteCoHosts`, data, config)
      .then((response) => response.data);
  };

  inviteGuest = (eventId: string, data: InviteGuestFormValues): Promise<any> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .post<any>(`${this.api}/events/${eventId}/inviteGuests`, data, config)
      .then((response) => response.data);
  };

  getInviteGuests = (eventId: string, filter: any): Promise<InviteGuest[]> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
      params: {
        filter,
      },
    };
    return axios
      .get<InviteGuest[]>(`${this.api}/events/${eventId}/inviteGuests`, config)
      .then((response) => response.data);
  };

  getInviteCoHosts = (eventId: string): Promise<InviteCoHost[]> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .get<InviteCoHost[]>(`${this.api}/events/${eventId}/inviteCoHosts`, config)
      .then((response) => response.data);
  };

  getEventToDoLists = (eventId: string, filter?: any): Promise<ToDoList[]> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
      params: {
        filter,
      },
    };
    return axios.get<ToDoList[]>(`${this.api}/events/${eventId}/toDoLists`, config).then((response) => response.data);
  };

  getTags = (filter?: any): Promise<Tag[]> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
      params: {
        filter,
      },
    };
    return axios.get<Tag[]>(`${this.api}/tags`, config).then((response) => response.data);
  };

  createTag = (data: TagFormValues): Promise<Tag> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.post<Tag>(`${this.api}/tags`, data, config).then((response) => response.data);
  };

  updateTag = (tagId: string, data: TagFormValues): Promise<Tag> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.put<Tag>(`${this.api}/tags/${tagId}`, data, config).then((response) => response.data);
  };

  removeTag = (tagId: string): Promise<any> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.delete<any>(`${this.api}/tags/${tagId}`, config).then((response) => response.data);
  };

  createEventToDoList = (eventId: string, data: any): Promise<ToDoList> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .post<ToDoList>(`${this.api}/events/${eventId}/toDoLists`, data, config)
      .then((response) => response.data);
  };

  updateEventToDoList = (eventId: string, toDoListId: string, data: any): Promise<ToDoList> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .put<ToDoList>(`${this.api}/events/${eventId}/toDoLists/${toDoListId}`, data, config)
      .then((response) => response.data);
  };

  removeEventToDoList = (eventId: string, toDoListId: string): Promise<any> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .delete<any>(`${this.api}/events/${eventId}/toDoLists/${toDoListId}`, config)
      .then((response) => response.data);
  };

  createEventToDoListTask = (eventId: string, toDoListId: string, data: any): Promise<ToDoListTask> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .post<ToDoListTask>(`${this.api}/events/${eventId}/toDoLists/${toDoListId}/tasks`, data, config)
      .then((response) => response.data);
  };

  updateEventToDoListTask = (eventId: string, toDoListId: string, taskId: string, data: any): Promise<ToDoListTask> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .put<ToDoListTask>(`${this.api}/events/${eventId}/toDoLists/${toDoListId}/tasks/${taskId}`, data, config)
      .then((response) => response.data);
  };

  removeEventToDoListTask = (eventId: string, toDoListId: string, taskId: string): Promise<any> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .delete<any>(`${this.api}/events/${eventId}/toDoLists/${toDoListId}/tasks/${taskId}`, config)
      .then((response) => response.data);
  };

  changeEventToDoListTaskStatus = (
    eventId: string,
    toDoListId: string,
    taskId: string,
    status: string
  ): Promise<ToDoListTask> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };

    const data = { status };

    return axios
      .post<ToDoListTask>(`${this.api}/events/${eventId}/toDoLists/${toDoListId}/tasks/${taskId}/status`, data, config)
      .then((response) => response.data);
  };

  getContacts = (filter?: any): Promise<Contact[]> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
      params: {
        filter,
      },
    };
    return axios.get<Contact[]>(`${this.api}/contacts`, config).then((response) => response.data);
  };

  createContact = (data: ContactFormValues): Promise<Contact> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.post<Contact>(`${this.api}/contacts`, data, config).then((response) => response.data);
  };

  updateContact = (contactId: string, data: ContactFormValues): Promise<Contact> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.put<Contact>(`${this.api}/contacts/${contactId}`, data, config).then((response) => response.data);
  };

  removeContact = (contactId: string): Promise<any> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.delete<any>(`${this.api}/contacts/${contactId}`, config).then((response) => response.data);
  };

  checkContactEmail = (data: { email: string }): Promise<{ isAvailable: boolean }> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .post<{ isAvailable: boolean }>(`${this.api}/contacts/check`, data, config)
      .then((response) => response.data);
  };

  getProfile = (): Promise<Profile> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.get<Profile>(`${this.api}/profile`, config).then((response) => response.data);
  };

  updateProfile = (data: any): Promise<Profile> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios.put<Profile>(`${this.api}/profile`, data, config).then((response) => response.data);
  };

  updatePassword = (newPassword: string, oldPassword: string): Promise<Profile> => {
    const { sessionKey } = this.user;
    const config = {
      headers: {
        usid: sessionKey,
      },
    };
    return axios
      .put<Profile>(`${this.api}/profile/password`, { newPassword, oldPassword }, config)
      .then((response) => response.data);
  };
}
