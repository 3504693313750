import * as React from 'react';
import classNames from 'classnames';
import './switchField.scss';

interface Props {
  value: boolean;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  text: string;
  customClass?: string;
  name: string;
  disabled?: boolean;
}

export function SwitchField(props: Props) {
  const { customClass, onChange, text, value, name, disabled } = props;

  const switchClass = classNames('form-check', 'form-switch', 'form-switch-wrapper', customClass);

  return (
    <div className={switchClass}>
      <label className="form-check-label" htmlFor={name}>
        <input
          name={name}
          onChange={onChange}
          checked={value}
          type="checkbox"
          className="form-check-input form-switch-input"
          id={name}
          disabled={disabled}
        />
        {text}
      </label>
    </div>
  );
}
