export const INVITE_STATUS = {
  NEW: 'NEW',
  REJECT: 'REJECT',
  ACCEPT: 'ACCEPT',
  MAYBE: 'MAYBE',
};

export const INVITE_STATUS_SERVER_TO_CLIENT_MAPPING: { [key: string]: string } = {
  NEW: 'Pending',
  REJECT: 'Rejected',
  ACCEPT: 'Accepted',
  MAYBE: 'Maybe',
};
