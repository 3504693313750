import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/button';
import { compose, withAppUser, withServices } from '../../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props {
  onSubmit: (data: ResetPasswordFormValues) => void;
}

export interface ResetPasswordFormValues {
  email: string;
}

function ResetPasswordForm(props: Props & RouteComponentProps & MapMethodsToProps) {
  const { onSubmit, checkAvailability } = props;

  const resetPasswordForm: ResetPasswordFormValues = {
    email: '',
  };

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required('Required')
      .email('Must be valid email')
      .test('email', 'Email not found', (value) => {
        if (!value) {
          return false;
        } else {
          return checkAvailability({ email: value }).then((res) => {
            return !res.isAvailable;
          });
        }
      }),
  });

  return (
    <Formik
      initialValues={resetPasswordForm}
      validationSchema={ResetPasswordSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ touched, handleSubmit }) => (
        <Form>
          <div className={'mt-3 mb-3 text-center'}>Please enter the email address you used to create the account.</div>
          <div className="form-group">
            <Field placeholder={'Email'} name="email" className="form-control" />
            <ErrorMessage component="div" className="alert alert-danger mt-3" name="email" />
          </div>
          <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 btn-lg btn-block'} type={'submit'} />
        </Form>
      )}
    </Formik>
  );
}

const ResetPasswordFormPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(ResetPasswordForm);

export default ResetPasswordFormPage;
