import * as React from 'react';
import { Component } from 'react';
import './ResetPassword.scss';
import SimpleModal from '../../components/simple-modal';
import { compose, withAppUser, withServices } from '../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import ResetPasswordFormPage from './ResetPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';
import { ResetPasswordFormValues } from './ResetPasswordForm/ResetPasswordForm';
import Loader from '../../components/loader';
import ErrorIndicator from '../../components/error-indicator';

interface Props {}

interface State {
  secretKey: string;
  isResetPasswordModalOpen: boolean;
  isChangePasswordModalOpen: boolean;
  isLoading: boolean;
  isError: boolean;
}

class ResetPassword extends Component<Props & RouteComponentProps & MapMethodsToProps, State> {
  constructor(props: Props & RouteComponentProps & MapMethodsToProps) {
    super(props);

    this.state = {
      secretKey: undefined,
      isResetPasswordModalOpen: false,
      isChangePasswordModalOpen: false,
      isLoading: false,
      isError: false,
    };
  }
  componentDidMount(): void {
    const { match } = this.props;
    const { params } = match as any;
    const { secretKey } = params;

    this.setState({
      secretKey,
    });
  }

  redirectToLoginPage = (): void => {
    const { history } = this.props;
    history.push('/login');
  };

  onChangePasswordFormSubmit = (password: string): void => {
    const { secretKey } = this.state;
    const { resetPassword } = this.props;

    this.setState({
      isLoading: true,
    });

    resetPassword(password, secretKey)
      .then((res) => {
        this.setState({
          isLoading: false,
          isChangePasswordModalOpen: true,
        });
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onResetPasswordFormSubmit = (data: ResetPasswordFormValues): void => {
    const { resetRequestPassword } = this.props;

    this.setState({
      isLoading: true,
    });

    resetRequestPassword(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          isResetPasswordModalOpen: true,
        });
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  renderResetPasswordModal(): React.ReactNode {
    const { isResetPasswordModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isResetPasswordModalOpen}
        title={'Info'}
        body={'Please check your email inbox, and click the link in the email \n you received to reset your password.'}
        positiveText={'Ok'}
        onPositiveButtonClick={this.redirectToLoginPage}
      />
    );
  }

  renderChangePasswordModal(): React.ReactNode {
    const { isChangePasswordModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isChangePasswordModalOpen}
        title={'Info'}
        body={'Password changed'}
        positiveText={'Ok'}
        onPositiveButtonClick={this.redirectToLoginPage}
      />
    );
  }

  render() {
    const { secretKey, isLoading, isError } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    return (
      <div>
        {this.renderResetPasswordModal()}
        {this.renderChangePasswordModal()}
        <div className="container">
          <div className="row">
            <div className="offset-xl-3 col-xl-6">
              <div className={'eResetPasswordWrapper'}>
                <div className="eResetPasswordFormWrapper">
                  <div className="mt-3 mb-3">
                    {secretKey ? (
                      <ChangePasswordForm onSubmit={this.onChangePasswordFormSubmit} />
                    ) : (
                      <ResetPasswordFormPage onSubmit={this.onResetPasswordFormSubmit} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ResetPasswordPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(ResetPassword);

export default ResetPasswordPage;
