import { AppUser } from '../views/App/App';
import { isAdmin, isUser } from './user';
import * as propz from 'propz';
import { NOT_VERIFIED_USER_ROUTES } from './routing';

const USER_SIDEBAR = [
  { title: 'My events', to: '/events/my' },
  { title: 'Upcoming events', to: '/events/upcoming' },
  { title: 'Past events', to: '/events/past' },
  { title: 'My contacts', to: '/contacts' },
  { title: 'My tags', to: '/tags' },
  { title: 'My tasks', to: '/tasks' },
  { title: 'Profile', to: '/profile' },
  { title: 'Sign out', to: '/signOut' },
];

const ADMIN_SIDEBAR = [
  { title: 'Users', to: '/users' },
  { title: 'Sign out', to: '/signOut' },
];

const NOT_VERIFIED_USER_SIDEBAR = [
  { title: 'Profile', to: '/profile' },
  { title: 'Sign out', to: '/signOut' },
];

export interface SidebarItem {
  title: string;
  to: string;
}

export function getSidebarItems(user: AppUser): SidebarItem[] {
  let sidebarItems;

  const isEmailVerified = propz.get<boolean>(user, ['profile', 'verification', 'status', 'email'], false);

  switch (true) {
    case isUser(user) && !isEmailVerified:
      sidebarItems = NOT_VERIFIED_USER_SIDEBAR;
      break;
    case isUser(user) && isEmailVerified:
      sidebarItems = USER_SIDEBAR;
      break;
    case isAdmin(user):
      sidebarItems = ADMIN_SIDEBAR;
      break;
  }

  return sidebarItems;
}
