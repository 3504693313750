import * as React from 'react';
import { compose, withAppUser, withServices } from '../../../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppUser } from '../../../App/App';
import { TABS, USER_PROFILE_TABS } from '../../../../consts/tabs';
import Tabs from '../../../../components/tabs';
import Loader from '../../../../components/loader';
import ErrorIndicator from '../../../../components/error-indicator';
import SimpleModal from '../../../../components/simple-modal';
import ProfileSummaryFormPage from './ProfileSummaryForm/ProfileSummaryForm';
import ProfileChangePasswordForm from './ProfileChangePasswordForm';
import ProfileSummary from './ProfileSummary';
import './Profile.scss';
import { Profile } from '../../../../models/profile';
import * as propz from 'propz';

interface Props {
  user: AppUser;
  setProfile: (profile: Profile) => void;
}
interface State {
  activeTabIndex: number;
  isLoading: boolean;
  isError: boolean;
  isSuccessModalOpen: boolean;
  errorText: string;
  isEditSummary: boolean;
}

class UserProfile extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      activeTabIndex: 0,
      isLoading: false,
      isError: false,
      isSuccessModalOpen: false,
      isEditSummary: false,
      errorText: '',
    };
  }

  onTabClick = (index: number) => {
    const { isEditSummary } = this.state;
    if (!isEditSummary) {
      this.setState({
        activeTabIndex: index,
      });
    }
  };

  onEditSummaryClick = () => {
    this.setState({
      isEditSummary: true,
    });
  };

  onSubmitSummaryClick = (data: any) => {
    const { updateProfile, setProfile } = this.props;

    this.setState({
      isLoading: true,
    });

    updateProfile(data)
      .then((profile) => {
        setProfile(profile);

        this.setState({
          isLoading: false,
          isEditSummary: false,
          isSuccessModalOpen: true,
        });
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
          errorText: propz.get(err, ['response', 'data', 'details', 'text'], ''),
        });
      });
  };

  onCancelSummaryClick = () => {
    this.setState({
      isEditSummary: false,
    });
  };

  onSubmitChangePasswordClick = (newPassword: string, oldPassword: string) => {
    const { updatePassword, setProfile } = this.props;

    this.setState({
      isLoading: true,
    });

    updatePassword(newPassword, oldPassword)
      .then((profile) => {
        setProfile(profile);

        this.setState({
          isLoading: false,
          isSuccessModalOpen: true,
        });
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
          errorText: propz.get(err, ['response', 'data', 'details', 'text'], ''),
        });
      });
  };

  getContent(): React.ReactNode {
    const { activeTabIndex, isEditSummary } = this.state;
    const { user } = this.props;
    const { profile } = user;
    const activeTab = USER_PROFILE_TABS[activeTabIndex];

    switch (true) {
      case activeTab === TABS.SUMMARY && isEditSummary:
        return (
          <ProfileSummaryFormPage
            profile={profile}
            onSubmit={this.onSubmitSummaryClick}
            onCancel={this.onCancelSummaryClick}
          />
        );

      case activeTab === TABS.SUMMARY && !isEditSummary:
        return <ProfileSummary profile={profile} onEditClick={this.onEditSummaryClick} />;
      case activeTab === TABS.CHANGE_PASSWORD:
        return <ProfileChangePasswordForm onSubmit={this.onSubmitChangePasswordClick} />;
    }
  }

  closeSuccessModal = () => {
    this.setState({
      isSuccessModalOpen: false,
    });
  };

  renderSuccessModal() {
    const { isSuccessModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isSuccessModalOpen}
        title={'Info'}
        body={'Success!'}
        negativeText={'Ok'}
        onNegativeButtonClick={this.closeSuccessModal}
      />
    );
  }

  render() {
    const { activeTabIndex, isLoading, isError, errorText } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator errorText={errorText} />;
    }

    const content = this.getContent();

    return (
      <div>
        {this.renderSuccessModal()}
        <Tabs tabs={USER_PROFILE_TABS} activeTabIndex={activeTabIndex} onTabClick={this.onTabClick}>
          {content}
        </Tabs>
      </div>
    );
  }
}

const ProfilePage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(UserProfile);

export default ProfilePage;
