import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../../components/button';
import './CreateEventModal.scss';

interface Props {
   onClose: () => void;
   onCreateEventClick: () => void;
}

export function CreateEventModal(props: Props) {
   const { onClose, onCreateEventClick } = props;

   return (
      <div className='create-event-modal px-4'>
         <h3>New event</h3>
         <p className='mb-4'>How would you like to create this event?</p>
         <div className='d-flex'>
            <div className='me-3'>
               <Button onClick={onCreateEventClick} text={'Create'} />
            </div>
            <div>
               <Button onClick={() => { }} text={'Create with bot'} />
            </div>
         </div>

         <div className='mt-4'>
            <Button onClick={onClose} text={'Cancel'} />
         </div>
      </div>
   );
};
