import * as React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Button from '../../../../../../components/button';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

interface Props {
  onSubmit: (data: EventToDoListFormValues) => void;
}

export interface EventToDoListFormValues {
  name: string;
}

export function CreateEventToDoListForm(props: Props) {
  const { onSubmit } = props;

  const initialValues: EventToDoListFormValues = {
    name: '',
  };

  return (
    <div>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, values }) => (
          <Form>
            <div className="row">
              <div className="col-auto">
                <Field placeholder={'To do list name'} name="name" className="form-control" />
              </div>
              <div className="col-auto">
                <Button
                  disabled={values.name.length === 0}
                  onClick={handleSubmit}
                  text={'Create To Do List'}
                  customClass={''}
                  type={'submit'}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
