import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../../../../../components/button';
import { EventToDoListFormValues } from './CreateEventToDoListForm';
import { ToDoList } from '../../../../../../models/to-do-list';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

interface Props {
  onSubmit: (data: EventToDoListFormValues) => void;
  todoList: ToDoList;
  onCancel: () => void;
}

export function EditEventToDoListForm(props: Props) {
  const { onSubmit, todoList, onCancel } = props;

  const initialValues: EventToDoListFormValues = {
    name: todoList.name,
  };

  return (
    <div className={'mt-3 mb-3'}>
      <h3 className={'mb-3 text-center mt-3'}>Edit To Do</h3>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Form>
            <div className="form-group">
              <Field placeholder={'To do list name'} name="name" className="form-control mb-3" />
              <ErrorMessage component="div" className="alert alert-danger" name="name" />
            </div>
            <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3 btn-secondary'} />
            <Button onClick={handleSubmit} text={'Edit To Do List'} customClass={'mt-3 mb-3'} type={'submit'} />
          </Form>
        )}
      </Formik>
    </div>
  );
}
