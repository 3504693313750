import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FieldArray } from 'formik';
import { Button } from '../../../../../components/button/button';
import { Template } from '../../../../../models/template';
import { FieldProps } from 'formik/dist/Field';
import SwitchField from '../../../../../components/switch-field';
import Autocomplete from '../../../../../components/autocomplete';
import { getFullName } from '../../../../../helpers/autocomplete';
import { Contact } from '../../../../../models/contact';
import { compose, withAppUser, withServices } from '../../../../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  templates: Template[];
}

interface State {
  emails: string[];
}

export interface InviteGuestFormValues {
  guests: InviteGuest[];
  templateName: string;
  isPlusOneGuest: boolean;
  isMultipleGuests: boolean;
  isPlusOneChild: boolean;
  isMultipleChildren: boolean;
}

interface InviteGuest {
  email: string;
}

const validationSchema = Yup.object().shape({
  guests: Yup.array()
    .min(1, 'Should contain minimum 1 guest email')
    .of(
      Yup.object().shape({
        email: Yup.string().required('Required').email('Must be valid email'),
      })
    ),
  templateName: Yup.string().required('Required'),
});

export class InviteGuestForm extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      emails: [],
    };
  }

  getContactsFunc = (text: string): Promise<Contact[]> => {
    const { emails } = this.state;
    const { getContacts } = this.props;

    const filter = {
      where: {
        $or: [
          {
            firstName: {
              like: text || '',
              options: 'i',
            },
          },
          {
            lastName: {
              like: text || '',
              options: 'i',
            },
          },
          {
            'tags.tagName': {
              like: text || '',
              options: 'i',
            },
          },
          {
            email: {
              like: text || '',
              options: 'i',
            },
          },
        ],

        email: {
          $nin: emails,
        },
      },
      limit: 10,
      skip: 0,
    };

    return getContacts(filter);
  };

  render() {
    const { onSubmit, onCancel, templates } = this.props;

    const initialValues: InviteGuestFormValues = {
      guests: [
        {
          email: '',
        },
      ],
      templateName: '',
      isPlusOneGuest: false,
      isMultipleGuests: false,
      isPlusOneChild: false,
      isMultipleChildren: false,
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3 className={'mb-3 text-center mt-3'}>Invite Guest</h3>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ handleSubmit, values, setFieldValue }) => (
                <Form>
                  <div className="form-group">
                    <label>Template</label>
                    <Field
                      name="templateName"
                      component="select"
                      placeholder="Select your event type"
                      className="form-control mb-3"
                    >
                      <option value={''}>Select your template</option>
                      {templates.map((template) => (
                        <option value={template.name} key={template.name}>
                          {template.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="templateName" />
                  </div>

                  <label>Add guest from contacts (name, surname, email, tags)</label>
                  <Field name="guest">
                    {({}) => {
                      return (
                        <Autocomplete
                          searchFunction={(text: string) => this.getContactsFunc(text)}
                          getElementTitle={getFullName}
                          customClass="mFullWidth mb-3"
                          defaultItem={undefined}
                          onSelect={(contact: Contact) => {
                            setFieldValue('guests', [{ email: contact.email }, ...values.guests]);
                            this.setState((prevState) => {
                              return {
                                emails: [...prevState.emails, contact.email],
                              };
                            });
                          }}
                        />
                      );
                    }}
                  </Field>

                  <FieldArray name="guests">
                    {({ remove, push }) => (
                      <div>
                        Invite send to:
                        <div
                          style={{ border: '1px solid green', borderRadius: '5px' }}
                          className={'mb-3 pt-3 pe-3 ps-3'}
                        >
                          {values.guests.length > 0 &&
                            values.guests.map((guest: InviteGuest, index: number) => (
                              <div className="row align-items-center" key={index}>
                                <div className="col-10">
                                  <Field
                                    name={`guests.${index}.email`}
                                    className="form-control mb-3"
                                    placeholder="Enter email here"
                                    type="email"
                                  />
                                </div>
                                <div className="col-2">
                                  <button type="button" className="mb-3 btn-close" onClick={() => remove(index)} />
                                </div>
                                <div className="col-10">
                                  <ErrorMessage
                                    name={`guests.${index}.email`}
                                    component="div"
                                    className="alert alert-danger"
                                  />
                                </div>
                                <div className={'col-2'} />
                              </div>
                            ))}
                        </div>
                        <button type="button" className="btn btn-secondary mb-3" onClick={() => push({ email: '' })}>
                          Add Guest
                        </button>
                      </div>
                    )}
                  </FieldArray>

                  <h3 className={'text-center'}>Additional questions</h3>

                  <div className="form-group">
                    <Field name="isPlusOneGuest" className={'mb-3'}>
                      {({ field }: FieldProps) => (
                        <SwitchField
                          {...field}
                          onChange={() => {
                            if (!values.isPlusOneGuest) {
                              setFieldValue('isPlusOneGuest', true);
                            } else {
                              setFieldValue('isPlusOneGuest', false);
                              setFieldValue('isMultipleGuests', false);
                            }
                          }}
                          text="+1"
                          customClass="mb-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Field name="isMultipleGuests" className={'mb-3'}>
                      {({ field }: FieldProps) => (
                        <SwitchField
                          {...field}
                          disabled={!values.isPlusOneGuest}
                          onChange={() => {
                            setFieldValue('isMultipleGuests', !values.isMultipleGuests);
                          }}
                          text="Multiple guests"
                          customClass="mb-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Field name="isPlusOneChild" className={'mb-3'}>
                      {({ field }: FieldProps) => (
                        <SwitchField
                          {...field}
                          onChange={() => {
                            if (!values.isPlusOneChild) {
                              setFieldValue('isPlusOneChild', true);
                            } else {
                              setFieldValue('isPlusOneChild', false);
                              setFieldValue('isMultipleChildren', false);
                            }
                          }}
                          text="+1 Child"
                          customClass="mb-3"
                        />
                      )}
                    </Field>
                  </div>

                  <div className="form-group">
                    <Field name="isMultipleChildren" className={'mb-3'}>
                      {({ field }: FieldProps) => (
                        <SwitchField
                          {...field}
                          disabled={!values.isPlusOneChild}
                          onChange={() => {
                            setFieldValue('isMultipleChildren', !values.isMultipleChildren);
                          }}
                          text="Multiple children"
                          customClass="mb-3"
                        />
                      )}
                    </Field>
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3 btn-secondary'} />
                  <Button onClick={handleSubmit} text={'Invite Guest'} customClass={'mt-3 mb-3'} type={'submit'} />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const InviteGuestFormPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(InviteGuestForm);

export default InviteGuestFormPage;
