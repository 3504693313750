import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FieldArray } from 'formik';
import { Button } from '../../../../../components/button/button';
import { Template } from '../../../../../models/template';
import { Event } from '../../../../../models/event';
import { FieldProps } from 'formik/dist/Field';
import SwitchField from '../../../../../components/switch-field';
import { AppUser } from '../../../../App/App';
import * as propz from 'propz';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  templates: Template[];
  events: Event[];
  emails: string[];
  user: AppUser;
}

export interface ContactsInviteGuestFormValues {
  guests: InviteGuest[];
  templateName: string;
  isPlusOneGuest: boolean;
  isMultipleGuests: boolean;
  isPlusOneChild: boolean;
  isMultipleChildren: boolean;
  eventId: string;
}

interface InviteGuest {
  email: string;
}

export function ContactsInviteGuestForm(props: Props) {
  const { onSubmit, onCancel, templates, emails, events, user } = props;

  const userEmail = propz.get(user, ['profile', 'email'], '');

  const validationSchema = Yup.object().shape({
    guests: Yup.array()
      .min(1, 'Should contain minimum 1 guest email')
      .of(
        Yup.object().shape({
          email: Yup.string().required('Required').email('Must be valid email')
            .test('email', 'You cannot send an invitation to yourself', (value: string) => {
              return userEmail !== value;
            })
        })
      ),
    templateName: Yup.string().required('Required'),
    eventId: Yup.string().required('Required'),
  });

  const initialValues: ContactsInviteGuestFormValues = {
    guests: emails.map((email) => ({ email })),
    templateName: '',
    isPlusOneGuest: false,
    isMultipleGuests: false,
    isPlusOneChild: false,
    isMultipleChildren: false,
    eventId: '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h3 className={'mb-3 text-center mt-3'}>Invite Guest</h3>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit, values, setFieldValue }) => (
              <Form>
                <div className="form-group">
                  <label>Event</label>
                  <Field
                    name="eventId"
                    component="select"
                    placeholder="Select your event"
                    className="form-control mb-3"
                  >
                    <option value={''}>Select your event</option>
                    {events.map((event) => (
                      <option value={event.id} key={event.id}>
                        {event.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component="div" className="alert alert-danger" name="eventId" />
                </div>

                <div className="form-group">
                  <label>Template</label>
                  <Field
                    name="templateName"
                    component="select"
                    placeholder="Select your event type"
                    className="form-control mb-3"
                  >
                    <option value={''}>Select your template</option>
                    {templates.map((template) => (
                      <option value={template.name} key={template.name}>
                        {template.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component="div" className="alert alert-danger" name="templateName" />
                </div>

                <FieldArray name="guests">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.guests.length > 0 &&
                        values.guests.map((guest: InviteGuest, index: number) => (
                          <div className="row align-items-center" key={index}>
                            <div className="col-10">
                              <Field
                                name={`guests.${index}.email`}
                                className="form-control mb-3"
                                placeholder="Enter email here"
                                type="email"
                              />
                            </div>
                            <div className="col-2">
                              <button type="button" className="mb-3 btn-close" onClick={() => remove(index)} />
                            </div>
                            <div className="col-10">
                              <ErrorMessage
                                name={`guests.${index}.email`}
                                component="div"
                                className="alert alert-danger"
                              />
                            </div>
                            <div className={'col-2'} />
                          </div>
                        ))}
                    </div>
                  )}
                </FieldArray>

                <h3 className={'text-center'}>Additional questions</h3>

                <div className="form-group">
                  <Field name="isPlusOneGuest" className={'mb-3'}>
                    {({ field }: FieldProps) => (
                      <SwitchField
                        {...field}
                        onChange={() => {
                          if (!values.isPlusOneGuest) {
                            setFieldValue('isPlusOneGuest', true);
                          } else {
                            setFieldValue('isPlusOneGuest', false);
                            setFieldValue('isMultipleGuests', false);
                          }
                        }}
                        text="+1"
                        customClass="mb-3"
                      />
                    )}
                  </Field>
                </div>

                <div className="form-group">
                  <Field name="isMultipleGuests" className={'mb-3'}>
                    {({ field }: FieldProps) => (
                      <SwitchField
                        {...field}
                        disabled={!values.isPlusOneGuest}
                        onChange={() => {
                          setFieldValue('isMultipleGuests', !values.isMultipleGuests);
                        }}
                        text="Multiple guests"
                        customClass="mb-3"
                      />
                    )}
                  </Field>
                </div>

                <div className="form-group">
                  <Field name="isPlusOneChild" className={'mb-3'}>
                    {({ field }: FieldProps) => (
                      <SwitchField
                        {...field}
                        onChange={() => {
                          if (!values.isPlusOneChild) {
                            setFieldValue('isPlusOneChild', true);
                          } else {
                            setFieldValue('isPlusOneChild', false);
                            setFieldValue('isMultipleChildren', false);
                          }
                        }}
                        text="+1 Child"
                        customClass="mb-3"
                      />
                    )}
                  </Field>
                </div>

                <div className="form-group">
                  <Field name="isMultipleChildren" className={'mb-3'}>
                    {({ field }: FieldProps) => (
                      <SwitchField
                        {...field}
                        disabled={!values.isPlusOneChild}
                        onChange={() => {
                          setFieldValue('isMultipleChildren', !values.isMultipleChildren);
                        }}
                        text="Multiple children"
                        customClass="mb-3"
                      />
                    )}
                  </Field>
                </div>

                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3'} />
                <Button onClick={handleSubmit} text={'Invite Guest'} customClass={'mt-3 mb-3'} type={'submit'} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
