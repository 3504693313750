import * as React from 'react';
import Button from '../../../../../components/button';
import { NavLink } from 'react-router-dom';

interface Props {
   onClose: () => void;
   onManageEventClick: () => void;
}

const EventCreatedModal = (props: Props) => {
   const { onClose, onManageEventClick } = props;
   return (
      <div className='event-created-modal'>
         <h3>Your event is created!</h3>
         <div className='d-flex justify-content-between'>
            <Button onClick={onClose} text={'Create more'} customClass={'mt-3 mb-3'} />
            <Button onClick={onManageEventClick} text={'Manage event'} customClass={'mt-3 mb-3'} />
         </div>

      </div>
   )
}

export default EventCreatedModal;
