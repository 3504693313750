import * as React from 'react';
import Logo from './../../../components/logo';
import UserAvatar from '../../../components/user-avatar';
import './Header.scss';

interface Props {
   userAvatar: string;
   fullName: string;
}

const Header = (props: Props) => {
   const { userAvatar, fullName } = props;
   return (
      <header className='px-4'>
         <Logo />
         <UserAvatar userAvatar={userAvatar} fullName={fullName} />
      </header>
   )
}

export default Header;
