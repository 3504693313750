import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { match, RouteComponentProps, withRouter } from 'react-router';
import { compose, withAppUser, withServices } from '../../components/hoc-helpers';
import { History } from 'history';
import Loader from '../../components/loader';
import ErrorIndicator from '../../components/error-indicator';
import { AppUser } from '../App/App';
import { MapMethodsToProps, mapMethodsToProps } from '../../helpers/component';
import { Link } from 'react-router-dom';

interface Props {
  match: match;
  history: History;
  user: AppUser;
  action: 'REJECT' | 'MAYBE';
}
interface State {
  isLoading: boolean;
  isError: boolean;
  errorText: string;
}

class InviteGuest extends Component<Props & RouteComponentProps & MapMethodsToProps, State> {
  constructor(props: Props & RouteComponentProps & MapMethodsToProps) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
      errorText: '',
    };
  }

  componentDidMount() {
    const { rejectInviteGuest, maybeInviteGuest, match, action } = this.props;

    const { params } = match as any;
    const { id } = params;

    let actionPromise;

    switch (action) {
      case 'REJECT':
        actionPromise = rejectInviteGuest;
        break;
      case 'MAYBE':
        actionPromise = maybeInviteGuest;
        break;
    }

    this.setState({
      isLoading: true,
    });

    actionPromise(id)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
          errorText: propz.get(err, ['response', 'data', 'details', 'text']),
        });
      });
  }

  render() {
    const { isLoading, isError, errorText } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator errorText={errorText} />;
    }

    return (
      <div className={'container'}>
        <div className="row">
          <div className="col-md-12">
            <div className="mt-3 text-center">
              <p>Thank you for your response, it will be sent to the event host.</p>
              <p>You can change your response by selecting the relevant link in the invitation email.</p>
              <p>
                To be able to manage your own events <Link to={{ pathname: '/registration' }}>sign up</Link> for your
                EventBerries account.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const InviteGuestPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(InviteGuest);

export default InviteGuestPage;
