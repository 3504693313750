import 'babel-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundry from './components/error-boundry';
import App from './views/App';

ReactDOM.render(
  <ErrorBoundry>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundry>,
  document.getElementById('app')
);
