import * as React from 'react';
import * as Moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../../../consts/date';
import ROLE from '../../../consts/user';
import { Event } from '../../../models/event';
import './EventItem.scss';

interface Props {
   event: Event
   userEmail: string,
   onManageClick: (id: string) => void
}

const EventItem: React.FC<Props> = (props) => {

   const { id, start, end, name, eventType, coHosts, host, guests, } = props.event;
   const { userEmail, onManageClick } = props;

   const timeStart = Moment(start).format(TIME_FORMAT);
   const timeEnd = Moment(end).format(TIME_FORMAT);
   const dateStart = Moment(start).format(DATE_FORMAT);
   const dateEnd = Moment(end).format(DATE_FORMAT);

   const isCoHost = coHosts.some(item => item.email === userEmail);
   const isHost = host.email === userEmail;
   const isDifferentDates = dateStart !== dateEnd


   let role;

   switch (true) {
      case isCoHost:
         role = ROLE.CO_HOST;
         break;

      case isHost:
         role = ROLE.HOST;
         break;

      default:
         role = '';
         break;
   }

   return (
      <div className="event-item">
         <div className="event-item__date">{dateStart} {timeStart} - {isDifferentDates && dateEnd} {timeEnd}</div>
         <div className="event-item__body">
            <div className="side left">
               <div className="side-block avatar">
                  <img src={'/dist/images/image/event/event-avatar.png'} alt="avatar" />
               </div>
               <div className="side-block event-text">{name}</div>
               <div className="side-block user">{role}</div>
            </div>
            <div className="side right">
               <button onClick={() => onManageClick(id)}>Manage</button>
               <button className='outline'>Cancel</button>
               <button className='outline'>Delete</button>
            </div>
         </div>
      </div>
   );
};

export default EventItem;
