import * as React from 'react';
import Button from '../../../../../components/button';
import { parse, ParseError, ParseResult } from 'papaparse';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { compose, withAppUser, withServices } from '../../../../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props {
  onCancel: () => void;
  onSubmit: (data: CsvImportFormValues) => void;
}
interface State {
  contacts: CSVContact[];
  isComplete: boolean;
  errors: ParseError[];
}

interface CSVContact {
  firstName: string;
  lastName: string;
  email: string;
}

export interface CsvImportFormValues {
  contacts: CSVContact[];
}

export class CSVImportForm extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  fileInputRef: any;

  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      contacts: [],
      errors: [],
      isComplete: false,
    };

    this.fileInputRef = React.createRef();
  }

  onFileChange = (eventDescriptor: React.ChangeEvent<HTMLInputElement>) => {
    const file = eventDescriptor.target.files[0];

    parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result: ParseResult<CSVContact>) => {
        this.setState({
          contacts: result.data,
          errors: result.errors,
          isComplete: true,
        });
      },
    });
  };

  render() {
    const { onCancel, checkContactEmail, onSubmit } = this.props;
    const { isComplete, contacts, errors } = this.state;

    const initialValues: CsvImportFormValues = {
      contacts: contacts,
    };

    const validationSchema = Yup.object().shape({
      contacts: Yup.array()
        .min(1, 'Should contain minimum 1 contacts email')
        .of(
          Yup.object().shape({
            email: Yup.string()
              .required('Required')
              .email('Must be valid email')
              .test('email', 'Duplicate contact', (value: string) => {
                return checkContactEmail({ email: value }).then((res) => {
                  return res.isAvailable;
                });
              }),
          })
        ),
    });

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3 className={'mb-3 text-center mt-3'}>Import CSV</h3>
            <div>
              {!isComplete && (
                <input type="file" onChange={this.onFileChange} ref={(input) => (this.fileInputRef = input)} />
              )}
            </div>

            {!isComplete && <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3 btn-secondary'} />}

            {isComplete &&
              errors.map((error, index) => {
                return <div key={`error_${index}`}>{error.message}</div>;
              })}

            {isComplete && (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, values }) => (
                  <Form>
                    <FieldArray name="contacts">
                      {({ remove }) => (
                        <div>
                          {values.contacts.length > 0 &&
                            values.contacts.map((contact: CSVContact, index: number) => (
                              <div className="row align-items-center mt-3" key={index}>
                                <div className="col-3">
                                  <Field name={`contacts.${index}.firstName`} className="form-control" />
                                </div>
                                <div className="col-4">
                                  <Field name={`contacts.${index}.lastName`} className="form-control" />
                                </div>
                                <div className="col-4">
                                  <Field name={`contacts.${index}.email`} className="form-control" />
                                </div>
                                <div className="col-1">
                                  <button type="button" className="btn-close" onClick={() => remove(index)} />
                                </div>
                                <div className="col-11">
                                  <ErrorMessage
                                    name={`contacts.${index}.email`}
                                    component="div"
                                    className="alert alert-danger mt-3"
                                  />
                                </div>
                                <div className={'col-1'} />
                              </div>
                            ))}
                        </div>
                      )}
                    </FieldArray>

                    {values.contacts.length === 0 && (
                      <ErrorMessage component="div" className="alert alert-danger mt-3" name={`contacts`} />
                    )}

                    <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 me-3 btn-secondary'} />
                    <Button onClick={handleSubmit} text={'Save contacts'} customClass={'mt-3 mb-3'} type={'submit'} />
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const CSVImportFormPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(CSVImportForm);

export default CSVImportFormPage;
