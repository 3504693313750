import * as React from 'react';
import * as Moment from 'moment';
import { mapMethodsToProps, MapMethodsToProps } from '../../../../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, withAppUser, withServices } from '../../../../../components/hoc-helpers';
import { ToDoList } from '../../../../../models/to-do-list';
import Loader from '../../../../../components/loader';
import ErrorIndicator from '../../../../../components/error-indicator';
import { EventToDoListFormValues } from './EventToDoListForm/CreateEventToDoListForm';
import { CreateEventToDoListForm, EditEventToDoListForm } from './EventToDoListForm';
import {
  CreateEventToDoListTaskForm,
  EventToDoListTaskFormValues,
} from './EventToDoListTaskForm/CreateEventToDoListTaskForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../../components/button';
import SimpleModal from '../../../../../components/simple-modal';
import { EditEventToDoListTaskForm } from './EventToDoListTaskForm';
import { DATE_TIME_FORMAT } from '../../../../../consts/date';
import { Event } from '../../../../../models/event';

interface Props {
  event: Event;
  eventId: string;
}

interface State {
  isLoading: boolean;
  isError: boolean;
  toDoLists: ToDoList[];
  accordionActiveToDoListId: string;
  activeTaskId: string;
  isEditToDoListModal: boolean;
  isEditToDoListTaskModal: boolean;
}

class EventToDoLists extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      isLoading: true,
      isError: false,
      toDoLists: [],
      accordionActiveToDoListId: '',
      activeTaskId: '',
      isEditToDoListModal: false,
      isEditToDoListTaskModal: false,
    };
  }

  componentDidMount() {
    const { getEventToDoLists, match } = this.props;
    const { params } = match as any;
    const { eventId } = params;

    this.setState({
      isLoading: true,
    });

    getEventToDoLists(eventId)
      .then((toDoLists) => {
        this.setState({
          isLoading: false,
          toDoLists: toDoLists,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }
  onSubmitCreateToDoListForm = (data: EventToDoListFormValues) => {
    const { createEventToDoList, getEventToDoLists, eventId } = this.props;
    const { name } = data;

    const dataToPost = {
      name,
    };

    this.setState({
      isLoading: true,
    });

    createEventToDoList(eventId, dataToPost)
      .then((res) => getEventToDoLists(eventId))
      .then((toDoLists) => {
        this.setState({
          isLoading: false,
          toDoLists,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onSubmitCreateToDoListTaskForm = (data: EventToDoListTaskFormValues) => {
    const { createEventToDoListTask, getEventToDoLists, eventId } = this.props;
    const { accordionActiveToDoListId: toDoListId } = this.state;
    const { text } = data;

    const dataToPost = {
      text,
    };

    this.setState({
      isLoading: true,
    });

    createEventToDoListTask(eventId, toDoListId, dataToPost)
      .then((res) => getEventToDoLists(eventId))
      .then((toDoLists) => {
        this.setState({
          isLoading: false,
          toDoLists,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onClickAccordionButton = (id: string) => {
    this.setState((prevState) => ({
      accordionActiveToDoListId: prevState.accordionActiveToDoListId === id ? '' : id,
    }));
  };

  onRemoveToDoListButtonClick = (toDoListId: string) => {
    const { removeEventToDoList, getEventToDoLists, eventId } = this.props;

    this.setState({
      isLoading: true,
    });

    removeEventToDoList(eventId, toDoListId)
      .then((res) => getEventToDoLists(eventId))
      .then((toDoLists) => {
        this.setState({
          isLoading: false,
          toDoLists,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onRemoveToDoListTaskButtonClick = (taskId: string) => {
    const { removeEventToDoListTask, getEventToDoLists, eventId } = this.props;
    const { accordionActiveToDoListId } = this.state;

    this.setState({
      isLoading: true,
    });

    removeEventToDoListTask(eventId, accordionActiveToDoListId, taskId)
      .then((res) => getEventToDoLists(eventId))
      .then((toDoLists) => {
        this.setState({
          isLoading: false,
          toDoLists,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onTaskCheckBoxClick = (taskId: string, status: string) => {
    const { changeEventToDoListTaskStatus, getEventToDoLists, eventId } = this.props;
    const { accordionActiveToDoListId } = this.state;

    this.setState({
      isLoading: true,
    });

    changeEventToDoListTaskStatus(
      eventId,
      accordionActiveToDoListId,
      taskId,
      status === 'COMPLETE' ? 'ACTIVE' : 'COMPLETE'
    )
      .then((res) => getEventToDoLists(eventId))
      .then((toDoLists) => {
        this.setState({
          isLoading: false,
          toDoLists,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  renderEditToDoListModal(): React.ReactNode {
    const { isEditToDoListModal, accordionActiveToDoListId, toDoLists } = this.state;

    const accordionActiveToDoList = toDoLists.find((toDoList) => toDoList.id === accordionActiveToDoListId);

    return (
      <SimpleModal isOpen={isEditToDoListModal}>
        <EditEventToDoListForm
          todoList={accordionActiveToDoList}
          onSubmit={this.onSubmitEditEventToDoListForm}
          onCancel={this.onCloseEditEventToDoListModalClick}
        />
      </SimpleModal>
    );
  }

  renderEditToDoListTaskModal(): React.ReactNode {
    const { event } = this.props;
    const { coHosts } = event;
    const { isEditToDoListTaskModal, accordionActiveToDoListId, toDoLists, activeTaskId } = this.state;
    if (!isEditToDoListTaskModal) {
      return null;
    }

    const accordionActiveToDoList = toDoLists.find((toDoList) => toDoList.id === accordionActiveToDoListId);
    const taskFounded = accordionActiveToDoList.tasks.find((task) => task.id === activeTaskId);

    return (
      <SimpleModal isOpen={isEditToDoListTaskModal}>
        <EditEventToDoListTaskForm
          task={taskFounded}
          coHosts={coHosts}
          onSubmit={this.onSubmitEditEventToDoListTaskForm}
          onCancel={this.onCloseEditEventToDoListTaskModalClick}
        />
      </SimpleModal>
    );
  }

  onSubmitEditEventToDoListTaskForm = (data: EventToDoListTaskFormValues) => {
    const { updateEventToDoListTask, getEventToDoLists, eventId } = this.props;
    const { accordionActiveToDoListId, activeTaskId } = this.state;
    const { text, deadline, responsibleUserId, subtasks } = data;

    let dataToPost: any = {
      text,
    };

    if (deadline) {
      dataToPost = { ...dataToPost, deadline };
    }

    if (responsibleUserId) {
      dataToPost = { ...dataToPost, responsible: { userId: responsibleUserId } };
    }

    const subtasksFiltered = subtasks.filter((subtask) => subtask.text);
    if (subtasksFiltered.length > 0) {
      dataToPost = { ...dataToPost, subtasks: subtasksFiltered };
    } else {
      dataToPost = { ...dataToPost, subtasks: [] };
    }

    this.setState({
      isLoading: true,
      isEditToDoListTaskModal: false,
    });

    updateEventToDoListTask(eventId, accordionActiveToDoListId, activeTaskId, dataToPost)
      .then((res) => getEventToDoLists(eventId))
      .then((toDoLists) => {
        this.setState({
          isLoading: false,
          toDoLists,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onSubmitEditEventToDoListForm = (data: EventToDoListFormValues) => {
    const { updateEventToDoList, getEventToDoLists, eventId } = this.props;
    const { accordionActiveToDoListId } = this.state;
    const { name } = data;

    const dataToPost = {
      name,
    };

    this.setState({
      isLoading: true,
      isEditToDoListModal: false,
    });

    updateEventToDoList(eventId, accordionActiveToDoListId, dataToPost)
      .then((res) => getEventToDoLists(eventId))
      .then((toDoLists) => {
        this.setState({
          isLoading: false,
          toDoLists,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  onCloseEditEventToDoListModalClick = () => {
    this.setState({
      isEditToDoListModal: false,
    });
  };

  onEditToDoListButtonClick = () => {
    this.setState({
      isEditToDoListModal: true,
    });
  };

  onCloseEditEventToDoListTaskModalClick = () => {
    this.setState({
      isEditToDoListTaskModal: false,
    });
  };

  onEditToDoListTaskButtonClick = (taskId: string) => {
    this.setState({
      isEditToDoListTaskModal: true,
      activeTaskId: taskId,
    });
  };

  render() {
    const { isLoading, isError, toDoLists, accordionActiveToDoListId, isEditToDoListModal, isEditToDoListTaskModal } =
      this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const classes = isEditToDoListModal || isEditToDoListTaskModal ? 'modal-open' : '';

    return (
      <div className={classes}>
        {this.renderEditToDoListModal()}
        {this.renderEditToDoListTaskModal()}
        <div className={'mt-3 mb-3'}>
          <h1>To Do Lists</h1>
          <div className="accordion">
            {toDoLists.map(({ id, name, tasks }) => (
              <div key={id} className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button" type="button" onClick={() => this.onClickAccordionButton(id)}>
                    {name}
                  </button>
                </h2>
                <div
                  className={
                    id === accordionActiveToDoListId
                      ? 'accordion-collapse collapse show'
                      : 'accordion-collapse collapse'
                  }
                >
                  <div className="accordion-body">
                    {tasks.length > 0 &&
                      tasks.map(({ id: taskId, text, status, deadline, responsible }) => (
                        <div key={taskId} className={'d-table-row'}>
                          <div className={'d-table-cell'}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={status === 'COMPLETE'}
                                id={taskId}
                                onChange={() => this.onTaskCheckBoxClick(taskId, status)}
                              />
                              <label
                                className={
                                  status === 'COMPLETE'
                                    ? 'form-check-label text-decoration-line-through'
                                    : 'form-check-label'
                                }
                                htmlFor={taskId}
                              >
                                {text}
                              </label>
                            </div>
                          </div>
                          <div className={'d-table-cell'}>
                            <Button
                              onClick={() => this.onEditToDoListTaskButtonClick(taskId)}
                              customClass={'mt-1 btn btn-success ms-3 btn-sm'}
                              text={[
                                <FontAwesomeIcon key={`icon_button_edit_task`} icon={['fas', 'edit']} />,
                                <span key={`span_button_edit_task`} style={{ marginLeft: '5px' }}>
                                  Edit
                                </span>,
                              ]}
                            />
                          </div>
                          <div className={'d-table-cell'}>
                            <Button
                              onClick={() => this.onRemoveToDoListTaskButtonClick(taskId)}
                              customClass={'mt-1 btn btn-danger ms-3 btn-sm'}
                              text={[
                                <FontAwesomeIcon key={`icon_button_remove_task`} icon={['fas', 'trash']} />,
                                <span key={`span_button_remove_task`} style={{ marginLeft: '5px' }}>
                                  Remove
                                </span>,
                              ]}
                            />
                          </div>
                          {typeof deadline !== 'undefined' ? (
                            <div className={'d-table-cell'}>
                              <div className={'ms-3'}>Deadline: {Moment(deadline).format(DATE_TIME_FORMAT)}</div>
                            </div>
                          ) : (
                            <div className={'d-table-cell'} />
                          )}

                          {typeof responsible !== 'undefined' && typeof responsible.userId !== 'undefined' ? (
                            <div className={'d-table-cell'}>
                              <div className={'ms-3'}>
                                Responsible:{' '}
                                {!responsible.firstName && !responsible.lastName
                                  ? responsible.email
                                  : `${responsible.firstName} ${responsible.lastName}`}
                              </div>
                            </div>
                          ) : (
                            <div className={'d-table-cell'} />
                          )}
                        </div>
                      ))}
                    <CreateEventToDoListTaskForm onSubmit={this.onSubmitCreateToDoListTaskForm} />

                    <hr />
                    <Button
                      onClick={this.onEditToDoListButtonClick}
                      customClass={'btn btn-success me-3'}
                      text={[
                        <FontAwesomeIcon key={`icon_button_edit`} icon={['fas', 'edit']} />,
                        <span key={`span_button_edit`} style={{ marginLeft: '5px' }}>
                          Edit To Do
                        </span>,
                      ]}
                    />
                    <Button
                      onClick={() => this.onRemoveToDoListButtonClick(id)}
                      customClass={'btn btn-danger'}
                      text={[
                        <FontAwesomeIcon key={`icon_button_remove`} icon={['fas', 'trash']} />,
                        <span key={`span_button_remove`} style={{ marginLeft: '5px' }}>
                          Remove To Do
                        </span>,
                      ]}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={'mt-3 mb-3'}>
          <CreateEventToDoListForm onSubmit={this.onSubmitCreateToDoListForm} />
        </div>
      </div>
    );
  }
}

const EventToDoListsPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(EventToDoLists);

export default EventToDoListsPage;
