import * as React from 'react';
import Sidebar from './Sidebar';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router';
import { AppUser } from '../App/App';
import { getRoutes } from '../../helpers/routing';
import { compose, withAppUser, withServices } from '../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../helpers/component';
import Loader from '../../components/loader';
import ErrorIndicator from '../../components/error-indicator';
import { Profile } from '../../models/profile';
import SimpleModal from '../../components/simple-modal';
import CreateEventModal from './CreateEventModal';
import * as propz from 'propz';
import { isUser } from '../../helpers/user';
import Header from './Header';
interface Props {
  onLogoutClick: () => void;
  setProfile: (profile: Profile) => void;
  user: AppUser;
}

interface State {
  isLoading: boolean;
  isError: boolean;
  isCreateEventModal: boolean;
}

class GenericView extends React.Component<Props & MapMethodsToProps & RouteComponentProps, State> {
  constructor(props: Props & MapMethodsToProps & RouteComponentProps) {
    super(props);

    this.state = {
      isLoading: true,
      isError: false,
      isCreateEventModal: false
    };
  }

  componentDidMount() {
    const { getProfile, setProfile, history, user } = this.props;

    getProfile()
      .then((profile) => {
        setProfile(profile);

        this.setState({
          isLoading: false,
        });

        const isEmailVerified = propz.get<boolean>(profile, ['verification', 'status', 'email'], false);
        if (isUser(user) && !isEmailVerified) {
          history.push('/profile');
        }
      })
      .catch(() => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  }

  renderCreateEventModal = (): React.ReactNode => {
    return (
      <SimpleModal isOpen={this.state.isCreateEventModal}>
        <CreateEventModal onCreateEventClick={this.onCreateEventClick} onClose={this.onCloseCreateEventModal} />
      </SimpleModal>
    );
  };

  onCloseCreateEventModal = (): void => {
    this.setState({ isCreateEventModal: false });
  };

  showCreateEventModal = (): void => {
    this.setState({ isCreateEventModal: true });
  };

  onCreateEventClick = () => {
    this.props.history.push('/events/create');
    this.onCloseCreateEventModal();
  };

  render() {
    const { user } = this.props;
    const { isLoading, isError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const userAvatar = propz.get(this.props.user, ['profile', 'avatar'], '');
    const firstName = propz.get(this.props.user, ['profile', 'firstName'], '');
    const lastName = propz.get(this.props.user, ['profile', 'lastName'], '');
    const fullName = firstName.split('')[0].toUpperCase() + lastName.split('')[0].toUpperCase()

    const routes = getRoutes(user);

    return (
      <div>
        {this.renderCreateEventModal()}
        <Header userAvatar={userAvatar} fullName={fullName} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 px-0">
              <Sidebar user={user} showCreateEventModal={this.showCreateEventModal} />
            </div>
            <div className="col-md-10 pt-2 ps-3">
              <Switch>
                {routes.map((route, i) => (
                  <Route
                    exact={route.exact}
                    key={`route_${i}`}
                    path={route.path}
                    render={(routeProps) => <route.component {...this.props} />}
                  />
                ))}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const GenericViewPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(GenericView);

export default GenericViewPage;
