import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import { match, RouteComponentProps, withRouter } from 'react-router';
import { compose, withAppUser, withServices } from '../../components/hoc-helpers';
import { History } from 'history';
import Loader from '../../components/loader';
import ErrorIndicator from '../../components/error-indicator';
import { AppUser } from '../App/App';
import { MapMethodsToProps, mapMethodsToProps } from '../../helpers/component';

interface Props {
  match: match;
  history: History;
  user: AppUser;
}

interface State {
  isLoading: boolean;
  isError: boolean;
  errorText: string;
}

class InviteCoHost extends Component<Props & RouteComponentProps & MapMethodsToProps, State> {
  constructor(props: Props & RouteComponentProps & MapMethodsToProps) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
      errorText: '',
    };
  }

  componentDidMount() {
    const { acceptInviteCoHost, history, match, user } = this.props;
    const { authorized } = user;

    const { params } = match as any;
    const { id } = params;

    this.setState({
      isLoading: true,
    });

    acceptInviteCoHost(id)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        history.push(authorized ? '/events' : '/login');
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
          errorText: propz.get(err, ['response', 'data', 'details', 'text']),
        });
      });
  }

  render() {
    const { isLoading, isError, errorText } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator errorText={errorText} />;
    }

    return null;
  }
}

const InviteCoHostPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(InviteCoHost);

export default InviteCoHostPage;
