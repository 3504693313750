import StorageHelper from './storage';

export function getSession() {
  return StorageHelper.Cookie.get('session');
}

export function clearSession(): void {
  StorageHelper.Cookie.remove('session');
}

export function setCookieAgreement() {
  return StorageHelper.Cookie.set('cookiesAgreement', 'true', { expires: 365 });
}

export function getCookieAgreement() {
  return StorageHelper.Cookie.get('cookiesAgreement');
}