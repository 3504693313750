import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../../../../../components/button';

interface Props {
  onSubmit: (data: EventDetailsCoHostFormValues) => void;
  userEmail: string;
}

export interface EventDetailsCoHostFormValues {
  email: string;
}

export function EventDetailsCoHostForm(props: Props) {
  const { onSubmit, userEmail } = props;

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required').email('Must be valid email')
      .test('email', 'You cannot send an invitation to yourself.', (value: string) => {
        return userEmail === value ? false : true;
      })
  });

  const initialValues: EventDetailsCoHostFormValues = {
    email: '',
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form>
          <div className="d-flex gap-3">
            <div>
              <Field placeholder={'Enter email here'} name="email" className="form-control mb-3" />
            </div>
            <div>
              <Button onClick={handleSubmit} text={'Invite Co-Host'} customClass={'mb-3'} type={'submit'} />
            </div>
          </div>
          <ErrorMessage component="div" className="alert alert-danger" name="email" />
        </Form>
      )}
    </Formik>
  );
}
