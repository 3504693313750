import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../components/button';
import * as Yup from 'yup';
import './Registration.scss';
import { compose, withAppUser, withServices } from '../../components/hoc-helpers';
import { MapMethodsToProps, mapMethodsToProps } from '../../helpers/component';
import { RouteComponentProps, withRouter } from 'react-router';
import Loader from '../../components/loader';
import ErrorIndicator from '../../components/error-indicator';
import { History } from 'history';

interface Props {
  history: History;
}

interface State {
  isLoading: boolean;
  isError: boolean;
}

export interface RegistrationFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

class Registration extends React.Component<Props & RouteComponentProps & MapMethodsToProps, State> {
  constructor(props: Props & RouteComponentProps & MapMethodsToProps) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
    };
  }

  onSubmit = (data: RegistrationFormValues) => {
    const { register, history } = this.props;
    this.setState({
      isLoading: true,
    });

    register(data)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        history.push('/login');
      })
      .catch((err) => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      });
  };

  render() {
    const { isLoading, isError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <ErrorIndicator />;
    }

    const { checkAvailability } = this.props;

    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string()
        .required('Required')
        .trim()
        .email('Must be valid email')
        .test('email', 'Duplicate email', (value) => {
          if (!value) {
            return false;
          } else {
            return checkAvailability({ email: value }).then((res) => {
              return res.isAvailable;
            });
          }
        }),
      password: Yup.string()
        .required('Required')
        .test(
          'password',
          'Password must contain at least one capital, lower case character, one number and must be 8 digits long',
          (value) => {
            const passwordRegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/);

            return value && passwordRegExp.test(value);
          }
        ),
      confirmPassword: Yup.string().test('confirmPassword', 'Passwords must match', function (value) {
        return value === this.parent.password;
      }),
    });

    const initialValues: RegistrationFormValues & { confirmPassword: string } = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    };

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className={'mb-3 text-center mt-3'}>Registration Form</h3>
            <div className={'eRegistrationForm'}>
              <Formik
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
                onSubmit={(data) => {
                  const { confirmPassword, ...rest } = data;
                  this.onSubmit(rest);
                }}
              >
                {({ handleSubmit, values, setFieldValue }) => (
                  <Form>
                    <div className="form-group">
                      <label>Name</label>
                      <Field name="firstName" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="firstName" />
                    </div>

                    <div className="form-group">
                      <label>Surname</label>
                      <Field name="lastName" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="lastName" />
                    </div>

                    <div className="form-group">
                      <label>Email</label>
                      <Field name="email" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="email" />
                    </div>

                    <div className="form-group">
                      <label>Password</label>
                      <Field type={'password'} name="password" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="password" />
                    </div>

                    <div className="form-group">
                      <label>Confirm password</label>
                      <Field type={'password'} name="confirmPassword" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="confirmPassword" />
                    </div>

                    <Button onClick={handleSubmit} text={'Ok'} customClass={'mt-3 mb-3'} type={'submit'} />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const RegistrationPage = compose(withServices(mapMethodsToProps), withRouter, withAppUser)(Registration);

export default RegistrationPage;
