import { EventType } from '../models/event-type';
import { Event } from '../models/event';
import { Services } from '../components/services-context/services-context';
import { Session } from '../models/session';
import { ToDoList, ToDoListTask } from '../models/to-do-list';
import { Template } from '../models/template';
import { InviteCoHost } from '../models/invite-co-host';
import { InviteGuest } from '../models/invite-guest';
import { Tag } from '../models/tag';
import { TagFormValues } from '../views/GenericView/UserView/Tags/TagForm/TagForm';
import { Contact } from '../models/contact';
import { ContactFormValues } from '../views/GenericView/UserView/Contacts/ContactForm/ContactForm';
import { RegistrationFormValues } from '../views/Registration/Registration';
import { Profile } from '../models/profile';
import { ResetPasswordFormValues } from '../views/ResetPassword/ResetPasswordForm/ResetPasswordForm';
import { InviteGuestFormValues } from '../views/GenericView/UserView/Events/InviteGuestForm/InviteGuestForm';
import { InviteGuestAcceptFormValues } from '../views/InviteGuestAccept/InviteGuestAcceptForm/InviteGuestAcceptForm';

export interface MapMethodsToProps {
  getProfile: () => Promise<Profile>;
  updateProfile: (data: any) => Promise<Profile>;
  updatePassword: (newPassword: string, oldPassword: string) => Promise<Profile>;
  getEventTypes: () => Promise<EventType[]>;
  getTemplates: () => Promise<Template[]>;
  getEvents: (filter?: any) => Promise<Event[]>;
  getEvent: (eventId: string) => Promise<Event>;
  getEventToDoLists: (eventId: string) => Promise<ToDoList[]>;
  createEventToDoList: (eventId: string, data: any) => Promise<ToDoList>;
  updateEventToDoList: (eventId: string, toDoListId: string, data: any) => Promise<ToDoList>;
  removeEventToDoList: (eventId: string, toDoListId: string) => Promise<any>;
  createEventToDoListTask: (eventId: string, toDoListId: string, data: any) => Promise<ToDoListTask>;
  updateEventToDoListTask: (eventId: string, toDoListId: string, taskId: string, data: any) => Promise<ToDoListTask>;
  removeEventToDoListTask: (eventId: string, toDoListId: string, taskId: string) => Promise<any>;
  changeEventToDoListTaskStatus: (
    eventId: string,
    toDoListId: string,
    taskId: string,
    status: string
  ) => Promise<ToDoListTask>;
  getUpcomingEvents: (filter?: any) => Promise<Event[]>;
  getPastEvents: (filter?: any) => Promise<Event[]>;
  createEvent: (data: any) => Promise<Event>;
  inviteCoHost: (eventId: string, data: { email: string }) => Promise<any>;
  inviteGuest: (eventId: string, data: InviteGuestFormValues) => Promise<any>;
  getInviteGuests: (eventId: string, filter?: any) => Promise<InviteGuest[]>;
  getInviteGuest: (id: string) => Promise<InviteGuest>;
  getInviteCoHosts: (eventId: string) => Promise<InviteCoHost[]>;
  logout: () => Promise<any>;
  login: (email: string, password: string) => Promise<Session>;
  acceptInviteCoHost: (id: string) => Promise<any>;
  acceptInviteGuest: (id: string, data?: InviteGuestAcceptFormValues) => Promise<any>;
  rejectInviteGuest: (id: string) => Promise<any>;
  maybeInviteGuest: (id: string) => Promise<any>;
  getUsers: () => Promise<any>;
  getTags: (filter?: any) => Promise<Tag[]>;
  createTag: (data: TagFormValues) => Promise<Tag>;
  removeTag: (id: string) => Promise<any>;
  updateTag: (id: string, data: TagFormValues) => Promise<Tag>;
  getContacts: (filter?: any) => Promise<Contact[]>;
  createContact: (data: ContactFormValues) => Promise<Contact>;
  removeContact: (id: string) => Promise<any>;
  updateContact: (id: string, data: ContactFormValues) => Promise<Contact>;
  checkContactEmail: (data: { email: string }) => Promise<{ isAvailable: boolean }>;
  checkAvailability: (data: { email: string } | { phone: string }) => Promise<{ isAvailable: boolean }>;
  register: (data: RegistrationFormValues) => Promise<any>;
  checkTokenEmail: (tokenEmail: string) => Promise<any>;
  resetRequestPassword: (data: ResetPasswordFormValues) => Promise<any>;
  resetPassword: (password: string, secretKey: string) => Promise<any>;
  uploadPublicFile: (image: any) => Promise<{ mimeType: string; url: string }>;
  getFileApi: () => string;
}

export const mapMethodsToProps = (services: Services): MapMethodsToProps => {
  return {
    getProfile: services.user.getProfile,
    updateProfile: services.user.updateProfile,
    updatePassword: services.user.updatePassword,
    getEventTypes: services.user.getEventTypes,
    getTemplates: services.user.getTemplates,
    getTags: services.user.getTags,
    createTag: services.user.createTag,
    updateTag: services.user.updateTag,
    removeTag: services.user.removeTag,
    checkContactEmail: services.user.checkContactEmail,
    getContacts: services.user.getContacts,
    createContact: services.user.createContact,
    updateContact: services.user.updateContact,
    removeContact: services.user.removeContact,
    getEvents: services.user.getEvents,
    getEventToDoLists: services.user.getEventToDoLists,
    createEventToDoList: services.user.createEventToDoList,
    updateEventToDoList: services.user.updateEventToDoList,
    removeEventToDoList: services.user.removeEventToDoList,
    createEventToDoListTask: services.user.createEventToDoListTask,
    updateEventToDoListTask: services.user.updateEventToDoListTask,
    removeEventToDoListTask: services.user.removeEventToDoListTask,
    changeEventToDoListTaskStatus: services.user.changeEventToDoListTaskStatus,
    getEvent: services.user.getEvent,
    getUpcomingEvents: services.user.getUpcomingEvents,
    getPastEvents: services.user.getPastEvents,
    createEvent: services.user.createEvent,
    inviteCoHost: services.user.inviteCoHost,
    inviteGuest: services.user.inviteGuest,
    getInviteGuests: services.user.getInviteGuests,
    getInviteCoHosts: services.user.getInviteCoHosts,
    logout: services.user.logout,

    getUsers: services.admin.getUsers,

    login: services.public.login,
    checkAvailability: services.public.checkAvailability,
    acceptInviteCoHost: services.public.acceptInviteCoHost,
    getInviteGuest: services.public.getInviteGuest,
    acceptInviteGuest: services.public.acceptInviteGuest,
    rejectInviteGuest: services.public.rejectInviteGuest,
    maybeInviteGuest: services.public.maybeInviteGuest,
    register: services.public.register,
    checkTokenEmail: services.public.checkTokenEmail,
    resetRequestPassword: services.public.resetRequestPassword,
    resetPassword: services.public.resetPassword,

    uploadPublicFile: services.file.uploadPublicFile,
    getFileApi: services.file.getFileApi,
  };
};
