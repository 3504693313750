import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { AppUser } from '../../App/App';
import { getSidebarItems } from '../../../helpers/sidebar';
import SimpleModal from '../../../components/simple-modal';
import './Sidebar.scss';

interface Props {
  user: AppUser;
  showCreateEventModal: () => void;
}

export function Sidebar(props: Props) {
  const { user, showCreateEventModal } = props;

  const sidebarItems = getSidebarItems(user);

  return (
    <div className='sidebar'>
      <div className="create-event-btn px-2">
        <button className='pa-3' onClick={showCreateEventModal}>
          <span>+</span>
          new event
        </button>
      </div>
      <div className="sidebat-top-nav px-2">
        {sidebarItems.map(({ title, to }) => (
          <NavLink
            key={title}
            className={'nav-link'}
            to={to}
          >
            {title}
          </NavLink>
        ))}
      </div>
      <div className="sidebar-bottom-nav px-2"></div>
    </div>
  );
}
