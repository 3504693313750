import * as React from 'react';
import classNames from 'classnames';
import './logo.scss';

interface Props {
   customClass?: string;
}

const Logo = (props: Props) => {
   const { customClass } = props;
   const logoClasses = classNames('logo', customClass);
   return (
      <div className={logoClasses}>
         <img src="/dist/images/image/header/header-logo.png" alt="logo" />
      </div>
   )
}

export default Logo;
