import * as React from 'react';
import './Landing.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CookiesAgreementModal from '../../components/cookies-agreement-modal';

interface Props {
  isCookiesModalOpen: boolean;
  onOk: () => void;
}

export function Landing(props: Props) {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const loginLink = `${document.location.protocol}//${document.location.host}/login`;

  return (
    <>
      <div className="header">
        <div className="bWrapper">
          <section className="bHeaderSection">
            <div className="customContainer">
              <div className="row">
                <div className="col-xl-8 eHeaderContentWrapper">
                  <div className="eHeaderLogoWrapper">
                    <div className="eHeaderLogo">
                      <img className="eHeaderLogoIcon" src="/dist/images/icon/logo.svg" />
                      <div className="eHeaderGuestItem noShowContent">
                        I am a <span className="fw-bold">Guest</span>
                      </div>
                      <div className="eHeaderHostItem noShowContent">
                        I am a <span className="fw-bold">Host</span>
                      </div>
                      <div className="bHeaderBurger">
                        <div className="eBurgerIcon">
                          {!isShowMenu && (
                            <img
                              onClick={() => {
                                setIsShowMenu(!isShowMenu);
                              }}
                              className="burgerImage"
                              src="/dist/images/icon/burger.svg"
                            />
                          )}
                          {isShowMenu && (
                            <img
                              onClick={() => {
                                setIsShowMenu(!isShowMenu);
                              }}
                              className="burgerImage"
                              src="/dist/images/icon/close.svg"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 hidden-xs eHeaderContentWrapper">
                  <div className="bHeaderNavWrapper">
                    <Link className="" to={loginLink} target="_blank">
                      <button type="submit" className="eLoginButton noShowContent">
                        Log In
                      </button>
                    </Link>
                    <div className="noShowContent">
                      <button type="submit" className="eSignUpButton">
                        Sign Up For Free
                      </button>
                    </div>
                  </div>
                </div>
                {isShowMenu && (
                  <div className="bHeaderBurgerMenu">
                    <Link className="" to={loginLink} target="_blank">
                      <button type="submit" className="eLoginButton">
                        Log In
                      </button>
                    </Link>
                    <button type="submit" className="eSignUpButton">
                      Sign Up For Free
                    </button>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="main">
        <div className="bWrapper">
          <section className="bIntroSection">
            <div className="customContainer">
              <div className="row">
                <div className="col-xs-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <div className="eIntroTitleWrapper">
                    <div className="eIntroTitleText">
                      Make every special event remarkable. No matter how big or small.
                    </div>
                  </div>
                  <div className="eIntroSubtitleWrapper">
                    <div className="eIntroSubtitleText">For FREE. Forever.</div>
                  </div>
                  <div className="eIntroCreateEventButtonWrapper">
                    <button type="submit" className="eFeaturesCreateEventButton">
                      Create My First Event
                      <img className="eArrowRightIcon" src="/dist/images/icon/arrowRight.svg" />
                    </button>
                  </div>
                </div>
                <div className="col-xs-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="eIntroImageWrapper">
                    <img className="introImage" src="/dist/images/image/cake.svg" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="bMomentsPlaceSection">
          <div className="customContainer ">
            <div className="row bMomentsPlaceInfoBlock">
              <div className="eMomentsPlaceTitleWrapper">
                <div className="eMomentsPlaceTitleText">All your special moments in one place.</div>
              </div>
              <div className="col-md-8 col-sm-10 col-xs-12 col-lg-8 col-xl-6">
                <div className="eMomentsPlaceContentWrapper">
                  <div className="eMomentsPlaceContent">
                    Plan, organise and manage your events. Simple, easy and efficient. Share your excitement with your
                    friends and family across all events, from family gatherings to birthday celebrations, weddings and
                    reunions as well as your children’s parties. No more spreadsheets, endless calls and notes on scrap
                    paper – keep everyone up to date of any changes and gather all the important details in one place.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="planning&management" className="ePlaningManagementSection">
          <div
            className="customContainer eManagementHeightBlock"
            style={{
              background: `url("/dist/images/image/PlanningManagement.svg") no-repeat center center`,
              backgroundSize: 'cover',
            }}
          >
            <div className="eManagementTitleWrapper">
              <div className="eFeaturesTitle">Planning and Management</div>
            </div>
            <div className="customContainer">
              <div className="row">
                <div className="order-xl-first order-last col-lg-4 col-md-12 col-xl-4  col-sm-12 col-xs-12 columnCenterWrapper">
                  <img className="eManagementImage" src="/dist/images/image/glass.png" />
                </div>
                <div className="col-lg-8 col-md-12 col-xl-8  col-sm-12 col-xs-12 eManagementColumnWrapper">
                  <div className="eManagementContent">
                    <div className="eManagementFeaturesInfoRow">
                      <div className="">
                        <img className="" src="/dist/images/image/point.svg" />
                      </div>
                      <div className="eFeaturesContentText">
                        Organise all of your events and social occasions no matter the size
                      </div>
                    </div>
                    <div className="eManagementFeaturesInfoRow">
                      <div className="">
                        <img className="" src="/dist/images/image/point.svg" />
                      </div>
                      <div className="eFeaturesContentText">Invite co-hosts</div>
                    </div>
                    <div className="eManagementFeaturesInfoRow">
                      <div className="">
                        <img className="" src="/dist/images/image/point.svg" />
                      </div>
                      <div className="eFeaturesContentText">Delegate tasks and track their progress</div>
                    </div>
                  </div>
                  <div className="eManagementButtonWrapper">
                    <button type="submit" className="eFeaturesCreateEventButton">
                      Create My First Event
                      <img className="eArrowRightIcon" src="/dist/images/icon/arrowRight.svg" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="invitations" className="bInvitationSection">
          <div className="customContainer">
            <div className="row">
              <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-xs-12 eInvitationTitleWrapper">
                <div className="eFeaturesTitle">Invitations, Guest Lists and RSVP</div>
              </div>
              <div className="eInvitationContentWrapper">
                <div className="">
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText">Send customised invitations</div>
                  </div>
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText">Collect RSVPs</div>
                  </div>
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText">Communicate with invitees, guests or selected contacts</div>
                  </div>
                  <div className="eInvitationButtonWrapper">
                    <button type="submit" className="eFeaturesCreateEventButton">
                      Create My First Event
                      <img className="eArrowRightIcon" src="/dist/images/icon/arrowRight.svg" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="customContainer">
              <div className="row">
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-6 d-flex justify-content-center">
                  <img className="eInvitationRightIcon" src="/dist/images/image/invite.svg" />
                </div>
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-6 d-flex justify-content-center">
                  <img className="eInvitationLeftIcon" src="/dist/images/image/invitation.svg" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="presents" className="presentBackgroundBlock">
          <div
            className="customContainer bPresentSection"
            style={{
              background: `rgba(214,233,242) url("/dist/images/image/present.svg") no-repeat center center`,
              backgroundSize: 'cover',
            }}
          >
            <div className="ePresentTitleWrapper">
              <div className="eFeaturesTitle">Wish-lists and Presents</div>
            </div>
            <div className="row">
              <div className="col-xl-7 col-lg-6 col-md-12 PresentColumnStartWrapper">
                <div className="ePresentContent">
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText">Compose your wish-lists</div>
                  </div>
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText"> Include present ideas and suggestions</div>
                  </div>
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText">Allow guests to reserve presents</div>
                  </div>
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText">
                      Enable gift sharing / <br />
                      Enable guests to share the cost
                    </div>
                  </div>
                </div>
                <div className="ePresentButtonWrapper">
                  <button type="submit" className="eFeaturesCreateEventButton">
                    Create My First Event
                    <img className="eArrowRightIcon" src="/dist/images/icon/arrowRight.svg" />
                  </button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-12">
                <div className="ePresentImageWrapper">
                  <img className="eGiftIcon" src="/dist/images/image/gift.png" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="memories" className="">
          <div
            className="customContainer bMemoriesSection"
            style={{
              background: `url("/dist/images/image/memories.svg") no-repeat center center`,
              backgroundSize: 'cover',
            }}
          >
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 eMemoriesTitleWrapper">
                <div className="eFeaturesTitle">Excitement and Memories</div>
              </div>
              <div className="eMemoriesContentWrapper">
                <div className="">
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText">Get your dedicated customised event website</div>
                  </div>
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText">Manage website access</div>
                  </div>
                  <div className="eFeaturesInfoRow">
                    <div className="">
                      <img className="" src="/dist/images/image/point.svg" />
                    </div>
                    <div className="eFeaturesContentText">Share photos and videos</div>
                  </div>
                  <div className="eMemoriesButtonWrapper eButtonPosition">
                    <button type="submit" className="eFeaturesCreateEventButton ">
                      Create My First Event
                      <img className="eArrowRightIcon" src="/dist/images/icon/arrowRight.svg" />
                    </button>
                    <img className="blueberryImage" src="/dist/images/image/blueberry.svg" />
                    <div>
                      <img className="blackberryImage" src="/dist/images/image/blackberry.svg" />
                      <img className="berryImage" src="/dist/images/image/berry.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bFooter">
          <div className="bWrapper">
            <div className="customContainer">
              <img className="eFooterImage" src="/dist/images/image/confetti.svg" />
              <div className="row eRowWrapper">
                <div className="order-lg-first order-last col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="eFooterSectionWrapper">
                    <img className="eFooterLogoIcon" src="/dist/images/icon/logoFooter.svg" />
                    <div className="eFooterСopyrightText"> © 2021 Eventberries</div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-3 col-md-6 d-none d-md-block">
                  <div className="eFooterText eFooterSectionWrapper">
                    <div>
                      Make every special event remarkable. <br /> No matter how big or small.
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="eFooterSectionWrapper">
                    <div className="eFooterTitle">Contacts</div>
                    <a className="eFooterSectionText">info@eventberries.com</a>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                  <div className="eFooterSectionWrapper ">
                    <div className="eFooterTitle">Platform</div>
                    <a className="eFooterSectionText" href="#planning&management">
                      Planning and Management
                    </a>
                    <a className="eFooterSectionText" href="#invitations">
                      Invitations and RSVP
                    </a>
                    <a className="eFooterSectionText" href="#presents">
                      Presents and Wish Lists
                    </a>
                    <a className="eFooterSectionText" href="#memories">
                      Excitement and Memories
                    </a>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 eFooterImageWrapper">
                  <div className="eFooterSectionWrapper">
                    <div className="eFooterTitle">Support</div>
                    <a className="eFooterSectionText">
                      <div>FAQ</div>
                      <div>Help videos</div>
                      <div>Privacy policy</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {props.isCookiesModalOpen && <CookiesAgreementModal onOk={props.onOk} />}
    </>
  );
}
