import * as React from 'react';
import classNames from 'classnames';
import { ReactNode } from 'react';
import './simple-modal.scss';

interface Props {
  isOpen: boolean;
  title?: string;
  body?: string;
  positiveText?: string;
  negativeText?: string;
  onPositiveButtonClick?: () => void;
  onNegativeButtonClick?: () => void;
  onCloseButtonClick?: () => void;
  customClass?: string;
  children?: ReactNode;
}

export function SimpleModal({
  isOpen,
  title,
  onNegativeButtonClick,
  body,
  children,
  onPositiveButtonClick,
  customClass,
  positiveText = 'Ok',
  negativeText = 'Cancel',
  onCloseButtonClick,
}: Props) {
  if (!isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayFlex: isOpen,
    modalOverlay: isOpen,
    show: isOpen,
    modal: true,
    fade: true,
  });

  const isTitleExist = typeof title !== 'undefined';
  const isOnCloseClickFunction = typeof onNegativeButtonClick === 'function';
  const isOnCloseButtonClick = typeof onCloseButtonClick === 'function';
  const isHeaderRender = isTitleExist || isOnCloseClickFunction || isOnCloseButtonClick;
  const isBodyExist = typeof body !== 'undefined';
  const isChildrenElementsExist = typeof children !== 'undefined';
  const isBodyRender = isBodyExist || isChildrenElementsExist;
  const isButtonTextExist = typeof positiveText !== 'undefined';
  const isOnButtonClickFunction = typeof onPositiveButtonClick === 'function';
  const isButtonRender = isButtonTextExist && isOnButtonClickFunction;
  const isFooterRender = isButtonRender || isOnCloseClickFunction;

  const customClasses = classNames('modal-dialog', customClass);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-xl-12">
          <div className={modalClasses}>
            <div className={customClasses}>
              <div className="modal-content simple-modal">
                {isHeaderRender && (
                  <div className="modal-header">
                    {isTitleExist && <h5 className="modal-title">{title}</h5>}
                    {isOnCloseClickFunction && (
                      <button type="button" className="btn-close" onClick={onNegativeButtonClick} />
                    )}
                    {isOnCloseButtonClick && (
                      <button type="button" className="btn-close" onClick={onCloseButtonClick} />
                    )}
                  </div>
                )}
                {isBodyRender && (
                  <div className="modal-body mPre">
                    {isBodyExist && body}
                    {isChildrenElementsExist && children}
                  </div>
                )}
                {isFooterRender && (
                  <div className="modal-footer">
                    {isOnCloseClickFunction && (
                      <button type="button" className="btn btn-secondary" onClick={onNegativeButtonClick}>
                        {negativeText}
                      </button>
                    )}
                    {isButtonRender && (
                      <button type="button" className="btn btn-primary" onClick={onPositiveButtonClick}>
                        {positiveText}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
