import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/button';

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .test(
      'password',
      'Password must contain at least one capital, lower case character, one number and must be 8 digits long',
      (value) => {
        const passwordRegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/);

        return value && passwordRegExp.test(value);
      }
    ),
  confirmPassword: Yup.string().test('confirmPassword', 'Passwords must match', function (value) {
    return value === this.parent.password;
  }),
});

interface Props {
  onSubmit: (password: string) => void;
}

export function ChangePasswordForm(props: Props) {
  const { onSubmit } = props;
  const changePasswordForm = {
    password: '',
    confirmPassword: '',
  };

  return (
    <Formik
      initialValues={changePasswordForm}
      validationSchema={ChangePasswordSchema}
      onSubmit={(data) => {
        onSubmit(data.password);
      }}
    >
      {({ handleSubmit }) => (
        <Form>
          <div className={'mt-3 mb-3 text-center'}>Please enter new password</div>
          <div className="form-group">
            <Field type={'password'} name="password" className="form-control" />
            <ErrorMessage component="div" className="alert alert-danger mt-3" name="password" />
          </div>
          <div className={'mt-3 mb-3 text-center'}>Confirm password</div>
          <div className="form-group">
            <Field type={'password'} name="confirmPassword" className="form-control" />
            <ErrorMessage component="div" className="alert alert-danger mt-3" name="confirmPassword" />
          </div>
          <Button onClick={handleSubmit} text={'Save'} customClass={'mt-3 mb-3 btn-lg btn-block'} type={'submit'} />
        </Form>
      )}
    </Formik>
  );
}
