export const EVENT_TABS = ['Details', 'To-Do Lists', 'Invitations', 'Guests', 'Website', 'Photos / Videos'];
export const USER_PROFILE_TABS = ['Summary', 'Change password'];

export const TABS = {
  DETAILS: 'Details',
  TO_DO_LISTS: 'To-Do Lists',
  INVITATIONS: 'Invitations',
  GUESTS: 'Guests',
  WEBSITE: 'Website',
  PHOTOS_AND_VIDEOS: 'Photos / Videos',
  SUMMARY: 'Summary',
  CHANGE_PASSWORD: 'Change password',
};
