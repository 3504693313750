import * as React from 'react';
import classNames from 'classnames';

import './button.scss';

interface Props {
  onClick: (item?: any) => void;
  text: string | string[] | (string | React.ReactElement<any>)[];
  customClass?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

export function Button({ onClick, text, customClass, disabled = false, type = 'button' }: Props) {
  const buttonClass = classNames('btn btn-outline-primary', customClass);

  return (
    <button className={buttonClass} disabled={disabled} onClick={onClick} type={type}>
      {text}
    </button>
  );
}

Button.displayName = 'Button';

Button.defaultProps = {
  disabled: false,
  type: 'button',
};
