type envType = 'local' | 'dev' | 'stage' | 'production';

export function apiSelector(domainName: string): { api: string; fileApi: string } {
  const env = getEnv(domainName);
  switch (env) {
    case 'local':
      return {
        api: `//localhost:3000`,
        fileApi: '//localhost:3003',
      };
    case 'dev':
    case 'stage':
      return {
        api: `https://api.stage.eventberries.com`,
        fileApi: 'https://storage.stage.eventberries.com',
      };
    default:
      return {
        api: `https://api.eventberries.com`,
        fileApi: 'https://storage.eventberries.com',
      };
  }
}

function getEnv(domainName: string): envType {
  const isLocal = domainName.includes('local');
  const isStage = domainName.includes('stage');
  const isDev = domainName.includes('dev');

  let env: envType;

  switch (true) {
    case isLocal:
      env = 'local';
      break;
    case isStage:
      env = 'stage';
      break;
    case isDev:
      env = 'dev';
      break;
    default:
      env = 'production';
      break;
  }

  return env;
}
