import * as React from 'react';
import './cookies-agreement-modal.scss';

interface Props {
  onOk: () => void;
}

export function CookiesAgreementModal(props: Props) {
  const { onOk } = props;
  return (
    <div className="bCookieBanner">
      <div className="bCookieBannerContainWrapper">
        <div className="bCookieBannerContainText" onClick={onOk}>
          {' '}
          By using this website, you agree to our cookie policy{' '}
        </div>
        <button onClick={onOk} className="bCookieCloseBanner">
          OK
        </button>
      </div>
    </div>
  );
}
