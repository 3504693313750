import * as React from 'react';

export interface MapProps {
  lat: number;
  lng: number;
}

export class Map extends React.Component<MapProps, {}> {
  mapView: google.maps.Map;
  marker: google.maps.Marker;
  mapRef: any;

  constructor(props: MapProps) {
    super(props);

    this.mapRef = React.createRef();
  }

  componentDidMount(): void {
    const { lng, lat } = this.props;
    const mapCenter = new (window as any).google.maps.LatLng(lat, lng);

    const mapOptions = {
      center: mapCenter,
      zoom: 16,
      disableDefaultUI: true,
      zoomControl: true,
    };

    this.mapView = new (window as any).google.maps.Map(this.mapRef.current, mapOptions);

    this.marker = new (window as any).google.maps.Marker({
      position: mapCenter,
      map: this.mapView,
      draggable: false,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: MapProps): void {
    const { lat: prevLat, lng: prevLng } = this.props;
    const { lat: nextLat, lng: nextLng } = nextProps;

    const isLatLngChanged = prevLat !== nextLat || prevLng !== nextLng;

    if (isLatLngChanged) {
      this.mapView.setCenter({ lat: nextLat, lng: nextLng });
      this.marker.setPosition({ lat: nextLat, lng: nextLng });
    }
  }

  render() {
    return <div style={{ height: 300 }} ref={this.mapRef} />;
  }
}
